import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { EditmenuProps, EditMenuState } from "../../interfaces/menu";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import {
  storeTypeList,
  editMenu,
  logoutUser,
  getMenu,
  brandsList,
  countryList,
  statesList,
} from "../../redux";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import {
  editAggregator,
  getAggregator,
} from "../../redux/actions/aggregatorsAction";
import {
  EditAggregatorProps,
  EditAggregatorState,
} from "../../interfaces/aggreagtors";

class EditAggregator extends Component<
  EditAggregatorProps,
  EditAggregatorState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      aggregator_name: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getAggregator(id);
    document.title = "SimpleXSync | Aggregators";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      aggregator_name: nextProps.aggregator.aggregator_name,
    });
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  isAggreagtorReady = () => {
    const { aggregator_name } = this.state;
    return aggregator_name !== "";
  };
  handleSubmit = (event: any) => {
    let { aggregator_name } = this.state;
    let id = this.props.match.params.id;
    let data = {
      aggregator_name,
    };
    this.props.editAggregator(id, data);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    return (
      <div className="page">
        <CheckChanges path="/edit-menu" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Aggregators Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/aggregators" className="text-primary">
                    Aggregators
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Aggregator</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label ">
                                Aggregator Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="menuname"
                                type="text"
                                name="aggregator_name"
                                defaultValue={this.state.aggregator_name}
                                required
                                data-msg="Please enter Menu Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            onClick={this.handleSubmit}
                            disabled={!this.isAggreagtorReady()}
                            className="btn btn-primary"
                          >
                            Update Aggregator
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    storetype: state.store.types,
    brands: state.store.brands,
    menuData: state.menu.menuData,
    message: state.menu.message,
    isUpdated: state.menu.isUpdated,
    aggregator: state.aggregator.aggregator,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    storeTypeList: function () {
      dispatch(storeTypeList());
    },
    brandsList: function () {
      dispatch(brandsList());
    },
    getMenu: function (id: number) {
      dispatch(getMenu(id));
    },
    getAggregator: (id: number) => {
      dispatch(getAggregator(id));
    },
    editAggregator: (id: number, data: any) => {
      dispatch(editAggregator(id, data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditAggregator);
