import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Circle,
  KmlLayer,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { GoogleMapsAPI } from "../../client-config";
// import * as geolib from 'geolib';
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();
interface Props {
  onSetData: any;
  center?: any;
  google: any;
  zoom: any;
  height: any;
  data: any;
  kml?: any;
}
interface State {
  [x: number]: any;
  kml: any;
  address: any;
  lat: any;
  lng: any;
}
class Map extends Component<Props, State> {
  map: any;
  constructor(props: any) {
    super(props);
    this.state = {
      address: "",
      lat: 0,
      lng: 0,
      kml: "",
    };
    this.generateRandom = this.generateRandom.bind(this);
  }
  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    if (this.props.data === "") {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position: any) => {
          this.setState({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude,
          ).then(
            (response) => {
              const address = response.results[0].formatted_address;
              this.setState({
                address: address ? address : "",
              });
              this.props.onSetData(this.state);
            },
            (error) => {
              console.error(error);
            },
          );
        });
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.data !== "") {
      if (nextProps.center.lat == 0 && nextProps.center.lng == 0) {
        if (this.state.lat !== nextProps.data.lat) {
          this.setState({ lat: nextProps.data.lat, lng: nextProps.data.lng });
          Geocode.fromLatLng(nextProps.data.lat, nextProps.data.lng).then(
            (response) => {
              const address = response.results[0].formatted_address;

              this.setState({
                address: address ? address : "",
              });
              this.props.onSetData(this.state);
            },
            (error) => {
              console.error(error);
            },
          );
        }
      } else {
        // console.log(this.props.center.lat,nextProps.center.lat," ",this.props.center.lng,nextProps.center.lng)
        if (
          this.props.center.lat !== nextProps.center.lat ||
          this.props.center.lng !== nextProps.center.lng
        ) {
          // console.log(this.props.center, nextProps.center)
          this.setState({
            lat: parseFloat(nextProps.center.lat),
            lng: parseFloat(nextProps.center.lng),
          });
          Geocode.fromLatLng(nextProps.center.lat, nextProps.center.lng).then(
            (response) => {
              const address = response.results[0].formatted_address;

              this.setState({
                address: address ? address : "",
              });
              this.props.onSetData(this.state);
            },
            (error) => {
              console.error(error);
            },
          );
          this.setState({ kml: nextProps.kml });
        }
      }
      this.setState({ kml: nextProps.kml });
    } else {
      // console.log(this.props.center.lat,nextProps.center.lat," ",this.props.center.lng,nextProps.center.lng)
      if (
        this.props.center.lat !== nextProps.center.lat ||
        this.props.center.lng !== nextProps.center.lng
      ) {
        // console.log(this.props.center, nextProps.center)
        this.setState({
          lat: parseFloat(nextProps.center.lat),
          lng: parseFloat(nextProps.center.lng),
        });
        Geocode.fromLatLng(nextProps.center.lat, nextProps.center.lng).then(
          (response) => {
            const address = response.results[0].formatted_address;

            this.setState({
              address: address ? address : "",
            });
            this.props.onSetData(this.state);
          },
          (error) => {
            console.error(error);
          },
        );
      }
      this.setState({ kml: nextProps.kml });
    }
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps: any, nextState: any): boolean | any {
    if (
      this.state.lat !== nextState.lat ||
      // this.props.center.lat !== nextProps.center.lat ||
      // this.props.center.lng !== nextProps.center.lng ||
      this.state.address !== nextState.address ||
      this.state.kml !== nextState.kml
    ) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event: any) => {};

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event: any) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components;
        this.setState({
          address: address ? address : "",
          lat: newLat,
          lng: newLng,
        });
        this.props.onSetData(this.state);
      },
      (error) => {
        console.error(error);
      },
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place: any) => {
    if (place.formatted_address) {
      const address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      // let response: any = geolib.isPointInPolygon({ latitude: latValue, longitude: lngValue }, this.state.tradeZone);
      // alert(response)
      // Set these values in the state.
      this.setState({
        address: address ? address : "",
        lat: latValue,
        lng: lngValue,
      });
      this.props.onSetData(this.state);
    }
  };
  mapMounted = (ref: any) => {
    this.map = ref;
  };
  generateRandom() {
    return Math.random() * 10000000000000000;
  }
  render() {
    const AsyncMap: any = withScriptjs(
      withGoogleMap((props: any) => (
        <GoogleMap
          // @ts-ignore
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
          mapTypeId={"terrain"}
        >
          {/* InfoWindow on top of marker */}
          <InfoWindow
            // @ts-ignore
            onClose={this.onInfoWindowClose}
            position={{ lat: this.state.lat + 0.0018, lng: this.state.lng }}
          >
            <div>
              <span style={{ padding: 0, margin: 0 }}>
                {this.state.address}
              </span>
            </div>
          </InfoWindow>
          {/*Marker*/}
          <Marker
            // @ts-ignore
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{ lat: this.state.lat, lng: this.state.lng }}
          />
          {/* <Marker /> */}
          {/* For Auto complete Search Box */}
          <Autocomplete
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "16px",
              marginTop: "2px",
            }}
            onPlaceSelected={this.onPlaceSelected}
            types={["address"]} //types={['(regions)']}
            componentRestrictions={{ country: ["ae"] }}
          />
          <KmlLayer
            url={this.props.kml + "&ver=" + this.generateRandom()}
            options={{ preserveViewport: true }}
          />
        </GoogleMap>
      )),
    );
    let map;
    if (this.state.lat !== undefined) {
      map = (
        <div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="">Address</label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  readOnly
                  value={this.state.address}
                />
              </div>
            </div>
          </div>
          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: "350px", width: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: "350px", width: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default Map;
