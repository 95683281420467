import React, { Component } from "react";
import { AddMenuItemProps, AddMenuItemState } from "../../../interfaces/menu";
import { connect } from "react-redux";
import Footer from "../../../components/footer/main";
import Sidebar from "../../../components/sidebar";
import Topbar from "../../../components/topbar";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import {
  addItems,
  brandsList,
  checkDuplicateErpId,
  checkDuplicatePosCode,
  groupsList,
  logoutUser,
  menusList,
  statesList,
  storesListForMultiSelect,
} from "../../../redux";
import { Link, Redirect } from "react-router-dom";
import CheckChanges from "../../../components/confirmOnLeave";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  groupsOptions,
  menusOptions,
  modGroupListByAggregator,
  OrderingModes,
  subGroupsByGroup,
} from "../../../redux/actions/menuAction";
import { instashop_categories } from "../instahop_categories";
import * as uuid from "uuid";
const ShortUniqueId = require("short-unique-id");
const uid = new ShortUniqueId();
toast.configure();
class AddMenuItem extends Component<AddMenuItemProps, AddMenuItemState> {
  constructor(props: any) {
    super(props);
    this.state = {
      name_ar: "",
      itemname: "",
      group: "",
      type: "",
      disabled: "",
      use_as_modifier: 0,
      description_ar:"",
      brand_id: "",
      priority: "",
      erp_id: "",
      master_subgroup_id: "",
      images: null,
      hero_image: null,
      hero_item: false,
      settime: "alltime",
      tax_percent: "",
      itemstart: "",
      itemclose: "",
      state: "",
      mode: [],
      is_lsm: "0",
      storesJson: [],
      metaTitle: "",
      metaDesc: "",
      instashop_category: "",
      sizejson: [
        {
          aloha_cat_id: "",
          size: "",
          cost: "",
          mrp: "",
          erp_id: "",
          extra_price: "",
          description: "",
          alt_text: "",
          image_url: null,
          order_modes_price: [],
          // modgroups:[]
        },
      ],
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleItemsByGroupId = this.handleItemsByGroupId.bind(this);
  }
  componentDidMount() {
    // this.props.modGroupListByAggregator("master")
    this.props.brandsList();
    // this.props.menusList();
    // this.props.groupsList();
    // this.props.storesList();
    this.props.OrderingModes();
    document.title = "SimpleXSync | Menu Items";
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleErpChange = () => {
    this.setState({ erp_id: uid.stamp(32) + new Date().getTime() });
  };
  handleUseAsModifier = (e: any) => {
    this.setState({ use_as_modifier: e.target.value });
  };
  handleItemsByGroupId(event: { target: { name: any; value: any } }) {
    this.setState({
      group: event.target.value,
      sizejson: [
        {
          aloha_cat_id: "",
          size: "",
          cost: "",
          mrp: "",
          extra_price: "",
          description: "",
          alt_text: "",
          image_url: null,
          order_modes_price: [],
          modgroups: [],
        },
      ],
    });
    this.props.subGroupsByGroup(event.target.value, "item");
  }

  handleISubgroup = (event: any) => {
    this.setState({ master_subgroup_id: event.target.value });
    this.props.subGroupsByGroup(event.target.value, "item");
  };
  // handle click event of the Time Radio buttons
  handleChangeRad = (event: { target: { name: any; value: any } }) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleVariantInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = this.state.sizejson;
    list[index][name] = value;
    this.setState({ sizejson: list });
  };

  // handleModGroups = (e: any, index: any) => {
  //     const list: any = this.state.sizejson;
  //     list[index]["modgroups"] = e.value;
  //     this.setState({ sizejson: list });
  // };
  handleErpInputChange = (e: any, index: any, value: any) => {
    const list: any = this.state.sizejson;
    list[index]["erp_id"] = value;
    this.setState({ sizejson: list });
  };
  // handle click event of the Remove button
  handleRemoveClick = (index: any) => {
    const list = this.state.sizejson;
    list.splice(index, 1);
    this.setState({ sizejson: list });
  };
  // handle click event of the Add button
  handleAddClick = (i: any) => {
    const list: any = this.state.sizejson;
    let { duplicateItemErp, duplicateItemPos } = this.props;
    // if (list[i].order_modes_price[list[i].order_modes_price.length - 1]) {
    //  || list[i].order_modes_price[list[i].order_modes_price.length - 1].mrp == 0
    if (
      list[i]["size"] == "" ||
      list[i]["cost"] == "" ||
      list[i]["image_url"] == null ||
      list[i]["erp_id"] == ""
    ) {
      alert("Please fill all mandatory variant fields");
    } else if (i == "3") {
      alert("Sorry you can select only 4 variants");
    } else {
      const newlist: any = {
        size: "",
        cost: "",
        mrp: "",
        extra_price: "",
        description: "",
        image_url: null,
        order_modes_price: [],
        modgroups: [],
      };
      this.state.mode &&
        this.state.mode.forEach((elem) => {
          let mode_id = elem.value;
          let mode_label = elem.label;
          let obj = {
            item_variant_id: "",
            order_mode_id: mode_id,
            mrp: 0,
            extra_price: 0,
            label: mode_label,
            value: mode_id,
          };
          newlist.order_modes_price.push(obj);
        });
      const menu_items: any = this.state.sizejson.concat(newlist);
      this.setState({ sizejson: menu_items });
    }
    // } else {
    //     alert("Please select at least one ordering mode!")
    // }
  };
  fileSelectedHandler = (e: any, index: any) => {
    const list: any = this.state.sizejson;
    if (e.target.files.length > 0) {
      list[index]["image_url"] = e.target.files;
      this.setState({ sizejson: list });
    }
  };
  handleOrderModesInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      if (e.length < this.state.mode.length) {
        let tempsizeJson = this.state.sizejson;
        for (let i = 0; i < tempsizeJson.length; i++) {
          let tempArr: any[] = tempsizeJson[i].order_modes_price;
          let selectedItems = tempArr.filter((obj) =>
            e.find((s: any) => s.value === obj.value),
          );
          tempsizeJson[i].order_modes_price = selectedItems;
        }
        this.setState({ sizejson: tempsizeJson });
      } else {
        let tempsizeJson = this.state.sizejson;
        for (let i = 0; i < tempsizeJson.length; i++) {
          let item_variant_id = this.state.sizejson[i].id;
          let mode_id =
            e[this.state.sizejson[i].order_modes_price.length].value;
          let mode_label =
            e[this.state.sizejson[i].order_modes_price.length].label;
          let mrp = this.state.sizejson[i].mrp;
          let extra_price = this.state.sizejson[i].extra_price;
          let obj = {
            item_variant_id: item_variant_id,
            order_mode_id: mode_id,
            order_mode: mode_label,
            mrp: mrp ? mrp : 0,
            extra_price: extra_price ? extra_price : 0,
            label: mode_label,
            value: mode_id,
          };
          tempsizeJson[i].order_modes_price.push(obj);
        }
        this.setState({ sizejson: tempsizeJson });
      }
      this.setState({ mode: e });
    } else {
      this.setState({ mode: [] });
      let tempsizeJson = this.state.sizejson;
      for (let i = 0; i < tempsizeJson.length; i++) {
        tempsizeJson[i].order_modes_price = [];
      }
      this.setState({ sizejson: tempsizeJson });
    }
  };
  handleVariantPriceInputChange = (e: any, index: any, sizeJsonindex: any) => {
    const { name, value } = e.target;
    const list: any = this.state.sizejson;
    list[sizeJsonindex].order_modes_price[index][name] = value;
    this.setState({ sizejson: list });
  };
  handlePricingOrderModesInputChange = (e: any, indexing: any, ind: any) => {
    if (e && e.length > 0) {
      if (e.length === this.state.sizejson[ind].order_modes_price.length + 1) {
        if (this.state.sizejson[ind].order_modes_price.length == 0) {
          let mode_id =
            e[this.state.sizejson[ind].order_modes_price.length].value;
          let mode_label =
            e[this.state.sizejson[ind].order_modes_price.length].label;
          // this.setState({ mode: e });
          let tempsizeJson = this.state.sizejson;
          let obj = {
            item_variant_id: "",
            order_mode_id: mode_id,
            mrp: 0,
            extra_price: 0,
            label: mode_label,
            value: mode_id,
          };
          tempsizeJson[ind].order_modes_price.push(obj);
          this.setState({ sizejson: tempsizeJson });
        } else {
          if (
            this.state.sizejson[ind].order_modes_price[
              this.state.sizejson[ind].order_modes_price.length - 1
            ].mrp > 0
          ) {
            let mode_id =
              e[this.state.sizejson[ind].order_modes_price.length].value;
            let mode_label =
              e[this.state.sizejson[ind].order_modes_price.length].label;
            // this.setState({ mode: e });
            let tempsizeJson = this.state.sizejson;
            let obj = {
              item_variant_id: "",
              order_mode_id: mode_id,
              mrp: 0,
              extra_price: 0,
              label: mode_label,
              value: mode_id,
            };
            tempsizeJson[ind].order_modes_price.push(obj);
            this.setState({ sizejson: tempsizeJson });
          } else {
            toast.error("Max retail price should not be 0,Please update", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        }
      } else {
        console.log(indexing);
        let tempsizeJson = this.state.sizejson;
        let dubtempsizeJson = tempsizeJson[ind].order_modes_price.filter(
          (item: any) => item.order_mode_id != indexing.removedValue.value,
        );
        tempsizeJson[ind].order_modes_price = dubtempsizeJson;
        this.setState({ sizejson: tempsizeJson });
      }
    } else {
      let tempsizeJson = this.state.sizejson;
      tempsizeJson[ind].order_modes_price = [];
      this.setState({ sizejson: tempsizeJson });
    }
  };

  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ storesJson: e });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  handleHeroItem = (event: { target: { name: any; value: any } }) => {
    const { hero_item } = this.state;
    this.setState({ hero_item: !hero_item });
  };
  heroItemImageSelectedHandler = (e: any) => {
    if (e.target.files.length > 0) {
      this.setState({ hero_image: e.target.files });
    }
  };
  checkMRPForAllModes = () => {
    let tempsizeJson = this.state.sizejson;
    let variants: any = [];
    for (let i = 0; i < tempsizeJson.length; i++) {
      let order_modes_price: any[] = tempsizeJson[i].order_modes_price;
      var filtered =
        order_modes_price &&
        order_modes_price.filter(function (el) {
          return el.mrp == 0 || el.mrp == "";
        });
      if (filtered.length > 0) {
        variants.push(tempsizeJson[i]);
      }
    }
    if (variants.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  isMenuItemReady = () => {
    const {
      itemname,
      group,
      instashop_category,
      settime,
      itemstart,
      itemclose,
      hero_item,
      mode,
      hero_image,
      is_lsm,
      storesJson,
      erp_id,
      disabled,
    } = this.state;
    let { duplicateItemPos, duplicateItemErp } = this.props;
    if (settime == "customtime") {
      if (hero_item == false) {
        if (is_lsm == "0") {
          //  && this.checkMRPForAllModes()
          // && mode.length > 0
          return (
            itemname !== "" &&
            group !== "" &&
            itemstart !== "" &&
            itemclose !== "" &&
            erp_id != "" &&
            disabled != true
          );
        } else if (is_lsm == "1") {
          // && this.checkMRPForAllModes()
          // && mode.length > 0
          return (
            itemname !== "" &&
            group !== "" &&
            itemstart !== "" &&
            itemclose !== "" &&
            storesJson.length > 0 &&
            erp_id != "" &&
            disabled != true
          );
        }
      } else {
        if (is_lsm == "0") {
          //  && this.checkMRPForAllModes()
          // && mode.length > 0
          return (
            itemname !== "" &&
            group !== "" &&
            hero_image !== null &&
            itemstart !== "" &&
            itemclose !== "" &&
            erp_id != "" &&
            disabled != true
          );
        } else if (is_lsm == "1") {
          //  && this.checkMRPForAllModes()
          // && mode.length > 0
          return (
            itemname !== "" &&
            group !== "" &&
            hero_image !== null &&
            itemstart !== "" &&
            itemclose !== "" &&
            storesJson.length > 0 &&
            erp_id != "" &&
            disabled != true
          );
        }
      }
    } else {
      if (hero_item == false) {
        if (is_lsm == "0") {
          //  && this.checkMRPForAllModes()
          // && mode.length > 0
          return (
            itemname !== "" && group !== "" && erp_id != "" && disabled != true
          );
        } else if (is_lsm == "1") {
          //  && this.checkMRPForAllModes()
          // && mode.length > 0
          return (
            itemname !== "" &&
            group !== "" &&
            storesJson.length > 0 &&
            erp_id != "" &&
            disabled != true
          );
        }
      } else {
        if (is_lsm == "0") {
          //  && this.checkMRPForAllModes()
          // && mode.length > 0
          return (
            itemname !== "" &&
            group !== "" &&
            hero_image !== null &&
            erp_id != "" &&
            disabled != true
          );
        } else if (is_lsm == "1") {
          //  && this.checkMRPForAllModes()
          // && mode.length > 0
          return (
            itemname !== "" &&
            group !== "" &&
            hero_image !== null &&
            storesJson.length > 0 &&
            erp_id != "" &&
            disabled != true
          );
        }
      }
    }
  };
  isUnique = (arr: any) => {
    const len = arr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len; j++) {
        // if the elements match, this wouldn't be a unique array
        if (arr[i].erp_id !== "" && arr[j].erp_id !== "") {
          if (
            i !== j &&
            (arr[i].pos_code === arr[j].pos_code ||
              arr[i].erp_id === arr[j].erp_id)
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };
  blockSpecialCharacters = () => {
    var checkString = this.state.itemname;
    if (checkString != "") {
      if (/[%]/.test(checkString)) {
        alert(
          "Your item name has '%' sign. \nIt is not allowed.\nPlease remove it and try again.",
        );
        return false;
      } else {
        return true;
      }
    }
  };
  handleState = (value: any) => {
    this.setState({ disabled: value });
  };
  handleSaveBtnClick = (event: any) => {
    this.setState({ disabled: true });
    let {
      name_ar,
      description_ar,
      itemname,
      group,
      type,
      use_as_modifier,
      brand_id,
      priority,
      instashop_category,
      erp_id,
      master_subgroup_id,
      mode,
      hero_item,
      hero_image,
      sizejson,
      settime,
      itemstart,
      itemclose,
      is_lsm,
      storesJson,
      metaTitle,
      metaDesc,
    } = this.state;
    const data: any = new FormData();
    let itemNamevalid = this.blockSpecialCharacters();
    if (itemNamevalid) {
      // if (this.state.sizejson) {
      //   for (var x = 0; x < this.state.sizejson.length; x++) {
      //     if (this.state.sizejson[x].image_url !== null) {
      //       var file = this.state.sizejson[x].image_url[0];
      //       var newFileName =
      //         file.name.split(".")[0] +
      //         `_variant_${x}.` +
      //         file.name.split(".")[1];
      //       data.append("files", file, newFileName);
      //     }
      //   }
      // }
      const SizeJsonString = JSON.stringify(sizejson);
      if (hero_image) {
        var file = hero_image[0];
        var newFileName =
          file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
        data.append("files", file, newFileName);
      }
      data.append("item_name", itemname);
      data.append("brand_id", brand_id);
      data.append("erp_id", erp_id);
      // if (erpid !== null) {
      //     data.append('erp_id', erpid)
      // }
      // if (poscode !== null) {
      //     data.append('pos_code', poscode)
      // }
      if (hero_item === true) {
        data.append("hero_item", 1);
      }
      if (is_lsm == "0") {
        data.append("is_lsm", 0);
      } else if (is_lsm == "1") {
        data.append("is_lsm", 1);
        data.append("stores_json", JSON.stringify(storesJson));
      }
      data.append("item_group_id", group);
      data.append("use_as_modifier", use_as_modifier);
      data.append("master_subgroup_id", master_subgroup_id);
      data.append("item_mode", JSON.stringify(mode));
      data.append("item_type", type);
      data.append("priority", priority);
      data.append("meta_title", metaTitle);
      data.append("meta_description", metaDesc);
      data.append("tag_name", instashop_category);
      data.append("description_ar", description_ar);
      data.append("name_ar", name_ar);
      
      if (settime === "customtime") {
        data.append("item_start_time", moment(itemstart).utc(false));
        data.append("item_close_time", moment(itemclose).utc(false));
      }
      // && (sizejson[sizejson.length - 1].order_modes_price.length > 0 && sizejson[sizejson.length - 1].order_modes_price[sizejson[sizejson.length - 1].order_modes_price.length - 1].mrp > 0)
      if (
        // sizejson[sizejson.length - 1]["size"] !== "" &&
        // sizejson[sizejson.length - 1]["cost"] !== "" &&
        sizejson[sizejson.length - 1]["image_url"] !== null &&
        sizejson[sizejson.length - 1]["mrp"] !== "" &&
        sizejson[sizejson.length - 1]["erp_id"] !== ""
      ) {
        // if (this.isUnique(sizejson)) {
        data.append("itemSizes", SizeJsonString);
        this.props.addItems(data, this.handleState);
        // } else {
        //     toast.error("Please fill unique POS & ERP codes", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        // }
      } else {
        this.handleState(false);
        alert("Please fill all mandatory variant fields");
      }
    }
  };
  handleInstashopCategor = (e: any) => {
    this.setState({ instashop_category: e.value });
  };

  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
    this.props.menusList(e.value);
    this.props.groupsList(e.value);
    this.props.storesList(e.value);
    this.props.modGroupListByAggregator("master", e.value);
  };

  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isInserted, message, groups, subgroups, modgroups } = this.props;
    const { sizejson, settime, hero_item, is_lsm } = this.state;
    if (!isInserted) {
      msg = message;
      messcolor = "red";
    }
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let role_id: any = decoded.role_id;
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    return (
      <div className="page">
        <CheckChanges path="/add-item" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Menu Item Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/menu-items" className="text-primary">
                    Menu Items
                  </Link>
                </li>
                <li className="breadcrumb-item active">Add Menu Item</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Item name <span className="text-danger">*</span>
                              </label>
                              <input
                                id="itemname"
                                type="text"
                                name="itemname"
                                required
                                data-msg="Please enter Item Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>

                            <div className="form-group">
                              <label className="form-control-label">
                                Arabic Item Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="name_ar"
                                type="text"
                                name="name_ar"
                                value={this.state.name_ar}
                                required
                                data-msg="Please enter Arabic Item Name"
                                className="input-material"
                                onChange={(e) => {
                                  // if (!validateAlphaValue(e.target.value))
                                  //   return;
                                  this.handleInputChange(e);
                                }}
                              />
                            </div>

                            <div className="form-group">
                              <label className="form-control-label">
                                Arabic Description
                              </label>
                              <input
                                id="description_ar"
                                type="text"
                                name="description_ar"
                                value={this.state.description_ar}
                                className="input-material"
                                onChange={(e) => {
                                  this.handleInputChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 col-sm-5 col-5">
                            <div className="form-group">
                              <label className="form-control-label mb-3">
                                Erp Id <span className="text-danger">*</span>
                              </label>
                              <input
                                
                                name="erp_id"
                                value={this.state.erp_id}
                                type="text"
                                data-msg="Please enter erp"
                                className="input-material"
                                onChange={e => this.handleInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <label className="form-control-label">
                              Select Brand{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-group">
                              <Select
                                name="brands"
                                options={selectedBrands}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                // value={{label:this.state.brand_name,value:this.state.brand_id}}
                                onChange={(e, i) =>
                                  this.handleBrandsInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Group name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name="group"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select Group"
                                onChange={this.handleItemsByGroupId}
                              >
                                <option>Select Group</option>
                                {groups &&
                                  groups.map(
                                    (group, index) =>
                                      group && (
                                        <option
                                          key={index}
                                          value={group.group_id}
                                        >
                                          {group.group_name +
                                            "-" +
                                            group.menu_name +
                                            "-" +
                                            group.store_type_name}
                                        </option>
                                      ),
                                  )}
                              </select>
                            </div>
                          </div>
                          {/* <div className="col-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Tag Name <span className="text-danger">*</span>
                              </label>
                              <Select
                                name="instashop_category"
                                options={instashop_categories}
                                placeholder="Select Instashop Category"
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleInstashopCategor(e)
                                }
                              />
                            </div>
                          </div> */}
                          <div className="col-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Use Item as Modifier
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name="master_subgroup_id"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select Group"
                                onChange={this.handleUseAsModifier}
                              >
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <label className="form-control-label">Type</label>
                              <input
                                id="type"
                                type="text"
                                name="type"
                                required
                                data-msg="Please enter Item Type"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Meta title{" "}
                              </label>
                              <input
                                id="metaTitle"
                                type="text"
                                name="metaTitle"
                                required
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Meta description
                              </label>
                              <input
                                id="metaDesc"
                                type="text"
                                name="metaDesc"
                                required
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Item Image <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                        </div>
                                                    </div>
                                                </div> */}
                        {/* <div className="row">
                                                    <div className="col-12" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.props.orderingModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> */}
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Variants
                              </label>
                              {sizejson.map((x: any, ind: any) => {
                                return (
                                  <div key={ind}>
                                    <div className="row">
                                      {/* <div className="col-lg-4 col-sm-4 col-6">
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label mb-3">Aloha Category Id</label>
                                                                                    <input type="number" name="aloha_cat_id" value={x.aloha_cat_id} className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                </div>
                                                                            </div> */}
                                      <div className="col-lg-4 col-sm-4 col-6">
                                        <div className="form-group">
                                          <label className="form-control-label mb-3">
                                            Size{" "}
                                           
                                          </label>
                                          <input
                                            name="size"
                                            type="text"
                                            value={x.size}
                                            data-msg="Please enter size"
                                            className="input-material"
                                            onChange={(e) =>
                                              this.handleVariantInputChange(
                                                e,
                                                ind,
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                     
                                      {/* <div className="col-lg-4 col-sm-4 col-6">
                                        <div className="form-group">
                                          <label className="form-control-label mb-3">
                                            Cost{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            name="cost"
                                            type="number"
                                            value={x.cost}
                                            data-msg="Please enter cost"
                                            className="input-material"
                                            onChange={(e) =>
                                              this.handleVariantInputChange(
                                                e,
                                                ind,
                                              )
                                            }
                                          />
                                        </div>
                                      </div> */}
                                      <div className="col-lg-4 col-sm-4 col-6">
                                        <div className="form-group">
                                          <label className="form-control-label mb-3">
                                            Mrp{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            name="mrp"
                                            type="number"
                                            value={x.mrp}
                                            data-msg="Please enter mrp"
                                            className="input-material"
                                            onChange={(e) =>
                                              this.handleVariantInputChange(
                                                e,
                                                ind,
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-sm-4 col-6">
                                        <div className="form-group">
                                          <label className="form-control-label mb-3">
                                            Extra Price{" "}
                                            <span className="text-danger">
                                              
                                            </span>
                                          </label>
                                          <input
                                            name="extra_price"
                                            type="number"
                                            value={x.extra_price}
                                            data-msg="Please enter Extra Price"
                                            className="input-material"
                                            onChange={(e) =>
                                              this.handleVariantInputChange(
                                                e,
                                                ind,
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-sm-4 col-6">
                                        <div className="form-group">
                                          <label className="form-control-label mb-3">
                                            Description
                                          </label>
                                          <input
                                            id="description"
                                            type="text"
                                            name="description"
                                            value={x.description}
                                            className="input-material"
                                            onChange={(e) =>
                                              this.handleVariantInputChange(
                                                e,
                                                ind,
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col-lg-12" >
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Pricing By Ordering Modes <span className="text-danger">*</span></label>
                                                                                    <Select
                                                                                        isMulti
                                                                                        name="mode"
                                                                                        options={this.state.mode}
                                                                                        value={x.order_modes_price}
                                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                                        classNamePrefix="select"
                                                                                        onChange={(e, i) => this.handlePricingOrderModesInputChange(e, i, ind)}
                                                                                    />
                                                                                </div>
                                                                            </div>
 */}

                                      {/* {x.order_modes_price.length > 0 && x.order_modes_price.map((channel: any, index: any) => (
                                                                                <>
                                                                                    <div className="col-12 mb-2">
                                                                                        <strong>{index + 1} -</strong>
                                                                                        <strong style={{ fontSize: '16px' }} className="ml-2">
                                                                                            {channel.label}
                                                                                        </strong>
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label mb-3">Max Retail Price <span className="text-danger">*</span></label>
                                                                                            <input
                                                                                                name="mrp"
                                                                                                type="number"
                                                                                                value={channel.mrp}
                                                                                                data-msg="Please enter mrp"
                                                                                                className="input-material"
                                                                                                onChange={e => this.handleVariantPriceInputChange(e, index, ind)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <div className="form-group">
                                                                                            <label className="form-control-label mb-3">Extra Price</label>
                                                                                            <input
                                                                                                name="extra_price"
                                                                                                type="number"
                                                                                                value={channel.extra_price}
                                                                                                className="input-material"
                                                                                                onChange={e => this.handleVariantPriceInputChange(e, index, ind)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ))} */}

                                    <div className="col-lg-3 col-sm-3 col-6">
                                        <div className="form-group">
                                          <label className="form-control-label mb-3">
                                            Item Image{" "}
                                           
                                          </label>
                                          <input
                                            id="files"
                                            type="file"
                                            name="files"
                                            className="form-control-file"
                                            onChange={(e) =>
                                              this.fileSelectedHandler(e, ind)
                                            }
                                          />
                                          </div>
                                          </div>
                                      <div className="col-lg-4 col-sm-4 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Image Url
                                              <span className="text-danger">
                                              *
                                            </span>
                                            </label>
                                            
                                            <input
                                              id="image_url"
                                              type="text"
                                              value={x.image_url}
                                              name="image_url"
                                              className="input-material"
                                              onChange={(e) =>
                                                this.handleVariantInputChange(
                                                  e,
                                                  ind,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      <div className="col-lg-3 col-sm-3 col-6">
                                        <div className="form-group">
                                          <label className="form-control-label mb-3">
                                            Alt Tag
                                          </label>
                                          <input
                                            id="alt_text"
                                            type="text"
                                            name="alt_text"
                                            className="input-material"
                                            onChange={(e) =>
                                              this.handleVariantInputChange(
                                                e,
                                                ind,
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col-lg-3 col-sm-3 col-6" >
                                                                                <div className="form-group">
                                                                                    <label className="form-control-label">Modifier Groups</label>
                                                                                    <Select
                                                                                        name="instashop_category"
                                                                                        options={modgroups}
                                                                                        placeholder='Select Mod Groups'
                                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                                        classNamePrefix="select"
                                                                                        onChange={(e, i) => this.handleModGroups(e,ind)}
                                                                                    />
                                                                                </div>
                                                                            </div> */}
                                      <div className="col-lg-3 col-sm-3 col-6 d-flex align-items-center">
                                        <div className="form-group">
                                          <label className="form-control-label">
                                            {" "}
                                          </label>
                                          {sizejson.length !== 1 && (
                                            <button
                                              className="btn btn-sm btn-outline-danger"
                                              onClick={() =>
                                                this.handleRemoveClick(ind)
                                              }
                                            >
                                              <i className="fa fa-trash"></i>
                                            </button>
                                          )}
                                          {sizejson.length - 1 === ind && (
                                            <button
                                              className="btn btn-sm btn-primary ml-2 float-right"
                                              onClick={() =>
                                                this.handleAddClick(ind)
                                              }
                                            >
                                              <i className="fa fa-plus"></i>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom1"
                                type="radio"
                                name="settime"
                                value="alltime"
                                checked={settime === "alltime"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom1">
                                Available for all time
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom2"
                                type="radio"
                                name="settime"
                                value="customtime"
                                checked={settime === "customtime"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom2">
                                Available for specific time
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="checkboxCustom1"
                                type="checkbox"
                                name="taxstatus"
                                checked={hero_item}
                                onChange={this.handleHeroItem}
                                className="checkbox-template"
                              />
                              <label htmlFor="checkboxCustom1">Hero Item</label>
                            </div>
                          </div>
                          {hero_item && (
                            <div className="col-lg-4 col-sm-6 col-6 mt-2">
                              <div className="form-group">
                                <label className="form-control-label mb-3">
                                  Hero Item Image{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="files"
                                  type="file"
                                  name="files"
                                  className="form-control-file"
                                  onChange={this.heroItemImageSelectedHandler}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {settime === "customtime" && (
                          <div>
                            <div className="row mt-3">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-lable">
                                    Menu Item Timing
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <input
                                    id="itemstart"
                                    type="datetime-local"
                                    name="itemstart"
                                    required
                                    data-msg="Please enter starting time"
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <input
                                    id="itemclose"
                                    type="datetime-local"
                                    name="itemclose"
                                    required
                                    data-msg="Please enter closing time"
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-lg-4 col-sm-4 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom3"
                                type="radio"
                                name="is_lsm"
                                value="0"
                                checked={is_lsm == "0"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom3">
                                Available for all stores
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom4"
                                type="radio"
                                name="is_lsm"
                                value="1"
                                checked={is_lsm == "1"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom4">
                                Available for specific stores
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Priority
                              </label>
                              <input
                                id="priority"
                                type="text"
                                name="priority"
                                required
                                data-msg="Please enter Priority"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {is_lsm == "1" && (
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Select Store{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  isMulti
                                  name="stores"
                                  options={this.props.stores}
                                  className="text-capitalize basic-multi-select mt-2"
                                  classNamePrefix="select"
                                  onChange={(e, i) =>
                                    this.handleStoresInputChange(e, i)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isMenuItemReady()}
                            onClick={this.handleSaveBtnClick}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    groups: state.menu.groups,
    subgroups: state.menu.subgroups,
    message: state.menu.message,
    states: state.setting.states,
    menus: state.menu.menus,
    stores: state.menu.storesoptions,
    isInserted: state.menu.isInserted,
    duplicateItemPos: state.menu.duplicateItemPos,
    duplicateItemErp: state.menu.duplicateItemErp,
    orderingModes: state.menu.orderModes,
    modgroups: state.menu.modgroups,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addItems: function (formData: any, handleState: any) {
      dispatch(addItems(formData, handleState));
    },
    checkDuplicatePosCode: (pos_code: any) => {
      dispatch(checkDuplicatePosCode(pos_code));
    },
    checkDuplicateErpId: (erp_id: any) => {
      dispatch(checkDuplicateErpId(erp_id));
    },
    groupsList: function (brand_id: any) {
      dispatch(groupsOptions(brand_id));
    },
    menusList: function (brand_id: any) {
      dispatch(menusOptions(brand_id));
    },
    statesList: function () {
      dispatch(statesList());
    },
    storesList: (brand_id: any) => {
      dispatch(storesListForMultiSelect(brand_id));
    },
    OrderingModes: () => {
      dispatch(OrderingModes());
    },
    subGroupsByGroup: (id: any, type: any) => {
      dispatch(subGroupsByGroup(id, type));
    },
    modGroupListByAggregator: (type: any, brand_id: any) => {
      dispatch(modGroupListByAggregator(type, brand_id));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMenuItem);
