import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { EditModGroupProps, EditModGroupState } from "../../../interfaces/menu";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import {
  logoutUser,
  editModGroup,
  getModGroup,
  groupsListForMultiSelect,
  itemsListForMultiSelect,
} from "../../../redux";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import CheckChanges from "../../../components/confirmOnLeave";
import Select from "react-select";

class EditMod extends Component<EditModGroupProps, EditModGroupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      modgroupname: "",
      groupJson: [],
      disabled: false,
      name_ar: "",
      itemsJson: [],
      poscode: "",
      brand_id: null,
      brand_name: "",
      erp_id: "",
      modgroupdesc: "",
      type: "",
      level: "",
      priority: "",
      instashop_remote_code: "",
      max_modifier_qunatity_selection: null,
      max_number: "",
      min_number: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    let type = this.props.match.params.menu_type;
    this.props.getModGroup(id, type);
    this.props.groupsList();
    // this.props.itemsListForMultiSelect();
    document.title = "SimpleXSync | Modifier Groups";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      name_ar: nextProps.modGroupData.name_ar,
      modgroupname: nextProps.modGroupData.mod_group_name,
      erp_id: nextProps.modGroupData.erp_id,
      brand_name: nextProps.modGroupData.brand_name,
      brand_id: nextProps.modGroupData.brand_id,
      modgroupdesc: nextProps.modGroupData.mod_group_description,
      groupJson:
        nextProps.modGroupData.group_json &&
        JSON.parse(nextProps.modGroupData.group_json),
      itemsJson:
        nextProps.modGroupData.items_json &&
        JSON.parse(nextProps.modGroupData.items_json),
      type: nextProps.modGroupData.modifiers_type,
      level: nextProps.modGroupData.type,
      priority: nextProps.modGroupData.priority,
      instashop_remote_code: nextProps.modGroupData.instashop_remote_code,
      min_number: nextProps.modGroupData.min_number,
      max_number: nextProps.modGroupData.max_number,
      max_modifier_qunatity_selection:
        nextProps.modGroupData.max_modifier_qunatity_selection,
    });

    // let finslitemjson = []
    // let savedItemJson:any = nextProps.modGroupData.items_json && JSON.parse(nextProps.modGroupData.items_json)
    // if(savedItemJson) {
    //     for(let i = 0;i<nextProps.items.length;i++) {
    //         for(let j = 0;j<savedItemJson.length;j++) {
    //             if(nextProps.items[i].id == savedItemJson[j].id){
    //                 finslitemjson.push(savedItemJson[j])
    //             }
    //         }
    //     }
    //     this.setState({itemsJson:finslitemjson})
    // }
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleModifiersLevel(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  isModGroupReady = () => {
    const {
      min_number,
      max_number,
      modgroupname,
      type,
      groupJson,
      level,
      itemsJson,
      disabled,
    } = this.state;
    console.table({ max_number: max_number, min_number: min_number });
    return (
      modgroupname !== "" &&
      disabled != true &&
      type !== "" &&
      max_number !== "" &&
      min_number !== "" &&
      (level == "group"
        ? groupJson && groupJson.length > 0
        : itemsJson && itemsJson.length > 0)
    );
  };
  handleGroupsInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ itemsJson: [], groupJson: e });
    }
  };
  handleItemsInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ groupJson: [], itemsJson: e });
    }
  };
  handleState = (value: any) => {
    this.setState({ disabled: value });
  };
  handleSubmit = (event: any) => {
    this.setState({ disabled: true });
    let {
      modgroupname,
      brand_id,
      modgroupdesc,
      max_modifier_qunatity_selection,
      min_number,
      max_number,
      name_ar,
      type,
      poscode,
      erp_id,
      groupJson,
      level,
      itemsJson,
      priority,
    } = this.state;
    let id = this.props.match.params.id;
    let data: any = {
      mod_group_name: modgroupname,
      mod_group_description: modgroupdesc,
      priority: priority,
      modifiers_type: type,
      type: level,
      min_number,
      max_number,
      brand_id,
      max_modifier_qunatity_selection,
      erp_id,
      name_ar 
    };
    if (level == "group") {
      data["group_json"] = JSON.stringify(groupJson);
      data["items_json"] = null;
    } else if (level == "item") {
      data["group_json"] = null;
      data["items_json"] = JSON.stringify(itemsJson);
    }
    let menutype = this.props.match.params.menu_type;
    this.props.editModGroup(id, data, menutype, this.handleState);
    event.preventDefault();
  };
  render() {
    let type = this.props.match.params.menu_type;
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    let { groupJson, itemsJson } = this.state;
    const { isUpdated, message, modGroupData, groups, items } = this.props;
    let finalGroups: any = [];
    groups.map((info: any) => {
      finalGroups.push({ label: info.group_name, value: info.group_id });
    });
    if (isUpdated) {
      msg = message;
      messcolor = "green";
    } else {
      msg = message;
      messcolor = "red";
    }
    // let finslitemjson = []
    // for(let i = 0;i<items.length;i++) {
    //     for(let j = 0;j<itemsJson.length;j++) {
    //         if(items[i].id == itemsJson[j].id){
    //             finslitemjson.push(itemsJson[j])
    //         }
    //     }
    // }
    return (
      <div className="page">
        <CheckChanges path="/edit-modgroup" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Modifier Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/mod-groups" className="text-primary">
                    Modifier Groups
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Modifier Group</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <form method="post" className="form-validate">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Modifier Group Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="modgroupname"
                                  type="text"
                                  name="modgroupname"
                                  defaultValue={modGroupData.mod_group_name}
                                  required
                                  data-msg="Please enter Modifier Name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label mb-3">
                                    Erp Id{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    disabled
                                    value={this.state.erp_id}
                                    name="erp_id"
                                    type="text"
                                    data-msg="Please enter erp"
                                    className="input-material"
                                    // onChange={e => this.handleVariantInputChange(e, ind)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Brand Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  disabled
                                  id="instashop_remote_code"
                                  type="text"
                                  name="instashop_remote_code"
                                  defaultValue={modGroupData.brand_name}
                                  required
                                  data-msg="Please enter remote code"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          
                          <div className="row">
                            <div className="col-md-6">

                            <div className="form-group">
                                <label className="form-control-label">
                                  Arabic Modifier Group Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="name_ar"
                                  type="text"
                                  name="name_ar"
                                  defaultValue={modGroupData.name_ar}
                                  required
                                  data-msg="Please enter Arabic Modifier Name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">
                                  Modifiers Level{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="level"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Type"
                                  onChange={this.handleModifiersLevel}
                                >
                                  <option
                                    value="group"
                                    {...(modGroupData.type == "group" && {
                                      selected: true,
                                    })}
                                  >
                                    Group
                                  </option>
                                  <option
                                    value="item"
                                    {...(modGroupData.type == "item" && {
                                      selected: true,
                                    })}
                                  >
                                    Menu Item
                                  </option>
                                </select>
                              </div>
                            </div>

                            {this.state.level === "group" && (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Group <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    isMulti
                                    name="groups"
                                    value={groupJson}
                                    options={groups}
                                    className="text-capitalize basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    onChange={(e, i) =>
                                      this.handleGroupsInputChange(e, i)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {this.state.level === "item" && (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Select Items{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    isMulti
                                    name="items"
                                    options={items}
                                    value={itemsJson}
                                    className="text-capitalize basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    onChange={(e, i) =>
                                      this.handleItemsInputChange(e, i)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {type == 2 && (
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Min Number
                                  </label>
                                  <input
                                    id="min_number"
                                    type="text"
                                    name="min_number"
                                    defaultValue={this.state.min_number}
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>

                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Max Number
                                  </label>
                                  <input
                                    id="max_number"
                                    type="text"
                                    name="max_number"
                                    defaultValue={this.state.max_number}
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>

                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Max Modifier Quantity
                                  </label>
                                  <input
                                    id="max_modifier_qunatity_selection"
                                    type="text"
                                    name="max_modifier_qunatity_selection"
                                    defaultValue={
                                      this.state.max_modifier_qunatity_selection
                                    }
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Description
                                </label>
                                <input
                                  id="modgroupdesc"
                                  type="text"
                                  name="modgroupdesc"
                                  defaultValue={
                                    modGroupData.mod_group_description
                                  }
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Modifier Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="type"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Type"
                                  onChange={this.handleInputChange}
                                >
                                  <option
                                    {...(modGroupData.modifiers_type ===
                                      "single" && { selected: true })}
                                    value="single"
                                  >
                                    Single Select
                                  </option>
                                  <option
                                    {...(modGroupData.modifiers_type ===
                                      "multiple" && { selected: true })}
                                    value="multiple"
                                  >
                                    Multi Select
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Priority
                                </label>
                                <input
                                  id="priority"
                                  type="number"
                                  name="priority"
                                  defaultValue={modGroupData.priority}
                                  required
                                  data-msg="Please enter Priority"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Min Quantity
                                </label>
                                <input
                                  id="min_number"
                                  type="number"
                                  name="min_number"
                                  value={this.state.min_number}
                                  required
                                  data-msg="Please enter Min Quantity"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Max Quantity
                                </label>
                                <input
                                  id="max_number"
                                  type="number"
                                  name="max_number"
                                  value={this.state.max_number}
                                  required
                                  data-msg="Please enter Max Quantity"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group d-flex justify-content-end mt-4">
                            <button
                              onClick={this.handleSubmit}
                              disabled={!this.isModGroupReady()}
                              className="btn btn-primary"
                            >
                              Update Modifier Group
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    modGroupData: state.menu.modGroupData,
    groups: state.menu.groupsptions,
    items: state.menu.allActiveItems,
    message: state.menu.message,
    isUpdated: state.menu.isUpdated,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editModGroup: function (id: any, data: any, type: any, handleState: any) {
      dispatch(editModGroup(id, data, type, handleState));
    },
    getModGroup: function (id: number, menu_type: any) {
      dispatch(getModGroup(id, menu_type));
    },
    groupsList: function () {
      dispatch(groupsListForMultiSelect());
    },
    itemsListForMultiSelect: function () {
      dispatch(itemsListForMultiSelect());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditMod);
