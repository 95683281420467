import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import {
  getAllMenuList,
  updateItemAvailability,
  syncBranchMenuWithStore,
  logoutUser,
  statusChangeReasons,
  updateItemStatus,
  searchModifiers,
  searchMenuItem,
  searchGroup,
  searchModifierGroups,
  searchCombos,
  brandsList,
} from "../../redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Toggle from "react-toggle";
import "react-tabs/style/react-tabs.css";
import "react-toggle/style.css";
import "./menuStyle.css";
import Select from "react-select";
import { storesList } from "../../redux/actions/reportAction";
import { storesListByBrand } from "../../redux/actions/storeAction";
import { aggregatorsList } from "../../redux/actions/aggregatorsAction";
interface MenuState {
  [x: number]: any;
  activeTab: any;
  status: any;
  statusReason: any;
  is_active: any;
  data: any;
  store: any;
  store_name: any;
  brand: any;
  menu_type: any;
  is_available: any;
  date: any;
  menu_item_id: any;
  selectedtype: any;
  hungerstation: any;
}
interface MenuProps {
  history: any;
  groupsData: any;
  itemsData: any;
  combosData: any;
  modgroupsData: any;
  modifiersData: any;
  searchItemData: any;
  searchGroupData: any;
  searchComboData: any;
  searchmodifiersData: any;
  searchmodgroupsData: any;
  itemStatusReasons: any[];
  stores: any[];
  brands: any;
  aggregators: any;
  brandsList: () => {};
  aggregatorsList: () => {};
  searchMenuItem: (value: any, data: any) => {};
  searchGroup: (value: any, data: any) => {};
  searchModifierGroups: (value: any, data: any) => {};
  searchModifiers: (value: any, data: any) => {};
  searchCombo: (value: any, data: any) => {};
  storesList: (brand: any) => {};
  getAllMenuList: (store_id: any, menu_type: any) => {};
  syncBranchMenuWithStore: (store_id: any, menu_type: any) => {};
  updateItemAvailability: (
    id: any,
    type: any,
    availability: any,
    date: any,
    store: any,
  ) => {};
  statusChange: () => {};
  updateItemStatus: (data: any, history: any) => {};
}
class BranchMenu extends Component<MenuProps, MenuState> {
  openModal: any;
  closeModal: any;
  constructor(readonly props: any) {
    super(props);
    this.state = {
      activeTab: 0,
      brand: "",
      store_name: "",
      status: false,
      is_active: "",
      data: {},
      statusReason: "",
      store: "",
      menu_type: "",
      is_available: "",
      date: "",
      menu_item_id: "",
      selectedtype: "",
      hungerstation: "",
    };
    this.handleToggleChange = this.handleToggleChange.bind(this);
    this.saveLogsForToggle = this.saveLogsForToggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    this.props.statusChange();
    this.props.brandsList();
    this.props.aggregatorsList();
    document.title = "SimpleXSync | Menu";
  }
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.props.getAllMenuList(e.value, this.state.menu_type);
      this.setState({
        store: e.value,
        store_name: e.label,
        hungerstation: e.hungerstation_vendor_code,
      });
    } else {
      this.setState({ store: " " });
    }
  };
  handleToggleChange(e: any, data: any, type: any, id: any) {
    if (type == "group" || type == "modifier" || type == "combo") {
      if (this.state.menu_type == 4) {
        alert(
          "Hungerstation only allow modifier group and menu item availability status change",
        );
      } else {
        alert("Deliveroo only allow menu item availability status change");
      }
    } else if (this.state.menu_type == 2 && type == "modifiergroup") {
      alert("Deliveroo only allow menu item availability status change");
    } else {
      this.openModal.click();
      this.setState({
        data: data,
        is_active: e.target.checked == false ? 0 : 1,
        selectedtype: type,
        menu_item_id: id,
      });
    }
  }
  handleAvailability = (e: any) => {
    this.setState({ is_available: e.target.value });
  };
  saveLogsForToggle() {
    let { data, is_active, statusReason, store } = this.state;
    let body: any = {
      is_active: is_active,
      reason: statusReason,
      status: is_active == 1 ? "UnBlock" : "Block",
      store_id: store,
    };
    if (data.group_id) {
      body.group_id = data.group_id.group_id;
    } else if (data.menu_item_id) {
      body.menu_item_id = data.menu_item_id.menu_item_id;
      body.erp_id = data.menu_item_id.erp_id;
    } else if (data.combo_id) {
      body.combo_id = data.combo_id.combo_id;
      body.erp_id = data.combo_id.erp_id;
    }
    body.menu_type = this.state.menu_type;
    this.props.updateItemStatus(body, this.props.history);
    this.closeModal.click();
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name == "menu_item") {
      this.props.searchMenuItem(event.target.value, this.props.itemsData);
    } else if (event.target.name == "group") {
      this.props.searchGroup(event.target.value, this.props.groupsData);
    } else if (event.target.name == "combo") {
      this.props.searchCombo(event.target.value, this.props.combosData);
    } else if (event.target.name == "modifiergroups") {
      this.props.searchModifierGroups(
        event.target.value,
        this.props.modgroupsData,
      );
    } else if (event.target.name == "modifiers") {
      this.props.searchModifiers(event.target.value, this.props.modifiersData);
    }
  }
  handleDate = (e: any) => {
    this.setState({ date: e.target.value });
  };
  isSaveStatusReady = () => {
    const { statusReason, is_active } = this.state;
    if (is_active == 0) {
      return statusReason !== "";
    } else {
      return true;
    }
  };
  changeTab = (index: any) => {
    this.setState({
      activeTab: index,
    });
    this.props.getAllMenuList(this.state.store, this.state.menu_type);
  };
  handleConfirm = () => {
    this.props.syncBranchMenuWithStore(this.state.store, this.state.menu_type);
  };
  handleConfirmAvailability = () => {
    if (
      this.state.selectedtype == "group" ||
      this.state.selectedtype == "modifier" ||
      this.state.selectedtype == "combo"
    ) {
      alert(
        "Hungerstation Only Allow Modifier Group and Menu Item Availability Status Change",
      );
    } else {
      let newDate = `${this.state.date}:00Z`;
      if (this.state.selectedtype == "menuitem") {
        this.props.updateItemAvailability(
          this.state.menu_item_id,
          "product",
          this.state.is_available,
          newDate,
          this.state.store,
        );
      } else {
        this.props.updateItemAvailability(
          this.state.menu_item_id,
          "topping",
          this.state.is_available,
          newDate,
          this.state.store,
        );
      }
    }
  };
  handleBrands = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.setState({
        brand: e.value,
        store: "",
        store_name: "",
        menu_type: "",
      });
      this.props.storesList(e.value);
    } else {
      this.setState({ brand: "", store: "", store_name: "", menu_type: "" });
    }
  };
  handleMenuType = (e: any) => {
    this.setState({ menu_type: e.target.value, store: "", store_name: "" });
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let tokendata: any = jwt.decode(sessionStorage.token);
    let roleId = tokendata.role_id;
    let brandId = tokendata.brand_id;
    let {
      searchGroupData,
      searchItemData,
      searchComboData,
      searchmodgroupsData,
      searchmodifiersData,
      itemStatusReasons,
      brands,
    } = this.props;
    let { is_active } = this.state;
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Menu Management</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row form-group">
                  <label
                    className="col-md-1 col-sm-2 form-control-label font-weight-bold"
                    style={{ paddingTop: "0.5em" }}
                  >
                    Brand
                  </label>

                  <div className="col-md-11 col-sm-12">
                    <Select
                      name="brand"
                      isClearable
                      options={
                        roleId == 7
                          ? brands
                          : brands.filter((o1: any) => brandId === o1.brand_id)
                      }
                      className="text-capitalize select mt-2"
                      classNamePrefix="select"
                      onChange={(e, i) => this.handleBrands(e, i)}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <label
                    className="col-md-1 col-sm-2 form-control-label font-weight-bold"
                    style={{ paddingTop: "0.5em" }}
                  >
                    Menu
                  </label>
                  <div className="col-md-11 col-sm-12">
                    <select
                      name="menu_type"
                      className="form-control text-capitalize mt-2"
                      required
                      data-msg="Please select menu type"
                      onChange={this.handleMenuType}
                    >
                      <option value="">Select Menu</option>
                      {/* <option value="master">Master Menu</option> */}
                      {/* <option value="aggregator">Aggregator Menu</option> */}
                      {this.props.aggregators.map((data: any) => {
                        return (
                          <option value={data.id}>
                            {data.aggregator_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="row form-group">
                  <label
                    className="col-md-1 col-sm-2 form-control-label font-weight-bold"
                    style={{ paddingTop: "0.5em" }}
                  >
                    Stores
                  </label>
                  <div className="col-md-11 col-sm-12">
                    <Select
                      //isMulti
                      name="storeType"
                      isClearable
                      options={
                        this.state.brand != "" && this.state.menu_type != ""
                          ? this.props.stores
                          : []
                      }
                      className="text-capitalize select mt-2"
                      classNamePrefix="select"
                      value={{
                        label:
                          this.state.store_name == ""
                            ? "Select Store"
                            : this.state.store_name,
                        value: this.state.store,
                      }}
                      onChange={(e, i) => this.handleStoresInputChange(e, i)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body p-0 mb-5">
                        <Tabs
                          selectedIndex={this.state.activeTab}
                          onSelect={(index) => this.changeTab(index)}
                        >
                          <TabList
                            style={{
                              background: "#20a5d6",
                              color: "#FFF",
                              padding: "10px",
                            }}
                          >
                            <Tab>Groups</Tab>
                            <Tab>Menu Items</Tab>
                            <Tab>Combos</Tab>
                            <Tab>Modifier Groups</Tab>
                            <Tab>Modifiers</Tab>
                            <button
                              title="Sync Menu"
                              data-toggle="modal"
                              data-target={`#sync`}
                              style={{ marginLeft: "30%" }}
                              className="sync_button btn btn-outline-info"
                            >
                              <img
                                title="Sync Menu with Aggregator"
                                alt="sync menu"
                                className="rounded-circle"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/sync.png"
                                }
                                width="20px"
                                height="20px"
                              />
                            </button>
                            <div
                              id={`sync`}
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                              className="modal fade text-dark"
                            >
                              <div role="document" className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h4
                                      id="exampleModalLabel"
                                      className="modal-title"
                                    >
                                      Sync Branch Menu With Aggregator
                                    </h4>
                                    <button
                                      type="button"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      className="close"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <p>
                                      Are you sure,you want to Sync Branch Menu
                                      With Aggregator?
                                    </p>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      data-dismiss="modal"
                                      className="btn btn-secondary"
                                    >
                                      Close
                                    </button>
                                    <button
                                      onClick={() => this.handleConfirm()}
                                      className="btn btn-primary"
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabList>

                          <TabPanel style={{ marginTop: "2em" }}>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-lg-12 col-12">
                                  <div className="card-header">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <strong>All Groups</strong>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="search-box">
                                          <label className="mb-0">
                                            <i className="fa fa-search"></i>
                                          </label>
                                          <input
                                            id="group"
                                            type="text"
                                            name="group"
                                            style={{
                                              border: "none",
                                              marginLeft: "10px",
                                              width: "100%",
                                            }}
                                            required
                                            data-msg="Please enter group"
                                            onChange={this.handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="card-body text-capitalize">
                                      <div className="row">
                                        {searchGroupData.length > 0 &&
                                        this.state.menu_type != "" ? (
                                          searchGroupData.map(
                                            (element: any, index: any) => {
                                              return (
                                                <div className="col-lg-6 my-2">
                                                  <div className="d-flex justify-content-between">
                                                    <div>
                                                      {
                                                        element.group_id
                                                          .group_name
                                                      }
                                                    </div>
                                                    <div>
                                                      <Toggle
                                                        className="custom-classname"
                                                        checked={
                                                          element.is_active == 0
                                                            ? false
                                                            : true
                                                        }
                                                        icons={false}
                                                        onChange={(e: any) =>
                                                          this.handleToggleChange(
                                                            e,
                                                            element,
                                                            "group",
                                                            element.group_id
                                                              .group_id,
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            },
                                          )
                                        ) : (
                                          <div className="col-12 text-center">
                                            <h5>Groups not found</h5>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel style={{ marginTop: "2em" }}>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-lg-12 col-12">
                                  <div className="card-header">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <strong>All Items</strong>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="search-box">
                                          <label className="mb-0">
                                            <i className="fa fa-search"></i>
                                          </label>
                                          <input
                                            id="menu_item"
                                            type="text"
                                            name="menu_item"
                                            style={{
                                              border: "none",
                                              marginLeft: "10px",
                                              width: "100%",
                                            }}
                                            required
                                            data-msg="Please enter item"
                                            onChange={this.handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="row">
                                        {searchItemData.length > 0 &&
                                        this.state.menu_type != "" ? (
                                          searchItemData.map(
                                            (element: any, index: any) => {
                                              return (
                                                <div className="col-lg-6 my-2">
                                                  <div className="d-flex justify-content-between">
                                                    <div>
                                                      {
                                                        element.menu_item_id
                                                          .item_name
                                                      }
                                                    </div>
                                                    <div>
                                                      <Toggle
                                                        className="custom-classname"
                                                        checked={
                                                          element.is_active == 0
                                                            ? false
                                                            : true
                                                        }
                                                        icons={false}
                                                        onChange={(e: any) =>
                                                          this.handleToggleChange(
                                                            e,
                                                            element,
                                                            "menuitem",
                                                            element.menu_item_id
                                                              .menu_item_id,
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            },
                                          )
                                        ) : (
                                          <div className="col-12 text-center">
                                            <h5>Menu Items not found</h5>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel style={{ marginTop: "2em" }}>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-lg-12 col-12">
                                  <div className="card-header">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <strong>All Combos</strong>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="search-box">
                                          <label className="mb-0">
                                            <i className="fa fa-search"></i>
                                          </label>
                                          <input
                                            id="combo"
                                            type="text"
                                            name="combo"
                                            style={{
                                              border: "none",
                                              marginLeft: "10px",
                                              width: "100%",
                                            }}
                                            required
                                            data-msg="Please enter combo"
                                            onChange={this.handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="row">
                                        {searchComboData.length > 0 &&
                                        this.state.menu_type != "" ? (
                                          searchComboData.map(
                                            (element: any, index: any) => {
                                              return (
                                                <div className="col-lg-6 my-2">
                                                  <div className="d-flex justify-content-between">
                                                    <div>
                                                      {
                                                        element.combo_id
                                                          .combo_name
                                                      }
                                                    </div>
                                                    <div>
                                                      <Toggle
                                                        className="custom-classname"
                                                        checked={
                                                          element.is_active == 0
                                                            ? false
                                                            : true
                                                        }
                                                        icons={false}
                                                        onChange={(e: any) =>
                                                          this.handleToggleChange(
                                                            e,
                                                            element,
                                                            "combo",
                                                            "s",
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            },
                                          )
                                        ) : (
                                          <div className="col-12 text-center">
                                            <h5>Combos not found</h5>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel style={{ marginTop: "2em" }}>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-lg-12 col-12">
                                  <div className="card-header">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <strong>All Modifier Groups</strong>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="search-box">
                                          <label className="mb-0">
                                            <i className="fa fa-search"></i>
                                          </label>
                                          <input
                                            id="modifiergroups"
                                            type="text"
                                            name="modifiergroups"
                                            style={{
                                              border: "none",
                                              marginLeft: "10px",
                                              width: "100%",
                                            }}
                                            required
                                            data-msg="Please enter combo"
                                            onChange={this.handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="row">
                                        {searchmodgroupsData.length > 0 &&
                                        this.state.menu_type != "" ? (
                                          searchmodgroupsData.map(
                                            (element: any, index: any) => {
                                              return (
                                                <div className="col-lg-6 my-2">
                                                  <div className="d-flex justify-content-between">
                                                    <div>
                                                      {
                                                        element
                                                          .modifier_group_id
                                                          .erp_id
                                                      }{" "}
                                                      ){" "}
                                                      {
                                                        element
                                                          .modifier_group_id
                                                          .mod_group_name
                                                      }
                                                    </div>
                                                    <div>
                                                      <Toggle
                                                        className="custom-classname"
                                                        checked={
                                                          element.is_active == 0
                                                            ? false
                                                            : true
                                                        }
                                                        icons={false}
                                                        onChange={(e: any) =>
                                                          this.handleToggleChange(
                                                            e,
                                                            element,
                                                            "modifiergroup",
                                                            element
                                                              .modifier_group_id
                                                              .mod_group_id,
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            },
                                          )
                                        ) : (
                                          <div className="col-12 text-center">
                                            <h5>Modifier Groups not found</h5>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel style={{ marginTop: "2em" }}>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-lg-12 col-12">
                                  <div className="card-header">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <strong>All Modifiers </strong>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="search-box">
                                          <label className="mb-0">
                                            <i className="fa fa-search"></i>
                                          </label>
                                          <input
                                            id="modifiers"
                                            type="text"
                                            name="modifiers"
                                            style={{
                                              border: "none",
                                              marginLeft: "10px",
                                              width: "100%",
                                            }}
                                            required
                                            data-msg="Please enter combo"
                                            onChange={this.handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="row">
                                        {searchmodifiersData.length > 0 &&
                                        this.state.menu_type != "" ? (
                                          searchmodifiersData.map(
                                            (element: any, index: any) => {
                                              return (
                                                <div className="col-lg-6 my-2">
                                                  <div className="d-flex justify-content-between">
                                                    <div>
                                                      {
                                                        element.modifier_id
                                                          ?.modifier_name
                                                      }
                                                    </div>
                                                    <div>
                                                      <Toggle
                                                        className="custom-classname"
                                                        checked={
                                                          element.is_active == 0
                                                            ? false
                                                            : true
                                                        }
                                                        icons={false}
                                                        onChange={(e: any) =>
                                                          this.handleToggleChange(
                                                            e,
                                                            element,
                                                            "modifier",
                                                            "m",
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            },
                                          )
                                        ) : (
                                          <div className="col-12 text-center">
                                            <h5>Modifiers not found</h5>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
        {/* End Print Preview */}
        <div style={{ display: "none" }}>
          <button
            ref={(el) => (this.openModal = el)}
            data-toggle="modal"
            data-target={`#saveLogsNew`}
            className="btn btn-outline-info ml-2"
          >
            <i className="fa fa-file-pdf-o"></i>
          </button>
        </div>
        <div
          id={`saveLogs`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  {is_active == 1 ? "UnBlock" : "Block"} Item
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <h6>
                      Are you sure,you want to{" "}
                      {is_active == 1 ? "UnBlock" : "Block"} the Item?
                    </h6>
                  </div>
                  {is_active == 0 && (
                    <div className="col-12">
                      <div className="form-group">
                        <select
                          name="statusReason"
                          onChange={this.handleInputChange}
                          className="form-control mt-2"
                        >
                          <option value="">Select Reason</option>
                          {itemStatusReasons &&
                            itemStatusReasons.map((reason: any, index: any) => (
                              <option key={index} value={reason.id}>
                                {reason.reason}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-group d-flex justify-content-end mt-4">
                  <button
                    className="btn btn-primary"
                    disabled={!this.isSaveStatusReady()}
                    onClick={() => this.saveLogsForToggle()}
                  >
                    Save
                  </button>
                  {/*  */}
                </div>
              </div>
              <div style={{ display: "none" }}>
                <button
                  ref={(el) => (this.closeModal = el)}
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-info"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id={`saveLogsNew`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {this.state.menu_type == 4 ? (
                  <h4 id="exampleModalLabel" className="modal-title">
                    Hungerstation Item Availability
                  </h4>
                ) : (
                  <>
                    <h4 id="exampleModalLabel" className="modal-title">
                      Deliveroo Item Availability
                    </h4>
                  </>
                )}
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-12">
                  <div className="form-group">
                    <select
                      name="fp_store_status"
                      value={this.state.is_available}
                      onChange={this.handleAvailability}
                      className="form-control mt-2"
                    >
                      <option value="">Select Status</option>
                      <option value={"true"}>{"AVAILABLE"}</option>
                      <option value={"false"}>{"UNAVAILABLE"}</option>
                    </select>
                  </div>
                  {this.state.is_available == "false" &&
                  this.state.menu_type == 4 ? (
                    <>
                      <span>Will Be Avaiable From</span>
                      <input
                        value={this.state.date}
                        onChange={this.handleDate}
                        id="storeopen"
                        type="datetime-local"
                        name="storeopen"
                        required
                        data-msg="Please enter Opening Time"
                        className="input-material"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-primary"
                  onClick={() => this.handleConfirmAvailability()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    groupsData: state.menu.groupsData,
    itemsData: state.menu.itemsData,
    combosData: state.menu.combosData,
    modgroupsData: state.menu.modgroupsData,
    modifiersData: state.menu.modifiersData,
    searchItemData: state.menu.searchItemData,
    searchGroupData: state.menu.searchGroupData,
    searchComboData: state.menu.searchComboData,
    searchmodifiersData: state.menu.searchmodifiersData,
    searchmodgroupsData: state.menu.searchmodgroupsData,
    itemStatusReasons: state.menu.statusChangeReasons,
    stores: state.report.stores,
    brands: state.store.brands,
    aggregators: state.aggregator.aggregators,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    getAllMenuList: (store_id: any, menu_type: any) => {
      dispatch(getAllMenuList(store_id, menu_type));
    },
    syncBranchMenuWithStore: (store_id: any, menu_type: any) => {
      dispatch(syncBranchMenuWithStore(store_id, menu_type));
    },
    updateItemStatus: (data: any, history: any) => {
      dispatch(updateItemStatus(data, history));
    },
    statusChange: () => {
      dispatch(statusChangeReasons());
    },
    storesList: (brand: any) => {
      dispatch(storesListByBrand(brand));
    },
    searchGroup: (value: any, data: any) => {
      dispatch(searchGroup(value, data));
    },
    searchModifierGroups: (value: any, data: any) => {
      dispatch(searchModifierGroups(value, data));
    },
    searchModifiers: (value: any, data: any) => {
      dispatch(searchModifiers(value, data));
    },
    searchMenuItem: (value: any, data: any) => {
      dispatch(searchMenuItem(value, data));
    },
    searchCombo: (value: any, data: any) => {
      dispatch(searchCombos(value, data));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
    aggregatorsList: () => {
      dispatch(aggregatorsList());
    },
    updateItemAvailability: function (
      id: any,
      type: any,
      availability: any,
      date: any,
      store: any,
    ) {
      dispatch(updateItemAvailability(id, type, availability, date, store));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BranchMenu);
