import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import { connect } from "react-redux";
import {
  blockunblockcombo,
  brandsList,
  combosList,
  logoutUser,
} from "../../../redux";
import { ComboProps } from "../../../interfaces/menu";
import { currency } from "../../../client-config";
import moment from "moment";
import { aggregatorsList } from "../../../redux/actions/aggregatorsAction";
import Select from "react-select";
class ActionFormatter extends Component<{ row: any }, {}> {
  closeModal: any;
  constructor(readonly props: any) {
    super(props);
  }
  handleBlockUnblock = (id: any, is_active: any) => {
    let type = this.props.row.aggregator_id
      ? this.props.row.aggregator_id
      : "master";
    this.props.data.blockunblockcombo(
      id,
      is_active,
      this.props.row.combo_name,
      type,
    );
    this.closeModal.click();
  };
  IsJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title={row.is_active === 0 ? "UnBlock" : "Block"}
          data-toggle="modal"
          data-target={`#blockunblock${row.combo_id}`}
          className={
            row.is_active === 0
              ? "btn btn-outline-success mr-2"
              : "btn btn-outline-danger mr-2"
          }
        >
          <i
            className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}
          ></i>
        </button>
        <button
          title="View Items"
          data-toggle="modal"
          data-target={`#itemdetails${row.combo_id}`}
          className="btn btn-outline-info"
        >
          <i className="fa fa-list"></i>
        </button>
        <Link
          title="Edit Combo"
          className="btn btn-outline-primary ml-2"
          to={`/edit-combo/${row.combo_id}/${row.aggregator_id ? row.aggregator_id : "master"}`}
        >
          <i className="fa fa-edit"></i>
        </Link>
        {/* <!-- Modal--> */}
        <div
          id={`itemdetails${row.combo_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Combo Items
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group text-capitalize">
                      <BootstrapTable
                        version="4"
                        data={
                          this.IsJsonString(row.combo_ingredients)
                            ? JSON.parse(row.combo_ingredients)
                            : ""
                        }
                        hover
                      >
                        <TableHeaderColumn
                          dataField="id"
                          columnTitle
                          isKey
                          hidden
                        >
                          Size
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="itemName" columnTitle>
                          Item
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="size" columnTitle>
                          Size
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="quantity" columnTitle>
                          Quantity
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Block/Unblock Modal--> */}
        <div
          id={`blockunblock${row.combo_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  {row.is_active === 0 ? "UnBlock" : "Block"} Combo
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure,you want to{" "}
                  {row.is_active === 0 ? "UnBlock" : "Block"} this combo?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  ref={(el) => (this.closeModal = el)}
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  onClick={() =>
                    this.handleBlockUnblock(row.combo_id, row.is_active)
                  }
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}
function orderModesFormatter(cell: any, row: any, props: any) {
  return <OrderModesFormatter row={row} data={props} />;
}
class OrderModesFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  IsJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  render() {
    const { row } = this.props;
    return (
      <div>
        {row.item_mode && (
          <button
            title="View Modes"
            data-toggle="modal"
            data-target={`#itemmodes${row.combo_id}`}
            className="btn btn-outline-info"
          >
            <i className="fa fa-list"></i>
          </button>
        )}
        {/* <!-- Modal--> */}
        <div
          id={`itemmodes${row.combo_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Order Modes
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group text-capitalize">
                      <BootstrapTable
                        version="4"
                        data={
                          row.item_mode && this.IsJsonString(row.item_mode)
                            ? JSON.parse(row.item_mode)
                            : ""
                        }
                        hover
                      >
                        <TableHeaderColumn dataField="label" columnTitle isKey>
                          Order Mode
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class Combo extends Component<ComboProps, { menu_type: any; brand_id: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      menu_type: "",
      brand_id: null,
    };
  }
  componentDidMount() {
    this.props.aggregatorsList();
    this.props.brandsList();
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let role_id: any = decoded.role_id;
    if (role_id != 7) {
      this.setState({ brand_id: decoded.brand_id });
    }
    document.title = "SimpleXSync | Combos";
  }
  handleMenuType = (e: any) => {
    this.setState({ menu_type: e.target.value });
    if (this.state.brand_id) {
      this.props.combosList(e.target.value, this.state.brand_id);
    }
  };
  handleSubmit = () => {
    this.props.combosList(this.state.menu_type);
  };

  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
    this.props.combosList(this.state.menu_type, e.value);
  };

  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    const options: any = {
      // sizePerPageList: [5,10,15],
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      insertModal: () => {
        return <Redirect to="/add-combo" />;
      },
      noDataText: "Combos Not Found",
    };
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let role_id: any = decoded.role_id;
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Combo Management</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-1">
                    <label className="form-control-label">
                      Menu Type <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-11">
                    <div className="form-group">
                      <select
                        name="menutype"
                        className="form-control text-capitalize mt-2"
                        required
                        data-msg="Please select MenuType"
                        onChange={this.handleMenuType}
                      >
                        <option value="">Select Menu Type</option>
                        <option value="master">Master Menu</option>
                        {/* <option value="aggregator">Aggregator Menu</option> */}
                        {this.props.aggregators.map((data: any) => {
                          return (
                            <option value={data.id}>
                              {data.aggregator_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-3">
                                        <br />
                                        <button onClick={this.handleSubmit} className="btn btn-primary mt-3">Submit</button>
                                    </div> */}
                </div>
                {role_id == 7 && (
                  <div className="row">
                    <div className="col-1">
                      <label className="form-control-label">
                        Select Brand <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-11">
                      <div className="form-group">
                        <Select
                          name="brands"
                          options={selectedBrands}
                          className="text-capitalize basic-multi-select mt-2"
                          classNamePrefix="select"
                          // value={{label:this.state.brand_name,value:this.state.brand_id}}
                          onChange={(e: any, i: any) =>
                            this.handleBrandsInputChange(e, i)
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body text-capitalize">
                        <BootstrapTable
                          version="4"
                          data={this.props.data}
                          search={true}
                          pagination={this.props.data.length > 10 && true}
                          options={options}
                          exportCSV={true}
                          insertRow
                          csvFileName="combos.csv"
                          hover
                        >
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="combo_id"
                            csvHeader="#"
                            width="70"
                            dataSort={true}
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="comboUnique"
                            csvHeader="Combo Unique ID"
                            width="100"
                            columnTitle
                          >
                            Combo Unique Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="combo_name"
                            csvHeader="Combo Name"
                            width="100"
                            columnTitle
                          >
                            Combo name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="menu_name"
                            csvHeader="Menu"
                            width="100"
                            columnTitle
                          >
                            Menu
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="group_name"
                            csvHeader="Group"
                            width="100"
                            columnTitle
                          >
                            Group
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="sub_group_name"
                            csvHeader="Sub Group"
                            width="100"
                            columnTitle
                          >
                            Sub Group
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="priority"
                            csvHeader="Priority"
                            width="100"
                            columnTitle
                          >
                            Priority
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="item_mode"
                            csvHeader="Mode"
                            width="100"
                            dataFormat={orderModesFormatter}
                            columnTitle
                          >
                            Mode
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_cost_price' csvHeader='Cost Price' dataFormat={priceFormatter} width='100' columnTitle>Cost Price</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='combo_mrp_price' csvHeader='Max Retail Price' dataFormat={priceFormatter} width='130' columnTitle>Max Retail Price</TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="combo_start_time"
                            csvHeader="Combo Start Time"
                            dataFormat={timeFormatter}
                            width="150"
                          >
                            Combo Start Time
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="combo_close_time"
                            csvHeader="Combo Close Time"
                            dataFormat={timeFormatter}
                            width="150"
                          >
                            Combo Close Time
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="hero_item"
                            width="100"
                            dataFormat={heroItemFormatter}
                            export={false}
                          >
                            HeroItem
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="topDeal"
                            width="100"
                            dataFormat={topDealFormatter}
                            export={false}
                          >
                            TopDeal
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="action"
                            width="180"
                            dataFormat={actionFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Action
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
function heroItemFormatter(cell: any) {
  return (
    <div className="text-capitalize">
      <span {...(cell == 1 && { className: "badge badge-success p-2" })}>
        {cell == 1 && <i className="fa fa-check-square-o"></i>}
      </span>
    </div>
  );
}
function topDealFormatter(cell: any) {
  return (
    <div className="text-capitalize">
      <span {...(cell == 1 && { className: "badge badge-success p-2" })}>
        {cell == 1 && <i className="fa fa-check-square-o"></i>}
      </span>
    </div>
  );
}
function priceFormatter(cell: any) {
  return `${currency}.${cell}`;
}
function timeFormatter(cell: any) {
  return (
    <div>
      <span
        {...(cell !== "" &&
          cell !== null && {
            title: moment(cell).local().format("YYYY-MM-DD HH:mm"),
          })}
      >
        {" "}
        {cell !== "" &&
          cell !== null &&
          moment(cell).local().format("YYYY-MM-DD HH:mm")}{" "}
      </span>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    data: state.menu.combos,
    aggregators: state.aggregator.aggregators,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    combosList: function (type: any, brand_id: any) {
      dispatch(combosList(type, brand_id));
    },
    blockunblockcombo: function (
      id: any,
      is_active: any,
      combo_name: any,
      type: any,
    ) {
      dispatch(blockunblockcombo(id, is_active, combo_name, type));
    },
    aggregatorsList: () => {
      dispatch(aggregatorsList());
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Combo);
