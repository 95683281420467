import {
  HANDLE_INPUT,
  BRANDS_LIST,
  ADD_BRAND,
  EDIT_BRAND,
  GET_BRAND,
} from "../actions/brandType";
const initialState: any = {
  brandname: "",
  branddetails: "",
  brandtype: "",
  brands: [] || "",
  message: "",
  isInserted: "",
  isUpdated: "",
  brandData: null,
  brand_unique_code: "",
};

const brandReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HANDLE_INPUT:
      return {
        ...state,
        [action.input.name]: action.input.value,
      };
    case BRANDS_LIST:
      return {
        ...state,
        brands: action.payload,
      };
    case ADD_BRAND:
      return {
        ...state,
        message: action.payload,
        isInserted: action.isInserted,
      };
    case EDIT_BRAND:
      return {
        ...state,
        message: action.payload,
        isUpdated: action.isUpdated,
      };
    case GET_BRAND:
      return {
        ...state,
        brandData: action.payload,
        brandname: action.payload.brand_name,
        branddetails: action.payload.brand_details,
        brandtype: action.payload.brand_type,
        brand_unique_code: action.payload.brand_unique_code,
      };
    default:
      return state;
  }
};
export default brandReducer;
