// import React, { Component } from 'react';
// import { connect } from 'react-redux'
// import { AddTradeZoneProps } from '../../interfaces/tradeZone';
// import { Link, Redirect } from 'react-router-dom'
// import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import Topbar from '../../components/topbar'
// import Sidebar from '../../components/sidebar'
// import Footer from '../../components/footer/main'
// import CheckChanges from '../../components/confirmOnLeave'
// import { addTradeZone, citiesList, handleZoneInput, logoutUser, storesList } from '../../redux';
// class AddTradeZone extends Component<AddTradeZoneProps, {}> {
//     constructor(props: any) {
//         super(props);
//         this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
//     }
//     componentDidMount() {
//         document.title = "SimplexYum | TradeZones";
//         this.props.citiesList();
//     }
//     isZoneReady = () => {
//         const { tradezonename, city } = this.props;
//         return (tradezonename !== "" && city !== "");
//     }
//     handleSaveBtnClick = () => {
//         let { tradezonename, city } = this.props;
//         let data = {
//             delivery_zone_name: tradezonename,
//             city_id: city,
//             is_active: 1
//         }
//         this.props.addTradeZone(data);
//     }
//     render() {
//         if (sessionStorage.token) {
//             jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
//                 if (err) {
//                     this.props.logoutUser();
//                 }
//             });
//         } else {
//             return <Redirect to="/" />
//         }
//         let { cities } = this.props;
//         return (
//             <div className="page">
//                 <CheckChanges path="/add-brand" />
//                 <Topbar />
//                 <div className="page-content d-flex align-items-stretch">
//                     <Sidebar />
//                     <div className="content-inner">
//                         {/*  Page Header */}
//                         <header className="page-header py-0">
//                             <div className="container-fluid">
//                                 <div className="d-flex justify-content-between py-3">
//                                     <h4 className="mt-2">Trade Zones Management</h4>
//                                 </div>
//                             </div>
//                         </header>
//                         {/* Breadcrumb */}
//                         <div className="breadcrumb-holder container-fluid">
//                             <ul className="breadcrumb">
//                                 <li className="breadcrumb-item"><Link to="/tradezone" className="text-primary">Trade Zone</Link></li>
//                                 <li className="breadcrumb-item active">Add Trade Zones</li>
//                             </ul>
//                         </div>
//                         <section className="forms">
//                             <div className="container-fluid">
//                                 <div className="row">
//                                     <div className="col-lg-12">
//                                         <div className="card">
//                                             <div className="card-body">
//                                                 <div className="row">
//                                                     <div className="col">
//                                                         <div className="form-group">
//                                                             <label className="form-control-label">Trade Zone name <span className="text-danger">*</span></label>
//                                                             <input id="tradezonename" type="text" name="tradezonename" required data-msg="Please enter TradeZoneName" className="input-material" onChange={this.props.handleZoneInput} />
//                                                         </div>
//                                                     </div>
//                                                     <div className="col">
//                                                         <div className="form-group">
//                                                             <label className="form-control-label">City</label>
//                                                             <select name="city" className="form-control mt-2" required data-msg="Please Select City" onChange={this.props.handleZoneInput}>
//                                                                 <option value="">Select City</option>
//                                                                 {cities &&
//                                                                     cities.map((city, index) => (
//                                                                         <option key={index} value={city.id}>{city.name}</option>
//                                                                     ))
//                                                                 }
//                                                             </select>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div className="form-group d-flex justify-content-end mt-4">
//                                                     <button className='btn btn-primary' disabled={!this.isZoneReady()} onClick={this.handleSaveBtnClick} >Save</button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </section>
//                         <Footer />
//                     </div>
//                 </div>
//             </div >
//         );
//     }
// }
// const mapStateToProps = (state: any) => {
//     return {
//         tradezonename: state.tradezone.tradezonename,
//         city: state.tradezone.city,
//         cities: state.store.cities
//     }
// }
// const mapDispatchToProps = (dispatch: any) => {
//     return {
//         handleZoneInput: (event: any) => { dispatch(handleZoneInput(event)) },
//         logoutUser: () => { dispatch(logoutUser()); },
//         citiesList: () => { dispatch(citiesList()) },
//         addTradeZone: (data: any) => { dispatch(addTradeZone(data)); }
//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(AddTradeZone);
import React, { Component } from "react";
import { connect } from "react-redux";
import { AddTradeZoneProps } from "../../interfaces/tradeZone";
import { Link, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import Map from "./map";
import { secretKey } from "../../secret";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import {
  addTradeZone,
  citiesList,
  handleZoneInput,
  logoutUser,
  storesList,
} from "../../redux";
import { GoogleMapsAPI } from "../../client-config";
class AddTradeZone extends Component<
  AddTradeZoneProps,
  {
    kmlFile: any;
    kml: any;
    lat: any;
    lng: any;
    newlat: any;
    newlng: any;
    [x: number]: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      kmlFile: null,
      kml: "",
      lat: 0,
      lng: 0,
      newlat: 0,
      newlng: 0,
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    document.title = "SimplexYum | TradeZones";
    this.props.citiesList();
  }
  isZoneReady = () => {
    const { tradezonename, city } = this.props;
    let { kml, kmlFile } = this.state;
    return (
      tradezonename !== "" && city !== "" && kml !== "" && kmlFile !== null
    );
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  fileSelectedHandler = (e: any) => {
    this.setState({ kmlFile: e.target.files });
  };
  handleSaveBtnClick = () => {
    let { tradezonename, city } = this.props;
    let { kml, kmlFile, lat, lng } = this.state;
    const data: any = new FormData();
    if (this.state.kmlFile) {
      data.append("file", kmlFile[0]);
    }
    data.append("delivery_zone_name", tradezonename);
    data.append("kml_shape", kml);
    data.append("centerlat", lat);
    data.append("centerlng", lng);
    data.append("city_id", city);
    data.append("is_active", 1);
    this.props.addTradeZone(data);
  };
  handleMapData = (obj: any) => {
    //to Get the values from the map component and set in state
    this.setState({
      lat: obj.lat,
      lng: obj.lng,
    });
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let { cities } = this.props;
    return (
      <div className="page">
        <CheckChanges path="/add-brand" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Trade Zones Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/tradezone" className="text-primary">
                    Trade Zone
                  </Link>
                </li>
                <li className="breadcrumb-item active">Add Trade Zones</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Trade Zone name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="tradezonename"
                                type="text"
                                name="tradezonename"
                                required
                                data-msg="Please enter TradeZoneName"
                                className="input-material"
                                onChange={this.props.handleZoneInput}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                City <span className="text-danger">*</span>
                              </label>
                              <select
                                name="city"
                                className="form-control mt-2"
                                required
                                data-msg="Please Select City"
                                onChange={this.props.handleZoneInput}
                              >
                                <option value="">Select City</option>
                                {cities &&
                                  cities.map((city, index) => (
                                    <option key={index} value={city.id}>
                                      {city.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div style={{ width: "100%" }}>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label htmlFor="">Latitude</label>
                                <input
                                  type="text"
                                  name="newlat"
                                  className="form-control"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label htmlFor="">Longitude</label>
                                <input
                                  type="text"
                                  name="newlng"
                                  className="form-control"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <Map
                            data=""
                            center={{
                              lat: this.state.newlat,
                              lng: this.state.newlng,
                            }}
                            kml={this.state.kml}
                            onSetData={this.handleMapData}
                            google={GoogleMapsAPI}
                            height="400px"
                            zoom={15}
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="form-control-label mb-3">
                                KML File <span className="text-danger">*</span>
                              </label>
                              <input
                                id="files"
                                type="file"
                                name="files"
                                className="form-control-file"
                                onChange={this.fileSelectedHandler}
                              />
                            </div>
                          </div>
                          <div className="col-md-8 col-12">
                            <div className="form-group">
                              <label className="form-control-label ">
                                KML Link <span className="text-danger">*</span>
                              </label>
                              <input
                                id="kml"
                                type="text"
                                name="kml"
                                defaultValue={this.state.kml}
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isZoneReady()}
                            onClick={this.handleSaveBtnClick}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    tradezonename: state.tradezone.tradezonename,
    city: state.tradezone.city,
    cities: state.store.cities,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    handleZoneInput: (event: any) => {
      dispatch(handleZoneInput(event));
    },
    logoutUser: () => {
      dispatch(logoutUser());
    },
    citiesList: () => {
      dispatch(citiesList());
    },
    addTradeZone: (data: any) => {
      dispatch(addTradeZone(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTradeZone);
