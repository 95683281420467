import React, { Component } from "react";
import { EditCouponProps, EditCouponState } from "../../interfaces/coupon";
import { connect } from "react-redux";
import {
  editCoupon,
  getCoupon,
  menusList,
  groupsList,
  logoutUser,
  itemsListForMultiSelect,
} from "../../redux";
import { Link, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import moment from "moment";
import Select from "react-select";
class EditCoupon extends Component<EditCouponProps, EditCouponState> {
  constructor(props: any) {
    super(props);
    this.state = {
      couponname: "",
      coupondesc: "",
      couponCode: "",
      couponvalue: "",
      expiryDate: "",
      percent: "",
      mode: "",
      // channel: "",
      type: "",
      discountType: "",
      limit: "",
      type_id: "",
      multiJson: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCouponType = this.handleCouponType.bind(this);
    this.handleDiscountType = this.handleDiscountType.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getCoupon(id);
    this.props.itemsListForMultiSelect();
    this.props.menusList();
    this.props.groupsList();
    document.title = "SimpleXSync | Coupons";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any, nextState: any) {
    this.setState({
      couponname: nextProps.couponData.coupon_name,
      coupondesc: nextProps.couponData.coupon_description,
      couponCode: nextProps.couponData.coupon_code,
      expiryDate: moment(nextProps.couponData.expire_date)
        .local()
        .format("YYYY-MM-DDTHH:mm"),
      type: nextProps.couponData.type,
      type_id: nextProps.couponData.type_id,
      limit: nextProps.couponData.limit,
      mode: nextProps.couponData.mode,
      multiJson:
        nextProps.couponData.items_json &&
        JSON.parse(nextProps.couponData.items_json),
      discountType:
        nextProps && nextProps.couponData.coupon_value === 0
          ? "percentage"
          : "value",
    });
    if (nextProps.couponData.coupon_value > 0) {
      this.setState({ couponvalue: nextProps.couponData.coupon_value });
    } else if (
      nextProps.couponData.percent &&
      nextProps.couponData.percent > 0
    ) {
      this.setState({ percent: nextProps.couponData.percent });
    }
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleCouponType(event: { target: { name: any; value: any } }) {
    this.setState({
      type_id: "",
      [event.target.name]: event.target.value,
    });
  }
  handleDiscountType(event: { target: { name: any; value: any } }) {
    this.setState({
      percent: "",
      couponvalue: "",
      [event.target.name]: event.target.value,
    });
  }
  handleItemsInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ multiJson: e });
    } else {
      this.setState({ multiJson: [] });
    }
  };
  isCouponReady = () => {
    const {
      couponname,
      couponvalue,
      expiryDate,
      multiJson,
      mode,
      type,
      discountType,
      limit,
      type_id,
      percent,
    } = this.state;
    if (type == "menu" || type == "group") {
      return (
        couponname !== "" &&
        expiryDate !== "" &&
        mode !== "" &&
        type !== "" &&
        discountType !== "" &&
        limit !== "" &&
        type_id !== "" &&
        (percent !== "" || couponvalue !== "")
      );
    } else if (type == "item") {
      return (
        couponname !== "" &&
        expiryDate !== "" &&
        mode !== "" &&
        type !== "" &&
        discountType !== "" &&
        limit !== "" &&
        multiJson &&
        multiJson.length > 0 &&
        (percent !== "" || couponvalue !== "")
      );
    }
  };
  blockInvalidChar = (e: any) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  handleSubmit = (event: any) => {
    let {
      couponname,
      couponvalue,
      coupondesc,
      couponCode,
      multiJson,
      expiryDate,
      mode,
      type,
      limit,
      type_id,
      percent,
    } = this.state;
    let id = this.props.match.params.id;
    let data: any = {
      coupon_name: couponname,
      coupon_description: coupondesc,
      coupon_value: couponvalue,
      expire_date: expiryDate,
      mode: mode,
      type: type,
      limit: limit,
      percent: percent,
    };
    if (type == "menu" || type == "group") {
      data.type_id = type_id;
      data.items_json = null;
    } else if (type == "item") {
      data.items_json = JSON.stringify(multiJson);
    }
    this.props.editCoupon(id, data, couponCode);
    event.preventDefault();
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isUpdated, message, couponData, items, menus, groups } = this.props;
    const { expiryDate } = this.state;

    if (isUpdated) {
      msg = message;
      messcolor = "green";
    } else {
      msg = message;
      messcolor = "red";
    }
    return (
      <div className="page">
        <CheckChanges path="/edit-coupon" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Compaigns Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/marketing/0" className="text-primary">
                    Compaigns
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Coupon</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <form method="post" className="form-validate">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Coupon name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="couponname"
                                  type="text"
                                  defaultValue={couponData.coupon_name}
                                  name="couponname"
                                  required
                                  data-msg="Please enter Coupon Name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Description
                                </label>
                                <input
                                  id="coupondesc"
                                  type="text"
                                  name="coupondesc"
                                  defaultValue={couponData.coupon_description}
                                  required
                                  data-msg="Please enter Description"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Coupon Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="type"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Type"
                                  onChange={this.handleInputChange}
                                >
                                  <option
                                    {...(couponData.type === "menu" && {
                                      selected: true,
                                    })}
                                    value="menu"
                                  >
                                    Menu
                                  </option>
                                  <option
                                    {...(couponData.type === "group" && {
                                      selected: true,
                                    })}
                                    value="group"
                                  >
                                    Group
                                  </option>
                                  <option
                                    {...(couponData.type === "item" && {
                                      selected: true,
                                    })}
                                    value="item"
                                  >
                                    Item
                                  </option>
                                </select>
                              </div>
                            </div>

                            {this.state.type === "menu" && (
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Select Menu{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    name="type_id"
                                    className="form-control text-capitalize mt-2"
                                    required
                                    data-msg="Please select Group"
                                    onChange={this.handleInputChange}
                                  >
                                    <option value="">Select Menu</option>
                                    {menus &&
                                      menus.map((menu: any, index: any) => (
                                        <option
                                          key={index}
                                          value={menu.menu_id}
                                          {...(couponData.type_id ===
                                            menu.menu_id && { selected: true })}
                                        >
                                          {menu.menu_name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            )}

                            {this.state.type === "group" && (
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Select Group{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    name="type_id"
                                    className="form-control text-capitalize mt-2"
                                    required
                                    data-msg="Please select Group"
                                    onChange={this.handleInputChange}
                                  >
                                    <option value="">Select Group</option>
                                    {groups &&
                                      groups.map((group: any, index: any) => (
                                        <option
                                          key={index}
                                          value={group.group_id}
                                          {...(couponData.type_id ===
                                            group.group_id && {
                                            selected: true,
                                          })}
                                        >
                                          {group.group_name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            )}
                            {this.state.type === "item" && (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Select Items{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    isMulti
                                    name="items"
                                    options={items}
                                    defaultValue={
                                      couponData &&
                                      couponData.items_json &&
                                      JSON.parse(couponData.items_json)
                                    }
                                    className="text-capitalize basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    onChange={(e, i) =>
                                      this.handleItemsInputChange(e, i)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {/* <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Ordering Channel <span className="text-danger">*</span></label>
                                                                <select name="channel" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                    <option {...couponData.channel == 'delivery' && { selected: true }} value='delivery'>Delivery</option>
                                                                    <option {...couponData.channel == 'pickup' && { selected: true }} value='pickup' >Pick Up</option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Ordering Mode{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="mode"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Type"
                                  onChange={this.handleInputChange}
                                >
                                  <option
                                    {...(couponData.mode == "all" && {
                                      selected: true,
                                    })}
                                    value="all"
                                  >
                                    All
                                  </option>
                                  <option
                                    {...(couponData.mode == "online" && {
                                      selected: true,
                                    })}
                                    value="online"
                                  >
                                    Online
                                  </option>
                                  <option
                                    {...(couponData.mode == "callcenter" && {
                                      selected: true,
                                    })}
                                    value="callcenter"
                                  >
                                    Call Center
                                  </option>
                                  <option
                                    {...(couponData.mode == "mobile" && {
                                      selected: true,
                                    })}
                                    value="mobile"
                                  >
                                    Mobile
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Discount Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="discountType"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Type"
                                  onChange={this.handleDiscountType}
                                >
                                  <option
                                    {...(this.state.discountType ===
                                      "value" && { selected: true })}
                                    value="value"
                                  >
                                    Value
                                  </option>
                                  <option
                                    {...(this.state.discountType ===
                                      "percentage" && { selected: true })}
                                    value="percentage"
                                  >
                                    Percentage
                                  </option>
                                </select>
                              </div>
                            </div>

                            {this.state.discountType === "percentage" && (
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Coupon Percentage{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="percent"
                                    defaultValue={couponData.percent}
                                    min="1"
                                    onKeyDown={this.blockInvalidChar}
                                    type="number"
                                    name="percent"
                                    required
                                    data-msg="Please enter Coupon Percentage"
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            )}
                            {this.state.discountType === "value" && (
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Coupon value{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="couponvalue"
                                    defaultValue={couponData.coupon_value}
                                    min="1"
                                    onKeyDown={this.blockInvalidChar}
                                    type="number"
                                    name="couponvalue"
                                    required
                                    data-msg="Please enter Coupon Value"
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Limit <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="limit"
                                  type="number"
                                  name="limit"
                                  min="1"
                                  defaultValue={couponData.limit}
                                  required
                                  data-msg="Please enter Coupon Value"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Valid till{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="expiryDate"
                                  type="datetime-local"
                                  name="expiryDate"
                                  value={expiryDate}
                                  required
                                  data-msg="Please enter Expire Date"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="row">
                                                        <div className="col py-4">
                                                            <div className="i-checks">
                                                                <input id="checkboxCustom1" type="checkbox" name="status" {...(couponData.is_archive === 0 && { defaultChecked: true }) || (couponData.is_archive === 1 && { defaultChecked: false })} onChange={this.handleChangeChk} className="checkbox-template" />
                                                                <label htmlFor="checkboxCustom1">Active</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                          <div className="form-group float-right">
                            <button
                              onClick={this.handleSubmit}
                              disabled={!this.isCouponReady()}
                              className="btn btn-primary"
                            >
                              Update Coupon
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    couponData: state.coupon.couponData,
    brands: state.store.brands,
    menus: state.menu.menus,
    groups: state.menu.groups,
    items: state.menu.allActiveItems,
    message: state.coupon.message,
    isUpdated: state.coupon.isUpdated,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editCoupon: function (id: any, data: any, couponCode: any) {
      dispatch(editCoupon(id, data, couponCode));
    },
    getCoupon: function (id: number) {
      dispatch(getCoupon(id));
    },
    menusList: function () {
      dispatch(menusList());
    },
    groupsList: function () {
      dispatch(groupsList());
    },
    itemsListForMultiSelect: function () {
      dispatch(itemsListForMultiSelect());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCoupon);
