import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  EditUserGroupProps,
  EditUserGroupState,
} from "../../../interfaces/settings";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import {
  logoutUser,
  editUserGroup,
  storesListForMultiSelect,
  brandsList,
} from "../../../redux";
import Select from "react-select";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import CheckChanges from "../../../components/confirmOnLeave";
import { getUserGroup } from "../../../redux/actions/settingsAction";
import { storesListForMultiSelectByBrand } from "../../../redux/actions/menuAction";
class EditUserGroup extends Component<EditUserGroupProps, EditUserGroupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      name: "",
      description: "",
      storesJson: [],
      brand_id: "",
      brand_name: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.brandsList();
    this.props.getUserGroup(id);
    // this.props.storesList();
    document.title = "SimpleXSync | User Groups";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    console.log(
      "nextProps.userGroupData.brand_id",
      nextProps.userGroupData.brand_id,
    );
    let brand = nextProps.brands.filter((data: any) => {
      console.log("jjj", data.brand_id);
      if (nextProps.userGroupData.brand_id) {
        return data.brand_id == nextProps.userGroupData.brand_id.brand_id;
      }
    });
    console.log("bbb", brand);
    this.setState({
      name: nextProps.userGroupData.name,
      description: nextProps.userGroupData.description,
      storesJson: nextProps.userGroupData.stores_json
        ? JSON.parse(nextProps.userGroupData.stores_json)
        : this.state.storesJson,
      brand_id: brand[0] && brand[0].brand_id,
      brand_name: brand[0] && brand[0].brand_name,
    });
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  isUserReady = () => {
    let { name, storesJson } = this.state;
    return name !== "" && storesJson.length > 0;
  };
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ storesJson: e });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  handleSubmit = (event: any) => {
    let { name, description, storesJson } = this.state;
    let id = this.props.match.params.id;
    let data: any = {
      name: name,
      description: description,
      storesJson: JSON.stringify(storesJson),
      is_active: 1,
    };
    this.props.editUserGroup(id, data);
  };

  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value, brand_name: e.label });
    this.props.storesList(e.value);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    const { stores, userGroupData } = this.props;
    const { storesJson } = this.state;
    return (
      <div className="page">
        <CheckChanges path="/edit-user" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header pb-1">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>User Groups Management</h4>
                </div>
              </div>
            </header>
            {/*  Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/usergroups" className="text-primary">
                    User Groups
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Group</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label ">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                id="name"
                                type="text"
                                name="name"
                                defaultValue={userGroupData.name}
                                required
                                data-msg="Please enter name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-lable">
                                Description
                              </label>
                              <input
                                id="description"
                                type="text"
                                name="description"
                                defaultValue={userGroupData.description}
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Select Brand{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                name="brands"
                                options={selectedBrands}
                                value={{
                                  label: this.state.brand_name,
                                  value: this.state.brand_id,
                                }}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleBrandsInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Select Store{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                isMulti
                                value={storesJson}
                                name="stores"
                                options={stores}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleStoresInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group float-right">
                          {userGroupData.default_group == 0 && (
                            <button
                              onClick={this.handleSubmit}
                              disabled={!this.isUserReady()}
                              className="btn btn-primary"
                            >
                              Update Group
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    userGroupData: state.setting.userGroupData,
    stores: state.menu.storesoptions,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editUserGroup: function (id: any, data: any) {
      dispatch(editUserGroup(id, data));
    },
    getUserGroup: function (id: number) {
      dispatch(getUserGroup(id));
    },
    storesList: function (brand_id: any) {
      dispatch(storesListForMultiSelectByBrand(brand_id));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUserGroup);
