import {
  CUSTOMERS_LIST,
  ORDER_HISTORY,
  FRANCHISE_LIST,
  SET_PAGE_COUNT,
  SET_PERPAGE,
  FAV_MENU_ITEM,
  GET_CUSTOMER,
  EDIT_CUSTOMER,
  DUP_DATA,
  FEEDBACK_LIST,
  NEWSLETTER_EMAILS,
  CUSTOMER_COMPLAINTS,
  UPDATE_LOADER,
} from "../actions/customerType";
const initialState: any = {
  data: [] || "",
  ordersdata: [] || "",
  favItemData: [] || "",
  feedbackData: [] || "",
  franchiseApplications: [] || "",
  blockedCustomer: [] || "",
  newsletterEmails: [] || "",
  customerData: {},
  message: "",
  dupData: [] || "",
  isUpdated: "",
  currentPage: 0,
  pageCount: 0,
  numOfRows: 0,
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CUSTOMERS_LIST:
      return {
        ...state,
        data: action.payload,
        currentPage: action.currentPage,
        pageCount: action.pageCount,
        numOfRows: action.numOfRows,
        blockedCustomer: action.blockedCustomer,
      };
    case DUP_DATA:
      return {
        ...state,
        dupData: action.payload,
        //isLoad: action.isLoad
      };
    case FEEDBACK_LIST:
      return {
        ...state,
        feedbackData: action.payload,
      };
    case FRANCHISE_LIST:
      return {
        ...state,
        franchiseApplications: action.payload,
      };
    case ORDER_HISTORY:
      return {
        ...state,
        ordersdata: action.payload,
      };
    case FAV_MENU_ITEM:
      return {
        ...state,
        favItemData: action.payload,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customerData: action.payload,
      };
    case EDIT_CUSTOMER:
      return {
        ...state,
        message: action.payload,
        isUpdated: action.isUpdated,
      };
    case NEWSLETTER_EMAILS:
      return {
        ...state,
        newsletterEmails: action.payload,
      };
    case CUSTOMER_COMPLAINTS:
      return {
        ...state,
        complaintData: action.payload,
      };
    case UPDATE_LOADER:
      return {
        ...state,
        isLoad: action.isLoad,
      };
    default:
      return state;
  }
};
export default userReducer;
