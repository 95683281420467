import {
  NOTIFICATIONS_LIST,
  GET_NOTIFICATION,
} from "../actions/notificationType";
const initialState: any = {
  data: [] || "",
  notificationData: {},
};

const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case NOTIFICATIONS_LIST:
      return {
        ...state,
        data: action.payload,
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        notificationData: action.payload,
      };
    default:
      return state;
  }
};
export default notificationReducer;
