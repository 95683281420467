import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import {
  logoutUser,
  getCity,
  editCity,
  handleBrandInput,
  countryList,
} from "../../redux";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import { EditCityZoneProps } from "../../interfaces/city";

class EditCity extends Component<
  EditCityZoneProps,
  { countryId: any; name: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      countryId: null,
      name: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getCity(id, this.setCityState);
    document.title = "SimpleXSync | Cities";
    this.props.countryList();
  }

  // UNSAFE_componentWillReceiveProps(nextProps:any) {
  //     console.log('next',nextProps);
  //     if(nextProps && nextProps.cityObj) {
  //         console.log('inside 1st',this.props.cityObj.name);
  //         if(nextProps.cityObj.name == this.props.cityObj.name) {
  //             console.log('inside');
  //             this.setState({name:nextProps.cityObj.name})
  //         }
  //     }
  //     if(nextProps.cityObj != this.props.cityObj) {
  //         this.setState({name:nextProps.cityObj.name,countryId:nextProps.cityObj.country_id.country_id})
  //     }
  //     return true
  // }

  setCityState = (value: any, Id2: any, n2: any) => {
    this.setState({ name: value, countryId: Id2 });
    console.log("state id2 n2", this.state.countryId);
  };

  isCityReady = () => {
    if (this.state.countryId == "") {
      return false;
    }
    return this.state.name !== "";
  };
  handleSubmit = () => {
    let { countryId, name } = this.state;
    let id = this.props.match.params.id;
    let data = {
      name,
      country_id: countryId,
    };
    console.log("handle submit", data);
    this.props.editCity(id, data);
  };

  handleInputChange(event: { target: { name: any; value: any } }) {
    console.log("[event.target.name]", event.target.value);
    this.setState({ countryId: Number(event.target.value) });
  }
  blockInvalidChar = (e: any) => {
    var regex = new RegExp("[a-zA-Z]");
    let specialkey: any = e.keyCode;
    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault();
          }
        }
      } else {
        (!regex.test(key) || specialkey == 32) && e.preventDefault();
      }
    }
  };
  handleCityInput = (e: any) => {
    this.setState({ name: e.target.value });
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isUpdated, message, countries } = this.props;
    if (isUpdated) {
      msg = message;
      messcolor = "green";
    } else {
      msg = message;
      messcolor = "red";
    }
    console.log("countries", this.props.countries);
    let id = this.props.match.params.id;
    return (
      <div className="page">
        <CheckChanges path="/edit-brand" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">City Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/cities" className="text-primary">
                    City
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit City</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Country <span className="text-danger">*</span>
                              </label>
                              <select
                                name="country"
                                value={this.state.countryId}
                                className="form-control mt-2"
                                required
                                data-msg="Please enter Country"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Country</option>
                                {countries &&
                                  countries.map((country: any, index: any) => (
                                    <option
                                      key={index}
                                      defaultValue={this.state.countryId}
                                      value={country.country_id}
                                    >
                                      {country.country_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                City name <span className="text-danger">*</span>
                              </label>
                              <input
                                id="cityname"
                                type="text"
                                name="cityname"
                                onKeyDown={this.blockInvalidChar}
                                value={this.state.name}
                                required
                                data-msg="Please enter city Name"
                                className="input-material"
                                onChange={this.handleCityInput}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                          <button
                            onClick={this.handleSubmit}
                            disabled={!this.isCityReady()}
                            className="btn btn-primary"
                          >
                            Update City
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    cityname: state.city.cityname,
    countryid: state.city.countryid,
    countries: state.store.countries,
    message: state.city.message,
    isUpdated: state.city.isUpdated,
    cityObj: state.city.cityObj,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: () => {
      dispatch(logoutUser());
    },
    handleBrandInput: (event: any) => {
      dispatch(handleBrandInput(event));
    },
    editCity: (id: any, data: any) => {
      dispatch(editCity(id, data));
    },
    getCity: (id: number, setCityState: any) => {
      dispatch(getCity(id, setCityState));
    },
    countryList: () => {
      dispatch(countryList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditCity);
