import React, { Component } from "react";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
class Sidebar extends Component<{}, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  render() {
    let page = window.location.pathname.split("/");
    let name = sessionStorage.name;
    let tokendata: any = jwt.decode(sessionStorage.token);
    let roleId = tokendata.role_id;
    let features: any = {};
    if (sessionStorage.token) {
      features = tokendata.features
        ? JSON.parse(tokendata.features)
        : tokendata.features;
    }
    let allowedRoutes = (
      // {/* <!-- Sidebar Navidation Menus--> */}
      <>
        <li className={page[1] === "dashboard" ? "active" : ""}>
          <Link to="/dashboard">
            {" "}
            <i className="fa fa-home"></i>Dashboard
          </Link>
        </li>
        <li
          className={
            page[1] === "stores" ||
            page[1] === "add-store" ||
            page[1] === "edit-store"
              ? "active"
              : ""
          }
        >
          <Link to="/stores">
            {" "}
            <i className="fa fa-shopping-basket"></i>Stores
          </Link>
        </li>
        {/* <li className={page[1] === 'fullfilment' || page[1] === 'add-store' || page[1] === 'edit-store' ? 'active' : ''}><a href='#'><i className="fa fa-shopping-basket"></i>Fulfillment</a></li> */}
        {/* <li><a href="#deliveryAreasDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-bars"></i>Areas</a>
                    <ul id="deliveryAreasDropDown" className={page[1] === 'tradezone' || page[1] === 'add-tradezone' || page[1] === 'edit-tradezone' ||
                        page[1] === 'tradearea' || page[1] === 'add-tradearea' || page[1] === 'edit-tradearea'
                        ? '' : 'collapse list-unstyled'}>
                        <li className={page[1] === 'tradezone' || page[1] === 'add-tradezone' || page[1] === 'edit-tradezone' ? 'active' : ''}><Link to="/tradezone">Trade Zones</Link></li>
                        <li className={page[1] === 'tradearea' || page[1] === 'add-tradearea' || page[1] === 'edit-tradearea' ? 'active' : ''}><Link to="/tradearea">Trade Areas</Link></li>
                    </ul>
                </li> */}
        <li className={page[1] === "branchmenu" ? "active" : ""}>
          <Link to="/branchmenu">
            {" "}
            <i className="fa fa-bars"></i>Branch Menu
          </Link>
        </li>
        <li>
          <a href="#menuDropDown" aria-expanded="false" data-toggle="collapse">
            {" "}
            <i className="fa fa-bars"></i>Menu
          </a>
          <ul
            id="menuDropDown"
            className={
              page[1] === "menus" ||
              page[1] === "add-menu" ||
              page[1] === "edit-menu" ||
              page[1] === "groups" ||
              page[1] === "add-group" ||
              page[1] === "edit-group" ||
              page[1] === "sub-groups" ||
              page[1] === "add-sub-group" ||
              page[1] === "edit-sub-group" ||
              page[1] === "menu-items" ||
              page[1] === "add-item" ||
              page[1] === "edit-item" ||
              page[1] === "combos" ||
              page[1] === "add-combo" ||
              page[1] === "edit-combo" ||
              page[1] === "mod-groups" ||
              page[1] === "add-modgroup" ||
              page[1] === "edit-modgroup" ||
              page[1] === "modifiers" ||
              page[1] === "add-modifier" ||
              page[1] === "edit-modifier"
                ? ""
                : "collapse list-unstyled"
            }
          >
            <li
              className={
                page[1] === "menus" ||
                page[1] === "add-menu" ||
                page[1] === "edit-menu"
                  ? "active"
                  : ""
              }
            >
              <Link to="/menus">Menus</Link>
            </li>
            <li className={page[1] === "aggregator-menu-logs" ? "active" : ""}>
              <Link to="/aggregator-menu-logs">Aggregator Menu Logs</Link>
            </li>
            <li
              className={
                page[1] === "groups" ||
                page[1] === "add-group" ||
                page[1] === "edit-group"
                  ? "active"
                  : ""
              }
            >
              <Link to="/groups">Groups</Link>
            </li>
            <li
              className={
                page[1] === "sub-groups" ||
                page[1] === "add-sub-group" ||
                page[1] === "edit-sub-group"
                  ? "active"
                  : ""
              }
            >
              <Link to="/sub-groups">Sub Groups</Link>
            </li>
            <li
              className={
                page[1] === "menu-items" ||
                page[1] === "add-item" ||
                page[1] === "edit-item"
                  ? "active"
                  : ""
              }
            >
              <Link to="/menu-items">Items</Link>
            </li>
            <li
              className={
                page[1] === "combos" ||
                page[1] === "add-combo" ||
                page[1] === "edit-combo"
                  ? "active"
                  : ""
              }
            >
              <Link to="/combos">Combo</Link>
            </li>
            <li
              className={
                page[1] === "mod-groups" ||
                page[1] === "add-modgroup" ||
                page[1] === "edit-modgroup"
                  ? "active"
                  : ""
              }
            >
              <Link to="/mod-groups">Modifier Groups</Link>
            </li>
            <li
              className={
                page[1] === "modifiers" ||
                page[1] === "add-modifier" ||
                page[1] === "edit-modifier"
                  ? "active"
                  : ""
              }
            >
              <Link to="/modifiers">Modifiers</Link>
            </li>
            <li className={page[1] === "syncLogs" ? "active" : ""}>
              <Link to="/syncLogs">Sync Logs</Link>
            </li>
            {/* <li className={page[1] === 'ingredients' ? 'active' : ''}><Link to="/ingredients">Ingredients</Link></li>
                    <li className={page[1] === 'recipes' ? 'active' : ''}><Link to="/recipes">Recipe</Link></li>
                    <li className={page[1] === '' ? 'active' : ''}><Link to="/users">SubGroup</Link></li>
                    <li className={page[1] === '' ? 'active' : ''}><Link to="/users">Nested Modifiers</Link></li> */}
          </ul>
        </li>

        {/* <li><a href="#"> <i className="fa fa-film"></i>Loyalty</a>
                </li> */}
        <li
          className={
            page[1] === "brands" ||
            page[1] === "add-brand" ||
            page[1] === "edit-brand"
              ? "active"
              : ""
          }
        >
          <Link to="/brands">
            {" "}
            <i className="fa fa-font-awesome"></i>Brands
          </Link>
        </li>
        <li>
          <a
            href="#customerDropDown"
            aria-expanded="false"
            data-toggle="collapse"
          >
            {" "}
            <i className="fa fa-users"></i>Customers
          </a>
          <ul
            id="customerDropDown"
            className={
              page[1] === "customers" ||
              page[1] === "feedbacks" ||
              page[1] === "franchise" ||
              page[1] === "complaints" ||
              page[1] === "newsletter" ||
              page[1] === "guests"
                ? ""
                : "collapse list-unstyled"
            }
          >
            <li className={page[1] === "customers" ? "active" : ""}>
              <Link to="/customers">Registered Customers</Link>
            </li>
            {/* <li className={page[1] === 'block-customers' ? 'active' : ''}><Link to="/block-customers">Blocked Customers</Link></li> */}
            {/* <li className={page[1] === 'guests' ? 'active' : ''}><Link to="/guests">Guest Customers</Link></li> */}
            {/* <li className={page[1] === 'feedbacks' ? 'active' : ''}><Link to="/feedbacks">Customers Review</Link></li> */}
            {/* <li className={page[1] === 'complaints' ? 'active' : ''}><Link to="/complaints">Customers Feedback</Link></li> */}
            {/* <li className={page[1] === 'franchise' ? 'active' : ''}><Link to="/franchise">Franchise Applications</Link></li> */}
            {/* <li className={page[1] === 'newsletter' ? 'active' : ''}><Link to="/newsletter">NewsLetter Emails</Link></li> */}
          </ul>
        </li>
        {/* <li className={page[1] === 'marketing' || page[1] === 'add-coupon' || page[1] === 'edit-coupon'
                    || page[1] === 'add-promo' || page[1] === 'edit-promo'
                    || page[1] === 'add-promo' || page[1] === 'edit-promo'
                    || page[1] === 'add-discount' || page[1] === 'edit-discount'
                    ? 'active' : ''}><Link to="/marketing/0"> <i className="fa fa-tag"></i>Marketing</Link></li> */}
        {/* <li className={page[1] === 'coupons' ? 'active' : ''}><Link to="/coupons"> <i className="fa fa-tag"></i>Coupons</Link></li> */}
        {/* <li className={page[1] === 'promos' ? 'active' : ''}><Link to="/promos"> <i className="fa fa-gift"></i>Promos</Link></li> */}
        <li>
          <a
            href="#ordersDropDown"
            aria-expanded="false"
            data-toggle="collapse"
          >
            {" "}
            <i className="fa fa-shopping-cart"></i>Orders
          </a>
          <ul
            id="ordersDropDown"
            className={
              page[1] === "orders" || page[1] === "outbound_contacts"
                ? ""
                : "collapse list-unstyled"
            }
          >
            <li className={page[1] === "orders" ? "active" : ""}>
              <Link to="/orders">Orders </Link>
            </li>
            <li className={page[1] === "outbound_contacts" ? "active" : ""}>
              <Link to="/outbound_contacts">OutBound Contacts</Link>
            </li>
          </ul>
        </li>
        <li className={page[1] === "payments" ? "active" : ""}>
          <Link to="/payments">
            {" "}
            <i className="fa fa-credit-card"></i>Payments
          </Link>
        </li>
        {/* <li className={page[1] === 'bulk' ? 'active' : ''}><Link to="/bulk"> <i className="fa fa-upload"></i>Bulk Upload</Link></li> */}
        {/* <li className={page[1] === 'addressBulk' ? 'active' : ''}><Link to="/addressBulk"> <i className="fa fa-upload"></i>Address Upload</Link></li> */}
        {/* <li className={page[1] === 'analytics' ? 'active' : ''}><Link to="/analytics"> <i className="fa fa-file-pdf-o"></i>Analytics</Link></li> */}
        <li>
          <a
            href="#securityDropDown"
            aria-expanded="false"
            data-toggle="collapse"
          >
            {" "}
            <i className="fa fa-lock"></i>Security{" "}
          </a>
          <ul
            id="securityDropDown"
            className={
              page[1] === "users" ||
              page[1] === "add-user" ||
              page[1] === "edit-user" ||
              page[1] === "usergroups" ||
              page[1] === "add-usergroup" ||
              page[1] === "edit-usergroup" ||
              page[1] === "roles" ||
              page[1] === "add-role" ||
              page[1] === "edit-role" ||
              page[1] === "access-manage"
                ? ""
                : "collapse list-unstyled"
            }
          >
            <li
              className={
                page[1] === "users" ||
                page[1] === "add-user" ||
                page[1] === "edit-user"
                  ? "active"
                  : ""
              }
            >
              <Link to="/users">
                <i className="fa fa-user"></i>Users
              </Link>
            </li>
            <li
              className={
                page[1] === "usergroups" ||
                page[1] === "add-usergroup" ||
                page[1] === "edit-usergroup"
                  ? "active"
                  : ""
              }
            >
              <Link to="/usergroups">
                <i className="fa fa-users"></i>Groups
              </Link>
            </li>
            <li
              className={
                page[1] === "roles" ||
                page[1] === "add-role" ||
                page[1] === "edit-role"
                  ? "active"
                  : ""
              }
            >
              <Link to="/roles">
                <i className="fa fa-user-plus"></i>Roles
              </Link>
            </li>
            <li className={page[1] === "access-manage" ? "active" : ""}>
              <Link to="/access-manage">
                {" "}
                <i className="fa fa-cog"></i>I A M
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#settingsDropDown"
            aria-expanded="false"
            data-toggle="collapse"
          >
            {" "}
            <i className="fa fa-cogs"></i>Settings{" "}
          </a>
          <ul
            id="settingsDropDown"
            className={
              page[1] === "settings" ||
              page[1] === "states" ||
              page[1] === "add-state" ||
              page[1] === "edit-state" ||
              page[1] === "drivers"
                ? ""
                : "collapse list-unstyled"
            }
          >
            <li className={page[1] === "settings" ? "active" : ""}>
              <Link to="/settings">
                {" "}
                <i className="fa fa-cog"></i>Settings
              </Link>
            </li>
            <li
              className={
                page[1] === "states" ||
                page[1] === "add-state" ||
                page[1] === "edit-state"
                  ? "active"
                  : ""
              }
            >
              <Link to="/states">
                {" "}
                <i className="fa fa-map-o"></i>States
              </Link>
            </li>
            <li className={page[1] === "drivers" ? "active" : ""}>
              <Link to="/drivers">
                <i className="fa fa-truck"></i>Drivers
              </Link>
            </li>
          </ul>
        </li>

        {roleId == 7 && (
          <li>
            <a
              href="#globalsettingsDropDown"
              aria-expanded="false"
              data-toggle="collapse"
            >
              {" "}
              <i className="fa fa-cogs"></i>Global Settings
            </a>
            <ul
              id="globalsettingsDropDown"
              className={
                page[1] === "cities" ||
                page[1] === "add-cities" ||
                page[1] === "edit-cities" ||
                page[1] === "reasons" ||
                page[1] === "add-reason" ||
                page[1] === "drivers" ||
                page[1] === "aggregators" ||
                page[1] === "add-aggregator" ||
                page[1] === "edit-aggregator"
                  ? ""
                  : "collapse list-unstyled"
              }
            >
              <li
                className={
                  page[1] === "cities" ||
                  page[1] === "add-cities" ||
                  page[1] === "edit-cities"
                    ? "active"
                    : ""
                }
              >
                <Link to="/cities">
                  {" "}
                  <i className="fa fa-font-awesome"></i>Cities
                </Link>
              </li>
              <li
                className={
                  page[1] === "reasons"
                    ? "active"
                    : "" || page[1] === "add-reason"
                      ? "active"
                      : ""
                }
              >
                <Link to="/reasons">
                  <i className="fa fa-file-o"></i>Reasons
                </Link>
              </li>
              <li
                className={
                  page[1] === "tradezone" ||
                  page[1] === "add-tradezone" ||
                  page[1] === "edit-tradezone"
                    ? "active"
                    : ""
                }
              >
                <Link to="/tradezone">
                  {" "}
                  <i className="fa fa-map-pin"></i>Trade Zones
                </Link>
              </li>
              <li
                className={
                  page[1] === "aggregators" ||
                  page[1] === "add-aggregator" ||
                  page[1] === "edit-aggregator"
                    ? "active"
                    : ""
                }
              >
                <Link to="/aggregators">
                  {" "}
                  <i className="fa fa-bars"></i>Aggregators
                </Link>
              </li>
            </ul>
          </li>
        )}
        {/* <li><Link to="#"> <i className="fa fa-question-circle"></i>Faq</Link></li>
            <li><Link to="#"> <i className="fa fa-support"></i>Support</Link></li> */}
      </>
    );
    return (
      // <!-- Side Navbar -->
      <nav className="side-navbar">
        {/* <!-- Sidebar Header--> */}
        <div className="sidebar-header d-flex align-items-center">
          <div
            className="avatar"
            style={{
              backgroundColor: "#20A5D6",
              borderRadius: 30,
              marginBottom: 7,
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
              alt="..."
              className="img-fluid rounded-circle"
            />
          </div>
          <div className="title">
            <h1 className="h4">{name}</h1>
          </div>
        </div>
        {/* <!-- Sidebar Navidation Menus--> */}
        <ul className="list-unstyled">
          {roleId == 1 || roleId == 7 ? (
            allowedRoutes
          ) : (
            <>
              {" "}
              {features &&
                features.feature1 &&
                features.feature1.permission == 1 && (
                  <li className={page[1] === "dashboard" ? "active" : ""}>
                    <Link to="/dashboard">
                      {" "}
                      <i className="fa fa-home"></i>Dashboard
                    </Link>
                  </li>
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <>
                    <li
                      className={
                        page[1] === "stores" ||
                        page[1] === "add-store" ||
                        page[1] === "edit-store"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/stores">
                        {" "}
                        <i className="fa fa-shopping-basket"></i>Stores
                      </Link>
                    </li>
                    {/* <li className={page[1] === 'tradezone' || page[1] === 'add-tradezone' || page[1] === 'edit-tradezone' ? 'active' : ''}><Link to="/tradezone"> <i className="fa fa-map-pin"></i>Trade Zones</Link></li> */}
                    {/* <li><a href="#deliveryAreasDropDown" aria-expanded="false" data-toggle="collapse"> <i className="fa fa-bars"></i>Areas</a>
                                    <ul id="deliveryAreasDropDown" className={page[1] === 'tradezone' || page[1] === 'add-tradezone' || page[1] === 'edit-tradezone' ||
                                        page[1] === 'tradearea' || page[1] === 'add-tradearea' || page[1] === 'edit-tradearea'
                                        ? '' : 'collapse list-unstyled'}>
                                        <li className={page[1] === 'tradezone' || page[1] === 'add-tradezone' || page[1] === 'edit-tradezone' ? 'active' : ''}><Link to="/tradezone">Trade Zones</Link></li>
                                        <li className={page[1] === 'tradearea' || page[1] === 'add-tradearea' || page[1] === 'edit-tradearea' ? 'active' : ''}><Link to="/tradearea">Trade Areas</Link></li>
                                    </ul>
                                </li> */}
                  </>
                )}
              {features &&
                features.feature3 &&
                features.feature3.permission == 1 && (
                  <li className={page[1] === "branchmenu" ? "active" : ""}>
                    <Link to="/branchmenu">
                      {" "}
                      <i className="fa fa-bars"></i>Branch Menu
                    </Link>
                  </li>
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <li>
                    <a
                      href="#menuDropDown"
                      aria-expanded="false"
                      data-toggle="collapse"
                    >
                      {" "}
                      <i className="fa fa-bars"></i>Menu
                    </a>
                    <ul
                      id="menuDropDown"
                      className={
                        page[1] === "menus" ||
                        page[1] === "add-menu" ||
                        page[1] === "edit-menu" ||
                        page[1] === "groups" ||
                        page[1] === "add-group" ||
                        page[1] === "edit-group" ||
                        page[1] === "sub-groups" ||
                        page[1] === "add-sub-group" ||
                        page[1] === "edit-sub-group" ||
                        page[1] === "menu-items" ||
                        page[1] === "add-item" ||
                        page[1] === "edit-item" ||
                        page[1] === "combos" ||
                        page[1] === "add-combo" ||
                        page[1] === "edit-combo" ||
                        page[1] === "mod-groups" ||
                        page[1] === "add-modgroup" ||
                        page[1] === "edit-modgroup" ||
                        page[1] === "modifiers" ||
                        page[1] === "add-modifier" ||
                        page[1] === "edit-modifier"
                          ? ""
                          : "collapse list-unstyled"
                      }
                    >
                      <li
                        className={
                          page[1] === "menus" ||
                          page[1] === "add-menu" ||
                          page[1] === "edit-menu"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/menus">Menu</Link>
                      </li>
                      <li
                        className={
                          page[1] === "aggregator-menu-logs" ? "active" : ""
                        }
                      >
                        <Link to="/aggregator-menu-logs">
                          Aggregator Menu Logs
                        </Link>
                      </li>
                      <li
                        className={
                          page[1] === "groups" ||
                          page[1] === "add-group" ||
                          page[1] === "edit-group"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/groups">Groups</Link>
                      </li>
                      <li
                        className={
                          page[1] === "sub-groups" ||
                          page[1] === "add-sub-group" ||
                          page[1] === "edit-sub-group"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/sub-groups">Sub Groups</Link>
                      </li>
                      <li
                        className={
                          page[1] === "menu-items" ||
                          page[1] === "add-item" ||
                          page[1] === "edit-item"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/menu-items">Items</Link>
                      </li>
                      <li
                        className={
                          page[1] === "combos" ||
                          page[1] === "add-combo" ||
                          page[1] === "edit-combo"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/combos">Combo</Link>
                      </li>
                      <li
                        className={
                          page[1] === "mod-groups" ||
                          page[1] === "add-modgroup" ||
                          page[1] === "edit-modgroup"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/mod-groups">Modifier Groups</Link>
                      </li>
                      <li
                        className={
                          page[1] === "modifiers" ||
                          page[1] === "add-modifier" ||
                          page[1] === "edit-modifier"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/modifiers">Modifiers</Link>
                      </li>
                      <li className={page[1] === "syncLogs" ? "active" : ""}>
                        <Link to="/syncLogs">Sync Logs</Link>
                      </li>

                      {/* <li className={page[1] === 'ingredients' ? 'active' : ''}><Link to="/ingredients">Ingredients</Link></li>
                            <li className={page[1] === 'recipes' ? 'active' : ''}><Link to="/recipes">Recipe</Link></li>
                            <li className={page[1] === '' ? 'active' : ''}><Link to="/users">SubGroup</Link></li>
                            <li className={page[1] === '' ? 'active' : ''}><Link to="/users">Nested Modifiers</Link></li> */}
                    </ul>
                  </li>
                )}
              {features &&
                features.feature5 &&
                features.feature5.permission == 1 && (
                  <li
                    className={
                      page[1] === "brands" ||
                      page[1] === "add-brand" ||
                      page[1] === "edit-brand"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/brands">
                      {" "}
                      <i className="fa fa-font-awesome"></i>Brands
                    </Link>
                  </li>
                )}
              {features &&
                features.feature6 &&
                features.feature6.permission == 1 && (
                  <li>
                    <a
                      href="#customerDropDown"
                      aria-expanded="false"
                      data-toggle="collapse"
                    >
                      {" "}
                      <i className="fa fa-users"></i>Customers
                    </a>
                    <ul
                      id="customerDropDown"
                      className={
                        page[1] === "customers" ||
                        page[1] === "feedbacks" ||
                        page[1] === "franchise" ||
                        page[1] === "complaints" ||
                        page[1] === "newsletter" ||
                        page[1] === "guests"
                          ? ""
                          : "collapse list-unstyled"
                      }
                    >
                      <li className={page[1] === "customers" ? "active" : ""}>
                        <Link to="/customers">Registered Customers</Link>
                      </li>
                      {/* <li className={page[1] === 'block-customers' ? 'active' : ''}><Link to="/block-customers">Blocked Customers</Link></li> */}
                      {/* <li className={page[1] === 'guests' ? 'active' : ''}><Link to="/guests">Guest Customers</Link></li> */}
                      {/* <li className={page[1] === 'feedbacks' ? 'active' : ''}><Link to="/feedbacks">Customers Review</Link></li> */}
                      {/* <li className={page[1] === 'complaints' ? 'active' : ''}><Link to="/complaints">Customers Feedback</Link></li> */}
                      {/* <li className={page[1] === 'franchise' ? 'active' : ''}><Link to="/franchise">Franchise Applications</Link></li> */}
                      {/* <li className={page[1] === 'newsletter' ? 'active' : ''}><Link to="/newsletter">NewsLetter Emails</Link></li> */}
                    </ul>
                  </li>
                )}
              {/* {(features && features.feature7 && features.feature7.permission == 1) && <li className={page[1] === 'marketing' || page[1] === 'add-coupon' || page[1] === 'edit-coupon'
                                || page[1] === 'add-promo' || page[1] === 'edit-promo'
                                || page[1] === 'add-promo' || page[1] === 'edit-promo'
                                || page[1] === 'add-discount' || page[1] === 'edit-discount'
                                ? 'active' : ''}><Link to="/marketing/0"> <i className="fa fa-tag"></i>Marketing</Link></li>
                                
                            } */}
              {features &&
                features.feature8 &&
                features.feature8.permission == 1 && (
                  <li>
                    <a
                      href="#ordersDropDown"
                      aria-expanded="false"
                      data-toggle="collapse"
                    >
                      {" "}
                      <i className="fa fa-shopping-cart"></i>Orders
                    </a>
                    <ul
                      id="ordersDropDown"
                      className={
                        page[1] === "orders" || page[1] === "outbound_contacts"
                          ? ""
                          : "collapse list-unstyled"
                      }
                    >
                      <li className={page[1] === "orders" ? "active" : ""}>
                        <Link to="/orders">Orders </Link>
                      </li>
                      <li
                        className={
                          page[1] === "outbound_contacts" ? "active" : ""
                        }
                      >
                        <Link to="/outbound_contacts">OutBound Contacts</Link>
                      </li>
                    </ul>
                  </li>
                )}
              {features &&
                features.feature9 &&
                features.feature9.permission == 1 && (
                  <li className={page[1] === "payments" ? "active" : ""}>
                    <Link to="/payments">
                      {" "}
                      <i className="fa fa-credit-card"></i>Payments
                    </Link>
                  </li>
                )}
              {/* {(features && features.feature10 && features.feature10.permission == 1) && <li className={page[1] === 'bulk' ? 'active' : ''}><Link to="/bulk"> <i className="fa fa-upload"></i>Bulk Upload</Link></li>} */}
              {/* {(features && features.feature11 && features.feature11.permission == 1) && <li className={page[1] === 'addressBulk' ? 'active' : ''}><Link to="/addressBulk"> <i className="fa fa-upload"></i>Address Upload</Link></li>} */}
              {/* {(features && features.feature12 && features.feature12.permission == 1) && <li className={page[1] === 'analytics' ? 'active' : ''}><Link to="/analytics"> <i className="fa fa-file-pdf-o"></i>Analytics</Link></li>} */}
              {features &&
                features.feature13 &&
                features.feature13.permission == 1 && (
                  <li>
                    <a
                      href="#settingsDropDown"
                      aria-expanded="false"
                      data-toggle="collapse"
                    >
                      {" "}
                      <i className="fa fa-cogs"></i>Settings{" "}
                    </a>
                    <ul
                      id="settingsDropDown"
                      className={
                        page[1] === "settings" ||
                        page[1] === "states" ||
                        page[1] === "add-state" ||
                        page[1] === "edit-state" ||
                        page[1] === "drivers" ||
                        page[1] === "roles" ||
                        page[1] === "add-role" ||
                        page[1] === "edit-role"
                          ? ""
                          : "collapse list-unstyled"
                      }
                    >
                      <li className={page[1] === "settings" ? "active" : ""}>
                        <Link to="/settings">
                          {" "}
                          <i className="fa fa-cog"></i>Settings
                        </Link>
                      </li>
                      <li
                        className={
                          page[1] === "states" ||
                          page[1] === "add-state" ||
                          page[1] === "edit-state"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/states">
                          {" "}
                          <i className="fa fa-map-o"></i>States
                        </Link>
                      </li>
                      <li className={page[1] === "drivers" ? "active" : ""}>
                        <Link to="/drivers">
                          <i className="fa fa-truck"></i>Drivers
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
            </>
          )}
        </ul>
      </nav>
    );
  }
}
export default Sidebar;
