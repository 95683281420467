import React from "react";
import Timer from "react-compound-timer";
export const ReactTimer = () => {
  return (
    <Timer initialTime={30000} direction="backward">
      {() => (
        <React.Fragment>
          <strong
            className="text-bold"
            style={{ color: " #20A5D6", fontSize: "2em" }}
          >
            {" "}
            00:
            <Timer.Seconds />
          </strong>
        </React.Fragment>
      )}
    </Timer>
  );
};
