import {
  HANDLE_INPUT,
  ADD_CITY,
  CITY_LIST,
  EDIT_CITY,
  GET_CITY,
} from "../actions/cityType";

const initialState: any = {
  cityname: "",
  countryname: "",
  cityObj: null,
  cities: [] || "",
  message: "",
  isInserted: "",
  isUpdated: "",
};

const cityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HANDLE_INPUT:
      return {
        ...state,
        [action.input.name]: action.input.value,
      };
    case CITY_LIST:
      return {
        ...state,
        cities: action.payload,
      };
    case ADD_CITY:
      return {
        ...state,
        message: action.payload,
        isInserted: action.isInserted,
      };
    case EDIT_CITY:
      return {
        ...state,
        message: action.payload,
        isUpdated: action.isUpdated,
      };
    case GET_CITY:
      return {
        ...state,
        cityname: action.payload.city_name,
        countryname: action.payload.countryname,
        cityObj: action.payload,
      };
    default:
      return state;
  }
};
export default cityReducer;
