import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { brandsList, aggregatorsList } from "../../redux";
import { storesListForMultiSelectInMenu } from "../../redux/actions/menuAction";
import { logoutUser } from "../../redux";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import { storesList, fpMenuLogs } from "../../redux/actions/storeAction";
import Select from "react-select";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class MenuLogs extends Component<
  {
    storesList: (brand_id: any, aggregator_id: any) => {};
    pingConnectivity: (data: any) => {};
    logoutUser: () => {};
    stores: any[];
    aggregators: any[];
    brands: any;
    brandsList: () => {};
    aggregatorsList: () => {};
    pingResponse: any;
    pingLoad: any;
  },
  { store: any; store_name: any; brand_id: any; aggregator_id: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      store: "",
      store_name: "",
      brand_id: "",
      aggregator_id: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.props.brandsList();
    //this.props.storesList();
    this.props.aggregatorsList();

    document.title = "SimpleXSync | Aggregator Menu Logs";
  }
  isPingReady = () => {
    const { store } = this.state;
    return store !== "";
  };
  handleSubmit = () => {
    let data = this.state;
    this.props.pingConnectivity(data);
  };
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.setState({ store: e.value });
      this.setState({ store_name: e.label });
    } else {
      this.setState({ store: "" });
      this.setState({ store_name: "" });
    }
  };

  handleBrandInputChange = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.setState({ brand_id: e.brand_id });
      this.setState({ store: "" });
      this.setState({ store_name: "" });
    } else {
      this.setState({ brand_id: "" });
      this.setState({ store: "" });
      this.setState({ store_name: "" });
    }
    if (e.brand_id != "" && this.state.aggregator_id != "") {
      let a = this.props.storesList(e.brand_id, this.state.aggregator_id);
      this.setState({ store: "" });
      this.setState({ store_name: "" });
    }
  };

  handleAggregatorInputChange = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.setState({ aggregator_id: e.value });
      this.setState({ store: "" });
      this.setState({ store_name: "" });
    } else {
      this.setState({ aggregator_id: "" });
      this.setState({ store: "" });
      this.setState({ store_name: "" });
    }

    if (this.state.brand_id != "" && e.value != "") {
      let a = this.props.storesList(this.state.brand_id, e.value);
      this.setState({ store: "" });
      this.setState({ store_name: "" });
    }
  };

  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Menu Logs</h4>
                </div>
              </div>
            </header>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label ">
                                Brand<span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable
                                options={this.props.brands}
                                className="text-capitalize select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleBrandInputChange(e, i)
                                }
                              />

                              <label
                                className="form-control-label "
                                style={{ paddingTop: "13px" }}
                              >
                                Aggregators
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable
                                options={this.props.aggregators.map(
                                  (data: any) => {
                                    return {
                                      label: data.aggregator_name,
                                      value: data.id,
                                    };
                                  },
                                )}
                                className="text-capitalize select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleAggregatorInputChange(e, i)
                                }
                              />
                              <label
                                className="form-control-label "
                                style={{ paddingTop: "13px" }}
                              >
                                Store<span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable
                                options={this.props.stores}
                                //isDisabled = {}
                                value={{
                                  label: this.state.store_name,
                                  value: this.state.store,
                                }}
                                className="text-capitalize select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleStoresInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {this.props.pingResponse &&
                        this.props.pingResponse.length > 0 ? (
                          <div className="row my-4">
                            <div className="col">
                              <div className="table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col">Message</th>
                                      {this.props.pingResponse[0]
                                        ?.menuImportId != 1 ? (
                                        <th scope="col">Menu Import ID</th>
                                      ) : null}
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.props.pingResponse.map(
                                      (item: any, index: any) => (
                                        <tr key={index}>
                                          <td className="col-5">
                                            {item.message}
                                          </td>

                                          {item.menuImportId != 1 ? (
                                            <td className="col-3">
                                              {item.menuImportId}
                                            </td>
                                          ) : null}
                                          <td className="col-3 text-capitalize">
                                            <span
                                              className={`badge ${item.status === "done" ? "badge-success" : "badge-danger"} p-2`}
                                            >
                                              {item.status}
                                            </span>
                                          </td>
                                        </tr>
                                      ),
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="form-group d-flex justify-content-end mt-4">
                          {this.props.pingLoad ? (
                            <button
                              className="btn btn-primary d-flex justify-content-end align-item-center"
                              disabled={this.props.pingLoad}
                            >
                              <Loader
                                type="TailSpin"
                                color="white"
                                height={30}
                                width={30}
                              />
                            </button>
                          ) : (
                            <button
                              onClick={this.handleSubmit}
                              disabled={!this.isPingReady()}
                              className="btn btn-primary"
                            >
                              <i className="fa fa-rss mr-2"></i>Connect
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    stores: state.menu.storesoptions,
    pingResponse: state.store.fpMenuLogs,
    pingLoad: state.store.pingLoad,
    brands: state.brand.brands,
    aggregators: state.aggregator.aggregators,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: () => {
      dispatch(logoutUser());
    },
    pingConnectivity: (data: any) => {
      dispatch(fpMenuLogs(data));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
    aggregatorsList: function () {
      dispatch(aggregatorsList());
    },
    storesList: (brand_id: any, aggregator_id: any) => {
      dispatch(storesListForMultiSelectInMenu(brand_id, aggregator_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuLogs);
