import Api from "../../components/Api";
import {
  DISCOUNT_LIST,
  GET_DISCOUNT,
  ADD_DISCOUNT,
  EDIT_DISCOUNT,
} from "./discountType";
import { toast } from "react-toastify";
import jwt from "jsonwebtoken";
import "react-toastify/dist/ReactToastify.css";
import { saveLogs } from "./storeAction";
import { menusListForMaster } from "./menuAction";
toast.configure();
export const discountsList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/discounts", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: DISCOUNT_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const getDiscount = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/discount/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_DISCOUNT,
            payload: response.data.successResponse,
          });
          dispatch(menusListForMaster(response.data.successResponse.brand_id));
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const addDiscount = (data: any, history: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.post("/admin/add_discount", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/marketing/2";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace("_", " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const editDiscount = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.put(`/admin/edit_discount/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/marketing/2";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace("_", " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const deleteDiscount = (id: number, history: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let data = {
      user_info: jwt.decode(token),
    };
    Api.post(`/admin/del_discount/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          history.push("/marketing/2");
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const activeInactiveDiscount = (
  id: any,
  isActive: any,
  history: any,
) => {
  return function (dispatch: any) {
    let is_active: any;
    if (isActive === 0) {
      is_active = 1; //is_active =0 it's mean that Discount is inactive
    } else if (isActive === 1) {
      is_active = 0; //is_active =1 it's mean that Discount is active
    }
    let token: any = sessionStorage.getItem("token");
    let data = {
      is_active: is_active,
      user_info: jwt.decode(token),
    };
    Api.put(`/admin/discount_active_inactive/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          history.push("/marketing/2");
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
