import React, { Component } from "react";
import { AddReasonProps, AddReasonState } from "../../interfaces/settings";
import { connect } from "react-redux";
import { logoutUser } from "../../redux";
import { Link, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import { addReason } from "../../redux/actions/settingsAction";

class AddRole extends Component<AddReasonProps, AddReasonState> {
  constructor(props: any) {
    super(props);
    this.state = {
      reason: "",
      type: "cancel order",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    document.title = "SimpleXSync | Reasons";
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  isReady = () => {
    const { reason, type } = this.state;
    return reason !== "" && type !== "";
  };
  handleSubmit = (event: any) => {
    let { reason, type } = this.state;
    let data = {
      reason: reason,
      type: type,
      is_active: 1,
    };
    this.props.addReason(data);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    return (
      <div className="page">
        <CheckChanges path="/add-reason" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Reasons Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/reasons" className="text-primary">
                    Reasons
                  </Link>
                </li>
                <li className="breadcrumb-item active">Add Reason</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-lable">
                                Reason <span className="text-danger">*</span>
                              </label>
                              <input
                                id="reason"
                                type="text"
                                name="reason"
                                value={this.state.reason}
                                required
                                data-msg="Please enter reason"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-lable">
                                Type <span className="text-danger">*</span>
                              </label>
                              <select
                                name="type"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select Type"
                                onChange={this.handleInputChange}
                              >
                                <option value="cancel order">
                                  Cancel Order
                                </option>
                                <option value="complaint">Complaint</option>
                                <option value="menu">Menu</option>
                                <option value="store">Store</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isReady()}
                            onClick={this.handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addReason: function (data: any) {
      dispatch(addReason(data));
    },
  };
};
export default connect(null, mapDispatchToProps)(AddRole);
