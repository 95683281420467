import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { EditUserProps, EditUserState } from "../../interfaces/settings";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import {
  editUser,
  logoutUser,
  getUser,
  rolesList,
  countryList,
  storesList,
  userGroupsList,
  brandsList,
} from "../../redux";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import { userGroupsListByBrand } from "../../redux/actions/settingsAction";
import Select from "react-select";

class EditUser extends Component<EditUserProps, EditUserState> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      city: "",
      country: "",
      brand_id: "",
      brand_name: "",
      address: "",
      roleId: "",
      storeId: "",
      groupId: "",
      isValidEmail: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getUser(id);
    this.props.rolesList();
    this.props.countryList();
    this.props.storesList();
    this.props.brandsList();
    document.title = "SimpleXSync | Users";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      firstname: nextProps.userData.first_name,
      lastname: nextProps.userData.last_name,
      email: nextProps.userData.email_address,
      city: nextProps.userData.city,
      country: nextProps.userData.country_id,
      address: nextProps.userData.address,
      roleId: nextProps.userData.role_id,
      brand_id: nextProps.userData.brand_id,
      brand_name: nextProps.userData.brand_name,
      groupId: nextProps.userData.user_group_id,
      // storeId: nextProps.userData.store_id
    });
  }
  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value, brand_name: e.label });
    this.props.userGroupsList(e.value);
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    if (event.target.name == "groupId") {
      if (this.state.roleId == 3 || this.state.roleId == 5) {
        let userGroup =
          this.props.userGroups &&
          this.props.userGroups.find((obj: any) => {
            return event.target.value == obj.id;
          });
        if (userGroup.group_store_id.length > 1) {
          alert(
            "Please select the group for this role that has only one store.",
          );
          this.setState({
            [event.target.name]: "",
          });
        } else {
          this.setState({
            [event.target.name]: event.target.value,
          });
        }
      } else {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }
  isUserReady = () => {
    let {
      firstname,
      email,
      country,
      address,
      roleId,
      groupId,
      isValidEmail,
      brand_id,
    } = this.state;
    return (
      firstname !== "" &&
      email !== "" &&
      country !== "" &&
      brand_id != "" &&
      address !== "" &&
      roleId !== "" &&
      groupId !== "" &&
      isValidEmail === true
    );
    // if (roleId == 3 || roleId == 5 || roleId == 6) {
    //     return (firstname !== "" && email !== "" && country !== "" && address !== "" && roleId !== "" && storeId !== "" && isValidEmail === true);
    // } else {
    //     return (firstname !== "" && email !== "" && country !== "" && address !== "" && roleId !== "" && isValidEmail === true);
    // }
  };
  validateEmail(event: { target: { name: any; value: any } }) {
    let reg = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,4}$/;
    if (reg.test(event.target.value)) {
      this.setState({ email: event.target.value, isValidEmail: true });
    } else {
      this.setState({ email: event.target.value, isValidEmail: false });
    }
  }
  handleSubmit = (event: any) => {
    event.preventDefault();
    let {
      firstname,
      lastname,
      email,
      city,
      country,
      brand_id,
      address,
      roleId,
      groupId,
    } = this.state;
    let id = this.props.match.params.id;
    let data = {
      first_name: firstname,
      last_name: lastname,
      user_name: lastname === "" ? firstname : firstname + " " + lastname,
      email_address: email,
      city: city,
      brand_id,
      country_id: country,
      address: address,
      role_id: roleId,
      user_group_id: groupId,
    };
    if (this.state.roleId == 3 || this.state.roleId == 5) {
      let userGroup =
        this.props.userGroups &&
        this.props.userGroups.find((obj: any) => {
          return this.state.groupId == obj.id;
        });
      if (userGroup.group_store_id.length > 1) {
        alert("Please select the group for this role that has only one store.");
        this.setState({
          groupId: "",
        });
      } else {
        this.props.editUser(id, data);
      }
    } else {
      this.props.editUser(id, data);
    }
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    const { roles, userData, countries, userGroups } = this.props;
    let { isValidEmail, email } = this.state;
    let tokendata: any = jwt.decode(sessionStorage.token);
    let roleId = tokendata.role_id;
    if (
      Object.values(userData).length > 0 &&
      roleId !== 7 &&
      (userData.role_id == 7 || userData.role_id == 1)
    ) {
      return <Redirect to="/users" />;
    }
    return (
      <div className="page">
        <CheckChanges path="/edit-user" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header pb-1">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Users Management</h4>
                </div>
              </div>
            </header>
            {/*  Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/users" className="text-primary">
                    Users
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit User</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <form method="post" className="form-validate">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label ">
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="firstname"
                                  type="text"
                                  name="firstname"
                                  defaultValue={userData.first_name}
                                  required
                                  data-msg="Please enter first name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-lable">
                                  Last Name
                                </label>
                                <input
                                  id="lastname"
                                  type="text"
                                  name="lastname"
                                  defaultValue={userData.last_name}
                                  required
                                  data-msg="Please enter last name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-lable">
                                  Email <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="email"
                                  type="email"
                                  name="email"
                                  defaultValue={userData.email_address}
                                  required
                                  data-msg="Please enter email"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                                {isValidEmail == false && email !== "" && (
                                  <small className="form-text text-danger">
                                    email is not correct
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-lable">
                                  City
                                </label>
                                <input
                                  id="city"
                                  type="text"
                                  name="city"
                                  defaultValue={userData.city}
                                  required
                                  data-msg="Please enter city"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Country <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="country"
                                  className="form-control mt-2"
                                  required
                                  data-msg="Please enter Country"
                                  onChange={this.handleInputChange}
                                >
                                  <option disabled>Select Country</option>
                                  {countries &&
                                    countries.map(
                                      (country: any, index: any) => (
                                        <option
                                          key={index}
                                          value={country.country_id}
                                          {...(userData.country_id ==
                                            country.country_id && {
                                            selected: true,
                                          })}
                                        >
                                          {country.country_name}
                                        </option>
                                      ),
                                    )}{" "}
                                </select>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-lable">
                                  Address <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="address"
                                  type="text"
                                  name="address"
                                  defaultValue={userData.address}
                                  required
                                  data-msg="Please enter address"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Role <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="roleId"
                                  className="form-control mt-2"
                                  required
                                  data-msg="Please Select Role"
                                  onChange={this.handleInputChange}
                                >
                                  <option disabled>Select Role</option>
                                  {roles &&
                                    roles.map((role, index) =>
                                      roleId !== 7 ? (
                                        role.role_id !== 7 &&
                                        role.role_id !== 1 && (
                                          <option
                                            key={index}
                                            value={role.role_id}
                                            {...(userData.role_id ==
                                              role.role_id && {
                                              selected: true,
                                            })}
                                          >
                                            {role.role_name}
                                          </option>
                                        )
                                      ) : (
                                        <option
                                          key={index}
                                          value={role.role_id}
                                          {...(userData.role_id ==
                                            role.role_id && { selected: true })}
                                        >
                                          {role.role_name}
                                        </option>
                                      ),
                                    )}
                                </select>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Select Brand{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  name="brands"
                                  options={selectedBrands}
                                  className="text-capitalize basic-multi-select mt-2"
                                  classNamePrefix="select"
                                  value={{
                                    label: this.state.brand_name,
                                    value: this.state.brand_id,
                                  }}
                                  onChange={(e, i) =>
                                    this.handleBrandsInputChange(e, i)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Group <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="groupId"
                                  className="form-control mt-2"
                                  value={this.state.groupId}
                                  required
                                  data-msg="Please Select Group"
                                  onChange={this.handleInputChange}
                                >
                                  <option value="">Select Group</option>
                                  {userGroups &&
                                    userGroups.map((group, index) => (
                                      <option
                                        key={index}
                                        value={group.id}
                                        {...(userData.user_group_id ==
                                          group.id && { selected: true })}
                                      >
                                        {group.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            {/* {
                                                            (this.state.roleId == 3 || this.state.roleId == 5 || this.state.roleId == 6) && <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Store <span className="text-danger">*</span></label>
                                                                    <select name="storeId" className="form-control mt-2" required data-msg="Please Select Role" onChange={this.handleInputChange}>
                                                                        <option value="">Select Store</option>
                                                                        {stores &&
                                                                            stores.map((store, index) => (
                                                                                <option key={index} value={store.store_id} {...userData.store_id == store.store_id && { selected: true }}>{store.store_name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        } */}
                          </div>
                          <div className="form-group float-right">
                            <button
                              onClick={this.handleSubmit}
                              disabled={!this.isUserReady()}
                              className="btn btn-primary"
                            >
                              Update User
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    userData: state.setting.userData,
    userGroups: state.setting.userGroups,
    roles: state.setting.roles,
    countries: state.store.countries,
    stores: state.store.data,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editUser: function (id: any, data: any) {
      dispatch(editUser(id, data));
    },
    getUser: function (id: number) {
      dispatch(getUser(id));
    },
    rolesList: function () {
      dispatch(rolesList());
    },
    countryList: function () {
      dispatch(countryList());
    },
    storesList: function () {
      dispatch(storesList());
    },
    userGroupsList: function (brand_id: any) {
      dispatch(userGroupsListByBrand(brand_id));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
