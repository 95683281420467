// import React, { Component } from 'react';
// import { Link, Redirect } from 'react-router-dom'
// import { EditTradeZoneProps } from '../../interfaces/tradeZone'
// import { connect } from 'react-redux'
// import jwt from 'jsonwebtoken'
// import { secretKey } from '../../secret'
// import { logoutUser, handleZoneInput, getTradeZone, editTradeZone, citiesList } from '../../redux'
// import Topbar from '../../components/topbar'
// import Sidebar from '../../components/sidebar'
// import Footer from '../../components/footer/main'
// import CheckChanges from '../../components/confirmOnLeave'

// class EditTradeZone extends Component<EditTradeZoneProps, {}> {
//     constructor(props: any) {
//         super(props);
//         this.handleSubmit = this.handleSubmit.bind(this);
//     }
//     componentDidMount() {
//         let id = this.props.match.params.id;
//         this.props.getTradeZone(id);
//         this.props.citiesList();
//         document.title = "SimplexYum | TradeZones"
//     }
//     isZoneReady = () => {
//         const { tradezonename, city } = this.props;
//         return (tradezonename !== "" && city !== "");
//     }
//     handleSubmit = () => {
//         let { tradezonename, city } = this.props;
//         let id = this.props.match.params.id;
//         let data = {
//             delivery_zone_name: tradezonename,
//             city_id: city
//         }
//         this.props.editTradeZone(id, data);
//     }
//     render() {
//         if (sessionStorage.token) {
//             jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
//                 if (err) {
//                     this.props.logoutUser();
//                 }
//             });
//         } else {
//             return <Redirect to="/" />
//         }
//         let msg;
//         let messcolor;
//         const { tradezonename, cities } = this.props;
//         return (
//             <div className="page">
//                 <CheckChanges path="/edit-brand" />
//                 <Topbar />
//                 <div className="page-content d-flex align-items-stretch">
//                     <Sidebar />
//                     <div className="content-inner">
//                         {/*  Page Header */}
//                         <header className="page-header py-0">
//                             <div className="container-fluid">
//                                 <div className="d-flex justify-content-between py-3">
//                                     <h4 className="mt-2">Trade Zones Management</h4>
//                                 </div>
//                             </div>
//                         </header>
//                         {/* Breadcrumb */}
//                         <div className="breadcrumb-holder container-fluid">
//                             <ul className="breadcrumb">
//                                 <li className="breadcrumb-item"><Link to="/tradezone" className="text-primary">Trade Zones</Link></li>
//                                 <li className="breadcrumb-item active">Edit Trade Zones</li>
//                             </ul>
//                         </div>
//                         <section className="forms">
//                             <div className="container-fluid">
//                                 <div className="row">
//                                     <div className="col-lg-12">
//                                         <div className="card">
//                                             <div className="card-body">
//                                                 {/* <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
//                                                     {msg}
//                                                 </p></strong> */}
//                                                 <div className="row">
//                                                     <div className="col">
//                                                         <div className="form-group">
//                                                             <label className="form-control-label">Trade Zone name <span className="text-danger">*</span></label>
//                                                             <input id="tradezonename" type="text" name="tradezonename" value={tradezonename} required data-msg="Please enter Brand Name" className="input-material" onChange={this.props.handleZoneInput} />
//                                                         </div>
//                                                     </div>
//                                                     <div className="col">
//                                                         <div className="form-group">
//                                                             <label className="form-control-label">City</label>
//                                                             <select name="city" className="form-control mt-2" required data-msg="Please Select City" onChange={this.props.handleZoneInput}>
//                                                                 <option value="">Select City</option>
//                                                                 {cities &&
//                                                                     cities.map((city, index) => (
//                                                                         <option key={index} value={city.id} {...this.props.city === city.id && { selected: true }}>{city.name}</option>
//                                                                     ))
//                                                                 }
//                                                             </select>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div className="form-group d-flex justify-content-end mt-4">
//                                                     {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
//                                                     <button onClick={this.handleSubmit} disabled={!this.isZoneReady()} className="btn btn-primary">Update TradeZone</button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </section>
//                         <Footer />
//                     </div>

//                 </div>
//             </div >
//         );
//     }
// }
// ;
// const mapStateToProps = (state: any) => {
//     return {
//         tradezonename: state.tradezone.tradezonename,
//         city: state.tradezone.city,
//         cities: state.store.cities
//     }
// }
// const mapDispatchToProps = (dispatch: any) => {
//     return {
//         logoutUser: () => { dispatch(logoutUser()); },
//         handleZoneInput: (event: any) => { dispatch(handleZoneInput(event)) },
//         editTradeZone: (id: any, data: any) => { dispatch(editTradeZone(id, data)); },
//         getTradeZone: (id: number) => { dispatch(getTradeZone(id)) },
//         citiesList: () => { dispatch(citiesList()) },
//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(EditTradeZone);
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { EditTradeZoneProps } from "../../interfaces/tradeZone";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import Map from "./map";
import {
  logoutUser,
  handleZoneInput,
  getTradeZone,
  editTradeZone,
  citiesList,
} from "../../redux";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import { GoogleMapsAPI } from "../../client-config";

class EditTradeZone extends Component<
  EditTradeZoneProps,
  {
    kmlFile: any;
    kml: any;
    lat: any;
    lng: any;
    newlat: any;
    newlng: any;
    [x: number]: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      kmlFile: null,
      kml: "",
      lat: 0,
      lng: 0,
      newlat: 0,
      newlng: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getTradeZone(id);
    this.props.citiesList();
    document.title = "SimplexYum | TradeZones";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    console.log("nextProps.tradeZoneData", nextProps.tradeZoneData);
    this.setState({
      kml: nextProps.tradeZoneData && nextProps.tradeZoneData.kml_shape,
      lat: nextProps.tradeZoneData && nextProps.tradeZoneData.centerlat,
      lng: nextProps.tradeZoneData && nextProps.tradeZoneData.centerlng,
    });
  }
  isZoneReady = () => {
    const { tradezonename, city } = this.props;
    return tradezonename !== "" && city !== "";
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  fileSelectedHandler = (e: any) => {
    this.setState({ kmlFile: e.target.files });
  };
  handleSubmit = () => {
    let { tradezonename, city } = this.props;
    let id = this.props.match.params.id;
    let { kml, kmlFile, lat, lng } = this.state;
    const data: any = new FormData();
    if (this.state.kmlFile) {
      data.append("file", kmlFile[0]);
    }
    data.append("delivery_zone_name", tradezonename);
    data.append("kml_shape", kml);
    data.append("centerlat", lat);
    data.append("centerlng", lng);
    data.append("city_id", city);
    data.append("is_active", 1);
    this.props.editTradeZone(id, data);
  };
  handleMapData = (obj: any) => {
    //to Get the values from the map component and set in state
    this.setState({
      lat: obj.lat,
      lng: obj.lng,
    });
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    const { tradezonename, cities } = this.props;
    return (
      <div className="page">
        <CheckChanges path="/edit-brand" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Trade Zones Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/tradezone" className="text-primary">
                    Trade Zones
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Trade Zones</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Trade Zone name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="tradezonename"
                                type="text"
                                name="tradezonename"
                                value={tradezonename}
                                required
                                data-msg="Please enter Brand Name"
                                className="input-material"
                                onChange={this.props.handleZoneInput}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                City <span className="text-danger">*</span>
                              </label>
                              <select
                                name="city"
                                className="form-control mt-2"
                                required
                                data-msg="Please Select City"
                                onChange={this.props.handleZoneInput}
                              >
                                <option value="">Select City</option>
                                {cities &&
                                  cities.map((city, index) => (
                                    <option
                                      key={index}
                                      value={city.id}
                                      {...(this.props.city === city.id && {
                                        selected: true,
                                      })}
                                    >
                                      {city.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div style={{ width: "100%" }}>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label htmlFor="">Latitude</label>
                                <input
                                  type="text"
                                  name="newlat"
                                  className="form-control"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label htmlFor="">Longitude</label>
                                <input
                                  type="text"
                                  name="newlng"
                                  className="form-control"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <Map
                            data={this.state}
                            center={{
                              lat: this.state.newlat,
                              lng: this.state.newlng,
                            }}
                            kml={this.state.kml}
                            onSetData={this.handleMapData}
                            google={GoogleMapsAPI}
                            height="400px"
                            zoom={15}
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-12">
                            <div className="form-group">
                              <label className="form-control-label mb-3">
                                KML File <span className="text-danger">*</span>
                              </label>
                              <input
                                id="files"
                                type="file"
                                name="files"
                                className="form-control-file"
                                onChange={this.fileSelectedHandler}
                              />
                            </div>
                          </div>
                          <div className="col-md-8 col-12">
                            <div className="form-group">
                              <label className="form-control-label ">
                                KML Link <span className="text-danger">*</span>
                              </label>
                              <input
                                id="kml"
                                type="text"
                                name="kml"
                                defaultValue={this.state.kml}
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                          <button
                            onClick={this.handleSubmit}
                            disabled={!this.isZoneReady()}
                            className="btn btn-primary"
                          >
                            Update TradeZone
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    tradeZoneData: state.tradezone.tradeZoneData,
    tradezonename: state.tradezone.tradezonename,
    city: state.tradezone.city,
    cities: state.store.cities,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: () => {
      dispatch(logoutUser());
    },
    handleZoneInput: (event: any) => {
      dispatch(handleZoneInput(event));
    },
    editTradeZone: (id: any, data: any) => {
      dispatch(editTradeZone(id, data));
    },
    getTradeZone: (id: number) => {
      dispatch(getTradeZone(id));
    },
    citiesList: () => {
      dispatch(citiesList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTradeZone);
