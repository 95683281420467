import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { EditRoleProps, EditRoleState } from "../../interfaces/settings";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { editRole, logoutUser, getRole } from "../../redux";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";

class EditRole extends Component<EditRoleProps, EditRoleState> {
  constructor(props: any) {
    super(props);
    this.state = {
      rolename: "",
      type: "cms",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getRole(id);
    document.title = "SimpleXSync | Roles";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      rolename: nextProps.roleData.role_name,
      type: nextProps.roleData.type,
    });
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  isRoleReady = () => {
    const { rolename, type } = this.state;
    return rolename !== "" && type !== "";
  };
  handleSubmit = (event: any) => {
    event.preventDefault();
    let { rolename, type } = this.state;
    let id = this.props.match.params.id;
    let data = {
      role_name: rolename,
      type: type,
    };
    this.props.editRole(id, data);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isUpdated, message, roleData } = this.props;
    if (isUpdated) {
      msg = message;
      messcolor = "green";
    } else {
      msg = message;
      messcolor = "red";
    }
    let tokendata: any = jwt.decode(sessionStorage.token);
    let roleId = tokendata.role_id;
    if (roleId !== 7) {
      return <Redirect to="/roles" />;
    }
    return (
      <div className="page">
        <CheckChanges path="/edit-role" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header pb-1">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Roles Management</h4>
                </div>
              </div>
            </header>
            {/*  Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/roles" className="text-primary">
                    Roles
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Role</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <form method="post" className="form-validate">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-label ">
                                  Role Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="rolename"
                                  type="text"
                                  name="rolename"
                                  defaultValue={roleData.role_name}
                                  required
                                  data-msg="Please enter role name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-lable">
                                  Type <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="type"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Type"
                                  onChange={this.handleInputChange}
                                >
                                  <option
                                    value="cms"
                                    {...(roleData.type == "cms" && {
                                      selected: true,
                                    })}
                                  >
                                    Cms
                                  </option>
                                  <option
                                    value="store"
                                    {...(roleData.type == "store" && {
                                      selected: true,
                                    })}
                                  >
                                    Store
                                  </option>
                                  <option
                                    value="crm"
                                    {...(roleData.type == "crm" && {
                                      selected: true,
                                    })}
                                  >
                                    Crm
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="form-group float-right">
                            <button
                              onClick={this.handleSubmit}
                              disabled={!this.isRoleReady()}
                              className="btn btn-primary"
                            >
                              Update Role
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    roleData: state.setting.roleData,
    message: state.setting.message,
    isUpdated: state.setting.isUpdated,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editRole: function (id: any, data: any) {
      dispatch(editRole(id, data));
    },
    getRole: function (id: number) {
      dispatch(getRole(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditRole);
