import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import jwt from "jsonwebtoken";
import { Provider } from "react-redux";
import store from "./redux/store";
import login from "./pages/login";
import forgot from "./pages/forgotpass";
import home from "./pages/home";
import profile from "./pages/profile";
import customer from "./pages/customers/customer";
import NewsLetter from "./pages/customers/newsletter";
import blockcustomers from "./pages/customers/block-customers";
import customerFeedback from "./pages/customers/customer-feedback";
import FranchiseApp from "./pages/customers//franchise";
import editCustomer from "./pages/customers/edit-customer";
import stores from "./pages/stores/stores";
import addStore from "./pages/stores/add-store";
import editStore from "./pages/stores/edit-store";
import brands from "./pages/brands/brands";
import addBrand from "./pages/brands/add-brand";
import editBrand from "./pages/brands/edit-brand";
import cities from "./pages/Cities/cities";
import addCities from "./pages/Cities/add-cities";
import editCities from "./pages/Cities/edit-cities";
import menu from "./pages/menu/menu";
import addMenu from "./pages/menu/add-menu";
import editMenu from "./pages/menu/edit-menu";
import groups from "./pages/menu/groups/groups";
import addGroup from "./pages/menu/groups/add-group";
import editGroup from "./pages/menu/groups/edit-group";
import menuItem from "./pages/menu/menuItem/menu-item";
import addMenuItem from "./pages/menu/menuItem/add-menu-item";
import editMenuItem from "./pages/menu/menuItem/edit-menu-item";
import combo from "./pages/menu/combo/combo";
import addCombo from "./pages/menu/combo/add-combo";
import editCombo from "./pages/menu/combo/edit-combo";
import modGroup from "./pages/menu/modifiers/mod-group";
import addModGroup from "./pages/menu/modifiers/add-mod-group";
import editModGroup from "./pages/menu/modifiers/edit-mod-group";
import modifiers from "./pages/menu/modifiers/modifiers";
import syncLogs from "./pages/menu/syncLogs/sync-logs";
import addModifier from "./pages/menu/modifiers/add-modifier";
import editModifier from "./pages/menu/modifiers/edit-modifier";
import BranchMenu from "./pages/menu/branchMenu";
import ing from "./pages/menu/ingredients/ingredients";
import recipes from "./pages/menu/recipe/recipes";
import coupons from "./pages/coupons/coupons";
import addCoupon from "./pages/coupons/add_coupon";
import addDiscount from "./pages/discounts/add_discount";
import editCoupon from "./pages/coupons/edit_coupon";
import editDiscount from "./pages/discounts/edit_discount";
import promos from "./pages/promos/promos";
import addPromo from "./pages/promos/add_promo";
import editPromo from "./pages/promos/edit_promo";
import addNotification from "./pages/PushNotification/add_notification";
import editNotification from "./pages/PushNotification/edit_notification";
import orders from "./pages/orders/orders";
import OutBoundContacts from "./pages/orders/outboundcontacts";
import payments from "./pages/payments/payments";
import users from "./pages/users/users";
import addUsers from "./pages/users/add-user";
import editUsers from "./pages/users/edit-user";
import userGroups from "./pages/settings/userGroups/usergroups";
import addUserGroup from "./pages/settings/userGroups/add-usergroup";
import editUserGroup from "./pages/settings/userGroups/edit-usergroup";
import roles from "./pages/roles/roles";
import addRoles from "./pages/roles/add-role";
import editRoles from "./pages/roles/edit-role";
import settings from "./pages/settings/settings";
import states from "./pages/settings/states/state";
import addState from "./pages/settings/states/add-state";
import editState from "./pages/settings/states/edit-state";
import Reports from "./pages/reports/reports";
import tradeZone from "./pages/tradezone/tradezone";
import addTradezone from "./pages/tradezone/add-tradezone";
import editTradezone from "./pages/tradezone/edit-tradezone";
import tradeArea from "./pages/tradezone/tradearea";
import addTradearea from "./pages/tradezone/add-tradearea";
import editTradearea from "./pages/tradezone/edit-tradearea";
import marketing from "./pages/marketing/marketing";
import customerComplaints from "./pages/customers/customer-complaints";
import Bulk from "./pages/BulkUplaod/upload";
import AddressBulk from "./pages/BulkUplaod/address";
import Drivers from "./pages/drivers/drivers";
import reasons from "./pages/reasonsList/reasons";
import addReason from "./pages/reasonsList/add-reasons";
import editReason from "./pages/reasonsList/edit-reasons";
import accessManage from "./pages/settings/roleAccess";
import Aggregators from "./pages/Aggregators/aggregators";
import AddAggregator from "./pages/Aggregators/addAggregator";
import EditAggregator from "./pages/Aggregators/editAggregator";
import AddSubGroup from "./pages/menu/subgroups/add-subgroup";
import EditSubGroup from "./pages/menu/subgroups/edit-subgroup";
import SubGroups from "./pages/menu/subgroups/subgroups";
import fpMenuLogs from "./pages/MenuLogs/menu-logs";
const Notfound = () => <h1 className="text-center">Not found</h1>;
function App() {
  let tokendata: any = jwt.decode(sessionStorage.token);
  let features: any = {};
  let roleId;
  if (sessionStorage.token) {
    features = JSON.parse(tokendata.features);
    roleId = tokendata.role_id;
  }
  let allowedRoutes = (
    <Switch>
      <Route exact path="/" component={login} />
      <Route exact path="/forgot" component={forgot} />
      <Route exact path="/dashboard" component={home} />
      <Route exact path="/profile" component={profile} />
      <Route exact path="/customers" component={customer} />
      <Route exact path="/newsletter" component={NewsLetter} />
      <Route exact path="/block-customers" component={blockcustomers} />
      <Route exact path="/edit-customer/:id" component={editCustomer} />
      <Route exact path="/feedbacks" component={customerFeedback} />
      <Route exact path="/franchise" component={FranchiseApp} />
      <Route exact path="/analytics" component={Reports} />
      <Route exact path="/complaints" component={customerComplaints} />
      <Route exact path="/orders" component={orders} />
      <Route exact path="/outbound_contacts" component={OutBoundContacts} />
      <Route exact path="/marketing/:id" component={marketing} />
      <Route exact path="/branchmenu" component={BranchMenu} />
      <Route exact path="/stores" component={stores} />
      <Route exact path="/add-store" component={addStore} />
      <Route exact path="/edit-store/:id" component={editStore} />
      <Route exact path="/tradezone" component={tradeZone} />
      <Route exact path="/add-tradezone" component={addTradezone} />
      <Route exact path="/edit-tradezone/:id" component={editTradezone} />
      <Route exact path="/tradearea" component={tradeArea} />
      <Route exact path="/add-tradearea" component={addTradearea} />
      <Route exact path="/edit-tradearea/:id" component={editTradearea} />
      <Route exact path="/cities" component={cities} />
      <Route exact path="/add-cities" component={addCities} />
      <Route exact path="/edit-cities/:id" component={editCities} />
      <Route exact path="/brands" component={brands} />
      <Route exact path="/add-brand" component={addBrand} />
      <Route exact path="/edit-brand/:id" component={editBrand} />
      <Route exact path="/menus" component={menu} />
      <Route exact path="/add-menu" component={addMenu} />
      <Route exact path="/edit-menu/:id/:type" component={editMenu} />
      <Route exact path="/groups" component={groups} />
      <Route exact path="/add-group" component={addGroup} />
      <Route exact path="/edit-group/:id/:menu_type" component={editGroup} />
      <Route exact path="/sub-groups" component={SubGroups} />
      <Route exact path="/add-sub-group" component={AddSubGroup} />
      <Route exact path="/edit-sub-group/:id/:type" component={EditSubGroup} />
      <Route exact path="/menu-items" component={menuItem} />
      <Route exact path="/add-item" component={addMenuItem} />
      <Route exact path="/edit-item/:id/:menu_type" component={editMenuItem} />
      <Route exact path="/combos" component={combo} />
      <Route exact path="/add-combo" component={addCombo} />
      <Route exact path="/edit-combo/:id/:menu_type" component={editCombo} />
      <Route exact path="/mod-groups" component={modGroup} />
      <Route exact path="/add-modgroup" component={addModGroup} />
      <Route
        exact
        path="/edit-modgroup/:id/:menu_type"
        component={editModGroup}
      />
      <Route exact path="/modifiers" component={modifiers} />
      <Route exact path="/syncLogs" component={syncLogs} />
      <Route exact path="/add-modifier" component={addModifier} />
      <Route exact path="/edit-modifier/:id/:type" component={editModifier} />
      {/* <Route exact path="/ingredients" component={ing} />
             <Route exact path="/recipes" component={recipes} /> */}
      <Route exact path="/add-coupon" component={addCoupon} />
      <Route exact path="/add-discount" component={addDiscount} />
      <Route exact path="/edit-coupon/:id" component={editCoupon} />
      <Route exact path="/edit-discount/:id" component={editDiscount} />
      <Route exact path="/add-promo" component={addPromo} />
      <Route exact path="/edit-promo/:id" component={editPromo} />
      <Route exact path="/add-notification" component={addNotification} />
      <Route exact path="/edit-notification/:id" component={editNotification} />
      <Route exact path="/payments" component={payments} />
      <Route exact path="/bulk" component={Bulk} />
      <Route exact path="/addressBulk" component={AddressBulk} />
      <Route exact path="/users" component={users} />
      <Route exact path="/drivers" component={Drivers} />
      <Route exact path="/add-user" component={addUsers} />
      <Route exact path="/edit-user/:id" component={editUsers} />
      <Route exact path="/usergroups" component={userGroups} />
      <Route exact path="/add-usergroup" component={addUserGroup} />
      <Route exact path="/edit-usergroup/:id" component={editUserGroup} />
      <Route exact path="/roles" component={roles} />
      <Route exact path="/add-role" component={addRoles} />
      <Route exact path="/edit-role/:id" component={editRoles} />
      <Route exact path="/settings" component={settings} />
      <Route exact path="/access-manage" component={accessManage} />
      <Route exact path="/states" component={states} />
      <Route exact path="/add-state" component={addState} />
      <Route exact path="/edit-state/:id" component={editState} />
      <Route exact path="/reasons" component={reasons} />
      <Route exact path="/add-reason" component={addReason} />
      <Route exact path="/edit-reason/:id" component={editReason} />
      <Route exact path="/aggregators" component={Aggregators} />
      <Route exact path="/add-aggregator" component={AddAggregator} />
      <Route exact path="/edit-aggregator/:id" component={EditAggregator} />
      <Route exact path="/aggregator-menu-logs" component={fpMenuLogs} />
      <Route path="*" exact component={Notfound} />
    </Switch>
  );
  return (
    // forceRefresh is used to force React Router to reload the page on every navigation
    <div className="App">
      <Provider store={store}>
        <Router forceRefresh>
          {roleId == 1 || roleId == 7 ? (
            allowedRoutes
          ) : (
            <Switch>
              <Route exact path="/" component={login} />
              <Route exact path="/forgot" component={forgot} />
              <Route exact path="/profile" component={profile} />
              {features &&
                features.feature1 &&
                features.feature1.permission == 1 && (
                  <Route exact path="/dashboard" component={home} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/stores" component={stores} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/add-store" component={addStore} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/edit-store/:id" component={editStore} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/tradezone" component={tradeZone} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/add-tradezone" component={addTradezone} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route
                    exact
                    path="/edit-tradezone/:id"
                    component={editTradezone}
                  />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/tradearea" component={tradeArea} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/add-tradearea" component={addTradearea} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route
                    exact
                    path="/edit-tradearea/:id"
                    component={editTradearea}
                  />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/cities" component={cities} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/add-cities" component={addCities} />
                )}
              {features &&
                features.feature2 &&
                features.feature2.permission == 1 && (
                  <Route exact path="/edit-cities/:id" component={editCities} />
                )}
              {features &&
                features.feature3 &&
                features.feature3.permission == 1 && (
                  <Route exact path="/branchmenu" component={BranchMenu} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/menus" component={menu} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/add-menu" component={addMenu} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route
                    exact
                    path="/edit-menu/:id/:type"
                    component={editMenu}
                  />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/groups" component={groups} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/add-group" component={addGroup} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route
                    exact
                    path="/edit-group/:id/:menu_type"
                    component={editGroup}
                  />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/sub-groups" component={SubGroups} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/add-group" component={AddSubGroup} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route
                    exact
                    path="/edit-group/:id/:menu_type"
                    component={editGroup}
                  />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/menu-items" component={menuItem} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/add-item" component={addMenuItem} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route
                    exact
                    path="/edit-item/:id/:menu_type"
                    component={editMenuItem}
                  />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/combos" component={combo} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/add-combo" component={addCombo} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route
                    exact
                    path="/edit-combo/:id/:menu_type"
                    component={editCombo}
                  />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/mod-groups" component={modGroup} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/add-modgroup" component={addModGroup} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route
                    exact
                    path="/edit-modgroup/:id/:menu_type"
                    component={editModGroup}
                  />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/modifiers" component={modifiers} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/syncLogs" component={syncLogs} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route exact path="/add-modifier" component={addModifier} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route
                    exact
                    path="/edit-modifier/:id/:type"
                    component={editModifier}
                  />
                )}
              {features &&
                features.feature5 &&
                features.feature5.permission == 1 && (
                  <Route exact path="/brands" component={brands} />
                )}
              {features &&
                features.feature5 &&
                features.feature5.permission == 1 && (
                  <Route exact path="/add-brand" component={addBrand} />
                )}
              {features &&
                features.feature5 &&
                features.feature5.permission == 1 && (
                  <Route exact path="/edit-brand/:id" component={editBrand} />
                )}
              {features &&
                features.feature6 &&
                features.feature6.permission == 1 && (
                  <Route exact path="/customers" component={customer} />
                )}
              {features &&
                features.feature6 &&
                features.feature6.permission == 1 && (
                  <Route exact path="/newsletter" component={NewsLetter} />
                )}
              {features &&
                features.feature6 &&
                features.feature6.permission == 1 && (
                  <Route
                    exact
                    path="/block-customers"
                    component={blockcustomers}
                  />
                )}
              {features &&
                features.feature6 &&
                features.feature6.permission == 1 && (
                  <Route
                    exact
                    path="/edit-customer/:id"
                    component={editCustomer}
                  />
                )}
              {features &&
                features.feature6 &&
                features.feature6.permission == 1 && (
                  <Route exact path="/feedbacks" component={customerFeedback} />
                )}
              {features &&
                features.feature6 &&
                features.feature6.permission == 1 && (
                  <Route exact path="/franchise" component={FranchiseApp} />
                )}
              {features &&
                features.feature6 &&
                features.feature6.permission == 1 && (
                  <Route
                    exact
                    path="/complaints"
                    component={customerComplaints}
                  />
                )}
              {features &&
                features.feature7 &&
                features.feature7.permission == 1 && (
                  <Route exact path="/marketing/:id" component={marketing} />
                )}
              {features &&
                features.feature7 &&
                features.feature7.permission == 1 && (
                  <Route exact path="/add-coupon" component={addCoupon} />
                )}
              {features &&
                features.feature7 &&
                features.feature7.permission == 1 && (
                  <Route exact path="/add-discount" component={addDiscount} />
                )}
              {features &&
                features.feature7 &&
                features.feature7.permission == 1 && (
                  <Route exact path="/edit-coupon/:id" component={editCoupon} />
                )}
              {features &&
                features.feature7 &&
                features.feature7.permission == 1 && (
                  <Route
                    exact
                    path="/edit-discount/:id"
                    component={editDiscount}
                  />
                )}
              {features &&
                features.feature7 &&
                features.feature7.permission == 1 && (
                  <Route exact path="/add-promo" component={addPromo} />
                )}
              {features &&
                features.feature7 &&
                features.feature7.permission == 1 && (
                  <Route exact path="/edit-promo/:id" component={editPromo} />
                )}
              {features &&
                features.feature7 &&
                features.feature7.permission == 1 && (
                  <Route
                    exact
                    path="/add-notification"
                    component={addNotification}
                  />
                )}
              {features &&
                features.feature7 &&
                features.feature7.permission == 1 && (
                  <Route
                    exact
                    path="/edit-notification/:id"
                    component={editNotification}
                  />
                )}
              {features &&
                features.feature8 &&
                features.feature8.permission == 1 && (
                  <Route exact path="/orders" component={orders} />
                )}
              {features &&
                features.feature8 &&
                features.feature8.permission == 1 && (
                  <Route
                    exact
                    path="/outbound_contacts"
                    component={OutBoundContacts}
                  />
                )}
              {features &&
                features.feature9 &&
                features.feature9.permission == 1 && (
                  <Route exact path="/payments" component={payments} />
                )}
              {features &&
                features.feature10 &&
                features.feature10.permission == 1 && (
                  <Route exact path="/bulk" component={Bulk} />
                )}
              {features &&
                features.feature11 &&
                features.feature11.permission == 1 && (
                  <Route exact path="/addressBulk" component={AddressBulk} />
                )}
              {features &&
                features.feature12 &&
                features.feature12.permission == 1 && (
                  <Route exact path="/analytics" component={Reports} />
                )}
              {features &&
                features.feature13 &&
                features.feature13.permission == 1 && (
                  <Route exact path="/drivers" component={Drivers} />
                )}
              {features &&
                features.feature13 &&
                features.feature13.permission == 1 && (
                  <Route exact path="/settings" component={settings} />
                )}
              {features &&
                features.feature13 &&
                features.feature13.permission == 1 && (
                  <Route exact path="/states" component={states} />
                )}
              {features &&
                features.feature13 &&
                features.feature13.permission == 1 && (
                  <Route exact path="/add-state" component={addState} />
                )}
              {features &&
                features.feature13 &&
                features.feature13.permission == 1 && (
                  <Route exact path="/edit-state/:id" component={editState} />
                )}
              {features &&
                features.feature13 &&
                features.feature13.permission == 1 && (
                  <Route exact path="/reasons" component={reasons} />
                )}
              {features &&
                features.feature13 &&
                features.feature13.permission == 1 && (
                  <Route exact path="/add-reason" component={addReason} />
                )}
              {features &&
                features.feature13 &&
                features.feature13.permission == 1 && (
                  <Route exact path="/edit-reason/:id" component={editReason} />
                )}
              {features &&
                features.feature4 &&
                features.feature4.permission == 1 && (
                  <Route
                    exact
                    path="/aggregator-menu-logs"
                    component={fpMenuLogs}
                  />
                )}
              {/* <Route exact path="/ingredients" component={ing} />
             <Route exact path="/recipes" component={recipes} /> */}
              <Route path="*" exact component={Notfound} />
            </Switch>
          )}
        </Router>
      </Provider>
    </div>
  );
}

export default App;
