import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../redux";

class Topbar extends Component<{ logoutUser: () => {} }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  render() {
    return (
      <header className="header">
        <nav className="navbar">
          <div className="container-fluid">
            <div className="navbar-holder d-flex align-items-center justify-content-between">
              {/* <!-- Navbar Header--> */}
              <div className="navbar-header">
                {/* <!-- Navbar Brand --> */}
                <Link
                  to="/dashboard"
                  className="navbar-brand d-none d-sm-inline-block"
                >
                  <div className="brand-text d-none d-lg-inline-block">
                    <span>Simplex</span>
                    <strong>Sync</strong>
                  </div>
                  <div className="brand-text d-none d-sm-inline-block d-lg-none">
                    <strong>Sync</strong>
                  </div>
                </Link>
                {/* <!-- Toggle Button--> */}
                <Link id="toggle-btn" to="#" className="menu-btn active">
                  <span></span>
                  <span></span>
                  <span></span>
                </Link>
              </div>
              {/* <!-- Navbar Menu --> */}
              <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                <li className="nav-item" title="Profile">
                  <Link to="/profile" className="nav-link">
                    <i className="fa fa-user-circle"></i>
                  </Link>
                </li>
                {/* <!-- Logout    --> */}
                <li
                  onClick={() => this.props.logoutUser()}
                  className="nav-item btn"
                >
                  <span className="d-none d-sm-inline mx-1">Logout</span>
                  <i className="fa fa-sign-out"></i>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
  };
};
export default connect(null, mapDispatchToProps)(Topbar);
