import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { EditTradeAreaProps } from "../../interfaces/tradeZone";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import {
  logoutUser,
  storesList,
  tradeZoneList,
  getTradeArea,
  handleTradeAreaInput,
  editTradeArea,
} from "../../redux";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";

class EditTradeArea extends Component<EditTradeAreaProps, {}> {
  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getTradeArea(id);
    this.props.tradeZoneList();
    this.props.storesList();
    document.title = "SimplexYum | TradeAreas";
  }
  isTradeAreaReady = () => {
    const { areaname, tradezone, store } = this.props;
    return areaname !== "" && store !== "" && tradezone !== "";
  };
  handleSubmit = () => {
    let { areaname, tradezone, store } = this.props;
    let id = this.props.match.params.id;
    let data = {
      area_name: areaname,
      delivery_zone_id: tradezone,
      store_id: store,
      is_active: 1,
    };
    this.props.editTradeArea(id, data);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { areaname, tradezones, stores } = this.props;
    return (
      <div className="page">
        <CheckChanges path="/edit-brand" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Trade Areas Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/tradearea" className="text-primary">
                    Trade Areas
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Trade Areas</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        {/* <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong> */}
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Trade Area{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="areaname"
                                type="text"
                                name="areaname"
                                value={areaname}
                                required
                                data-msg="Please enter Area Name"
                                className="input-material"
                                onChange={this.props.handleTradeAreaInput}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                TradeZone
                              </label>
                              <select
                                name="tradezone"
                                className="form-control mt-2"
                                required
                                data-msg="Please Select TradeZone"
                                onChange={this.props.handleTradeAreaInput}
                              >
                                <option value="">Select TradeZone</option>
                                {tradezones &&
                                  tradezones.map((zone, index) => (
                                    <option
                                      key={index}
                                      value={zone.id}
                                      {...(this.props.tradezone === zone.id && {
                                        selected: true,
                                      })}
                                    >
                                      {zone.delivery_zone_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Store
                              </label>
                              <select
                                name="store"
                                className="form-control mt-2"
                                required
                                data-msg="Please Select Store"
                                onChange={this.props.handleTradeAreaInput}
                              >
                                <option value="">Select Store</option>
                                {stores &&
                                  stores.map((store, index) => (
                                    <option
                                      key={index}
                                      value={store.store_id}
                                      {...(this.props.store ===
                                        store.store_id && { selected: true })}
                                    >
                                      {store.store_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                          <button
                            onClick={this.handleSubmit}
                            disabled={!this.isTradeAreaReady()}
                            className="btn btn-primary"
                          >
                            Update TradeZone
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    areaname: state.tradezone.areaname,
    tradezone: state.tradezone.tradezone,
    tradezones: state.tradezone.tradezones,
    store: state.tradezone.store,
    stores: state.store.data,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: () => {
      dispatch(logoutUser());
    },
    handleTradeAreaInput: (event: any) => {
      dispatch(handleTradeAreaInput(event));
    },
    editTradeArea: (id: any, data: any) => {
      dispatch(editTradeArea(id, data));
    },
    getTradeArea: (id: number) => {
      dispatch(getTradeArea(id));
    },
    tradeZoneList: () => {
      dispatch(tradeZoneList());
    },
    storesList: () => {
      dispatch(storesList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTradeArea);
