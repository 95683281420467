import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import { connect } from "react-redux";
import {
  itemsList,
  updateItemAvailability,
  blockunblockitem,
  logoutUser,
  brandsList,
} from "../../../redux";
import { MenuItemProps } from "../../../interfaces/menu";
import moment from "moment";
import { currency } from "../../../client-config";
import { aggregatorsList } from "../../../redux/actions/aggregatorsAction";
import Select from "react-select";
function priceFormatter(cell: any) {
  return `${currency}.${cell}`;
}
class ActionFormatter extends Component<
  { row: any },
  { is_available: any; date: any; menu_item_id: any }
> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      is_available: false,
      date: "",
      menu_item_id: "",
    };
  }
  handleDate = (e: any) => {
    this.setState({ date: e.target.value });
  };
  handleBlockUnblock = (id: any, is_active: any) => {
    let type = this.props.row.aggregator_id
      ? this.props.row.aggregator_id
      : "master";
    this.props.data.blockunblockitem(
      id,
      is_active,
      this.props.row.item_name,
      type,
    );
  };
  handleAvailability = (e: any) => {
    this.setState({ is_available: e.target.value });
  };
  handleConfirm = (menu_id: any) => {
    let newDate = `${this.state.date}:00Z`;
    this.props.data.updateItemAvailability(
      menu_id,
      "product",
      this.state.is_available,
      newDate,
    );
  };
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title={row.is_active === 0 ? "UnBlock" : "Block"}
          data-toggle="modal"
          data-target={`#blockunblock${row.menu_item_id}`}
          className={
            row.is_active === 0
              ? "btn btn-outline-success mr-2"
              : "btn btn-outline-danger mr-2"
          }
        >
          <i
            className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}
          ></i>
        </button>
        {/* <button title="View Details" data-toggle="modal" data-target={`#itemdetails${row.menu_item_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button> */}
        <Link
          title="Edit Item"
          className="btn btn-outline-primary ml-2"
          to={`/edit-item/${row.menu_item_id}/${row.aggregator_id ? row.aggregator_id : "master"}`}
        >
          <i className="fa fa-edit"></i>
        </Link>
        {/* <!-- Modal--> */}
        <div
          id={`itemdetails${row.menu_item_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Item Details
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row mb-2">
                  <div className="col-6">
                    <h4 className="d-inline">Item Name:</h4>
                    <p>{row.item_name}</p>
                  </div>
                  {row.image_url && (
                    <div className="col-6">
                      <img
                        className="rounded-circle"
                        src={row.image_url}
                        alt="menu_item"
                        width="100%"
                        height="150px"
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group text-capitalize">
                      <h4>Variant</h4>
                      <BootstrapTable
                        data={row.item_size !== "" && JSON.parse(row.item_size)}
                        hover
                      >
                        <TableHeaderColumn dataField="size" columnTitle isKey>
                          Size
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="cost"
                          dataFormat={priceFormatter}
                          columnTitle
                        >
                          Item Cost Price
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn dataField='price' dataFormat={priceFormatter} columnTitle>Item Sale Price</TableHeaderColumn> */}
                        <TableHeaderColumn
                          dataField="mrp"
                          dataFormat={priceFormatter}
                          columnTitle
                        >
                          Max Retail Price
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Block/Unblock Modal--> */}
        <div
          id={`blockunblock${row.menu_item_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  {row.is_active === 0 ? "UnBlock" : "Block"} Menu Item
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure,you want to{" "}
                  {row.is_active === 0 ? "UnBlock" : "Block"} this item?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  onClick={() =>
                    this.handleBlockUnblock(row.menu_item_id, row.is_active)
                  }
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id={`fp_store_status${row.store_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Hungerstation Item Availability
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-12">
                  <div className="form-group">
                    <select
                      name="fp_store_status"
                      value={this.state.is_available}
                      onChange={this.handleAvailability}
                      className="form-control mt-2"
                    >
                      <option value="">Select Status</option>
                      <option value={"true"}>{"AVAILABLE"}</option>
                      <option value={"false"}>{"UNAVAILABLE"}</option>
                    </select>
                  </div>
                  {this.state.is_available == "false" ? (
                    <>
                      <span>Will Be Avaiable From</span>
                      <input
                        value={this.state.date}
                        onChange={this.handleDate}
                        id="storeopen"
                        type="datetime-local"
                        name="storeopen"
                        required
                        data-msg="Please enter Opening Time"
                        className="input-material"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                  onClick={() => this.handleConfirm(row.menu_item_id)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}
function orderModesFormatter(cell: any, row: any, props: any) {
  return <OrderModesFormatter row={row} data={props} />;
}
class OrderModesFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  IsJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  render() {
    const { row } = this.props;
    return (
      <div>
        {row.item_mode && (
          <button
            title="View Modes"
            data-toggle="modal"
            data-target={`#itemmodes${row.menu_item_id}`}
            className="btn btn-outline-info"
          >
            <i className="fa fa-list"></i>
          </button>
        )}
        {/* <!-- Modal--> */}
        <div
          id={`itemmodes${row.menu_item_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Order Modes
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group text-capitalize">
                      <BootstrapTable
                        version="4"
                        data={
                          row.item_mode && this.IsJsonString(row.item_mode)
                            ? JSON.parse(row.item_mode)
                            : ""
                        }
                        hover
                      >
                        <TableHeaderColumn dataField="label" columnTitle isKey>
                          Order Mode
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class MenuItem extends Component<
  MenuItemProps,
  { menu_type: any; brand_id: any; is_available: any }
> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      menu_type: "",
      brand_id: null,
      is_available: false,
    };
  }
  componentDidMount() {
    this.props.aggregatorsList();
    this.props.brandsList();
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let role_id: any = decoded.role_id;
    if (role_id != 7) {
      this.setState({ brand_id: decoded.brand_id });
    }
    document.title = "SimpleXSync | Menu Items";
  }

  handleMenuType = (e: any) => {
    this.setState({ menu_type: e.target.value });
    if (this.state.brand_id) {
      this.props.itemsList(e.target.value, this.state.brand_id);
    }
  };

  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
    this.props.itemsList(this.state.menu_type, e.value);
  };

  handleSubmit = () => {
    this.props.itemsList(this.state.menu_type);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    const options: any = {
      // sizePerPageList: [5,10,15],
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      insertModal: () => {
        return <Redirect to="/add-item" />;
      },
      noDataText: "Menu Items Not Found",
    };
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let role_id: any = decoded.role_id;
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Items Management</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-1">
                    <label className="form-control-label">
                      Menu Type <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-11">
                    <div className="form-group">
                      <select
                        name="menutype"
                        className="form-control text-capitalize mt-2"
                        required
                        data-msg="Please select Menu"
                        onChange={this.handleMenuType}
                      >
                        <option value="">Select Menu Type</option>
                        <option value="master">Master Menu</option>
                        {/* <option value="aggregator">Aggregator Menu</option> */}
                        {this.props.aggregators.map((data: any) => {
                          return (
                            <option value={data.id}>
                              {data.aggregator_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-3">
                                        <br />
                                        <button onClick={this.handleSubmit} className="btn btn-primary mt-3">Submit</button>
                                    </div> */}
                </div>
                {role_id == 7 && (
                  <div className="row">
                    <div className="col-1">
                      <label className="form-control-label">
                        Select Brand <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-11">
                      <div className="form-group">
                        <Select
                          name="brands"
                          options={selectedBrands}
                          className="text-capitalize basic-multi-select mt-2"
                          classNamePrefix="select"
                          // value={{label:this.state.brand_name,value:this.state.brand_id}}
                          onChange={(e: any, i: any) =>
                            this.handleBrandsInputChange(e, i)
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          version="4"
                          data={this.props.data}
                          search={true}
                          pagination={this.props.data.length > 10 && true}
                          options={options}
                          exportCSV={true}
                          insertRow
                          csvFileName="menus.csv"
                          hover
                        >
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="menu_item_id"
                            csvHeader="#"
                            width="70"
                            dataSort={true}
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataField='erp_id' csvHeader='ERP ID' width='100' columnTitle>ERP ID</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='pos_code' csvHeader='POS Code' width='100' columnTitle>POS Code</TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="item_name"
                            csvHeader="Item Name"
                            width="200"
                            columnTitle
                          >
                            Item name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="erp_id"
                            csvHeader="Erp Id"
                            width="200"
                            columnTitle
                          >
                            Erp Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="group_name"
                            csvHeader="Group Name"
                            width="150"
                            columnTitle
                          >
                            Group Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="sub_group_name"
                            csvHeader="Sub Group Name"
                            width="150"
                            columnTitle
                          >
                            Sub Group Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="menu_name"
                            csvHeader="Menu Name"
                            width="130"
                            columnTitle
                          >
                            Menu
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='item_mode' csvHeader='Mode' width='100' dataFormat={orderModesFormatter} columnTitle>Mode</TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="priority"
                            csvHeader="Priority"
                            width="100"
                            columnTitle
                          >
                            Priority
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="item_start_time"
                            csvHeader="Item Start Time"
                            dataFormat={timeFormatter}
                            width="150"
                          >
                            Item Start Time
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="item_close_time"
                            csvHeader="Item Close Time"
                            dataFormat={timeFormatter}
                            width="150"
                          >
                            Item Close Time
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="hero_item"
                            width="100"
                            dataFormat={heroItemFormatter}
                            export={false}
                          >
                            HeroItem
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="action"
                            width="180"
                            dataFormat={actionFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Action
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
function heroItemFormatter(cell: any) {
  return (
    <div className="text-capitalize">
      <span {...(cell == 1 && { className: "badge badge-success p-2" })}>
        {cell == 1 && <i className="fa fa-check-square-o"></i>}
      </span>
    </div>
  );
}
function timeFormatter(cell: any) {
  return (
    <div>
      <span
        {...(cell !== "" &&
          cell !== null && {
            title: moment(cell).local().format("YYYY-MM-DD HH:mm"),
          })}
      >
        {" "}
        {cell !== "" &&
          cell !== null &&
          moment(cell).local().format("YYYY-MM-DD HH:mm")}{" "}
      </span>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    data: state.menu.items,
    aggregators: state.aggregator.aggregators,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    itemsList: function (type: any, brand_id: any) {
      dispatch(itemsList(type, brand_id));
    },
    blockunblockitem: function (
      id: any,
      is_active: any,
      itemName: any,
      type: any,
    ) {
      dispatch(blockunblockitem(id, is_active, itemName, type));
    },
    updateItemAvailability: function (
      id: any,
      type: any,
      availability: any,
      date: any,
    ) {
      dispatch(updateItemAvailability(id, type, availability, date, "sd"));
    },
    aggregatorsList: () => {
      dispatch(aggregatorsList());
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
