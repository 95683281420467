import React, { Component } from "react";
import { AddGroupProps, AddGroupState } from "../../../interfaces/menu";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import {
  addGroup,
  logoutUser,
  storesListForMultiSelect,
  brandsList,
} from "../../../redux";
import { Link, Redirect } from "react-router-dom";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import CheckChanges from "../../../components/confirmOnLeave";
import Select from "react-select";
import { menusOptions } from "../../../redux/actions/menuAction";
import * as uuid from "uuid";
const ShortUniqueId = require("short-unique-id");
const uid = new ShortUniqueId();
class AddGroup extends Component<AddGroupProps, AddGroupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      groupname: "",
      menu: "",
      name_ar:"",
      brand_id: "",
      image: "",
      priority: "",
      erp_id: "",
      poscode: null,
      erpid: null,
      is_lsm: "0",
      storesJson: [],
      disabled: false,
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    this.props.brandsList();
    // this.props.menusList();
    document.title = "SimpleXSync | Groups";
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  blockInvalidChar = (e: any) => {
    var regex = new RegExp("^[a-zA-Z0-9]");
    let specialkey: any = e.keyCode;
    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault();
          }
        }
      } else {
        (!regex.test(key) || specialkey == 32) && e.preventDefault();
      }
    }
  };
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ storesJson: e });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  handleChangeRad = (event: { target: { name: any; value: any } }) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  fileSelectedHandler = (e: any) => {
    this.setState({ image: e.target.files });
  };
  isGroupReady = () => {
    const { groupname, menu, image, is_lsm, storesJson, erp_id, disabled } =
      this.state;
    if (is_lsm == "0") {
      return (
        groupname !== "" &&
        menu !== "" &&
        erp_id != "" &&
        disabled != true
      );
    } else if (is_lsm == "1") {
      return (
        groupname !== "" &&
        menu !== "" &&
        storesJson.length > 0 &&
        erp_id != "" &&
        disabled != true
      );
    }
  };

  handleState = (value: any) => {
    this.setState({ disabled: value });
  };
  handleSaveBtnClick = () => {
    this.setState({ disabled: true });
    let {
      groupname,
      menu,
      image,
      brand_id,
      priority,
      poscode,
      erpid,
      is_lsm,
      storesJson,
      name_ar,
      erp_id,
    } = this.state;
    const data: any = new FormData();
    data.append("file", image[0]);
    data.append("group_name", groupname);
    data.append("brand_id", brand_id);
    data.append("menu_id", menu);
    data.append("erp_id", erp_id);
    data.append("name_ar",name_ar)
    if (is_lsm == "0") {
      data.append("is_lsm", 0);
    } else if (is_lsm == "1") {
      data.append("is_lsm", 1);
      data.append("stores_json", JSON.stringify(storesJson));
    }
    data.append("priority", priority);
    this.props.addGroup(data, this.handleState);
  };
  handleErpChange = (value: any) => {
    this.setState({ erp_id: value + new Date().getTime() });
  };

  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
    this.props.menusList(e.value);
    this.props.storesList(e.value);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let role_id: any = decoded.role_id;
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    const { menus } = this.props;
    const { is_lsm, storesJson } = this.state;
    return (
      <div className="page">
        <CheckChanges path="/add-group" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Groups Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/groups" className="text-primary">
                    Groups
                  </Link>
                </li>
                <li className="breadcrumb-item active">Add Group</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Group name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="groupname"
                                type="text"
                                name="groupname"
                                onKeyDown={this.blockInvalidChar}
                                required
                                data-msg="Please enter Menu Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 col-sm-5 col-5">
                            <div className="form-group">
                              <label className="form-control-label mb-3">
                                Erp Id <span className="text-danger">*</span>
                              </label>
                              <input
                                
                                value={this.state.erp_id}
                                name="erp_id"
                                type="text"
                                data-msg="Please enter erp"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                              <label className="form-control-label">
                                Arabic Group Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="name_ar"
                                type="text"
                                name="name_ar"
                                value={this.state.name_ar}
                                required
                                data-msg="Please enter Arabic Item Name"
                                className="input-material"
                                onChange={(e) => {
                                
                                  this.handleInputChange(e);
                                }}
                              />
                            </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="form-control-label">
                              Select Brand{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-group">
                              <Select
                                name="brands"
                                options={selectedBrands}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                // value={{label:this.state.brand_name,value:this.state.brand_id}}
                                onChange={(e, i) =>
                                  this.handleBrandsInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Menu <span className="text-danger">*</span>
                              </label>
                              <select
                                name="menu"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select Menu"
                                onChange={this.handleInputChange}
                              >
                                <option>Select Menu</option>
                                {menus &&
                                  menus.map((menu, index) => (
                                    <option key={index} value={menu.menu_id}>
                                      {menu.menu_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            {/* <div className="form-group">
                              <label className="form-control-label mb-3">
                                Group Image{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="file"
                                type="file"
                                name="file"
                                className="form-control-file"
                                onChange={this.fileSelectedHandler}
                              />
                            </div> */}
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Priority
                              </label>
                              <input
                                id="priority"
                                type="number"
                                name="priority"
                                onKeyDown={this.blockInvalidChar}
                                required
                                data-msg="Please enter Priority"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-sm-6 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom3"
                                type="radio"
                                name="is_lsm"
                                value="0"
                                checked={is_lsm == "0"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom3">
                                Available for all stores
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-6 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom4"
                                type="radio"
                                name="is_lsm"
                                value="1"
                                checked={is_lsm == "1"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom4">
                                Available for specific stores
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {is_lsm == "1" && (
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Select Store{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  isMulti
                                  defaultValue={storesJson}
                                  name="stores"
                                  options={this.props.stores}
                                  className="text-capitalize basic-multi-select mt-2"
                                  classNamePrefix="select"
                                  onChange={(e, i) =>
                                    this.handleStoresInputChange(e, i)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isGroupReady()}
                            onClick={this.handleSaveBtnClick}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    menus: state.menu.menus,
    stores: state.menu.storesoptions,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    addGroup: function (data: any, handleState: any) {
      dispatch(addGroup(data, handleState));
    },
    menusList: function (brand_id: any) {
      dispatch(menusOptions(brand_id));
    },
    storesList: (brand_id: any) => {
      dispatch(storesListForMultiSelect(brand_id));
    },
    logoutUser: function () {
      dispatch(logoutUser());
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
