import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { EditReasonProps, EditReasonState } from "../../interfaces/settings";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { logoutUser } from "../../redux";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import { editReason, getReason } from "../../redux/actions/settingsAction";

class EditRole extends Component<EditReasonProps, EditReasonState> {
  constructor(props: any) {
    super(props);
    this.state = {
      reason: "",
      type: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.getReason(id);
    document.title = "SimpleXSync | Reasons";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      reason: nextProps.reasonData.reason,
      type: nextProps.reasonData.type,
    });
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  isReady = () => {
    const { reason, type } = this.state;
    return reason !== "" && type !== "";
  };
  handleSubmit = (event: any) => {
    event.preventDefault();
    let { reason, type } = this.state;
    let id = this.props.match.params.id;
    let data = {
      reason: reason,
      type: type,
    };
    this.props.editReason(id, data);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { reasonData } = this.props;
    return (
      <div className="page">
        <CheckChanges path="/edit-reason" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Reasons Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/reasons" className="text-primary">
                    Reasons
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Reason</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <form method="post" className="form-validate">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-label ">
                                  Reason <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="reason"
                                  type="text"
                                  name="reason"
                                  defaultValue={reasonData.reason}
                                  required
                                  data-msg="Please enter role name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-lable">
                                  Type <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="type"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Type"
                                  onChange={this.handleInputChange}
                                >
                                  <option
                                    value="cancel order"
                                    {...(reasonData.type == "cancel order" && {
                                      selected: true,
                                    })}
                                  >
                                    Cancel Order
                                  </option>
                                  <option
                                    value="complaint"
                                    {...(reasonData.type == "complaint" && {
                                      selected: true,
                                    })}
                                  >
                                    Complaint
                                  </option>
                                  <option
                                    value="menu"
                                    {...(reasonData.type == "menu" && {
                                      selected: true,
                                    })}
                                  >
                                    Menu
                                  </option>
                                  <option
                                    value="store"
                                    {...(reasonData.type == "store" && {
                                      selected: true,
                                    })}
                                  >
                                    Store
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="form-group float-right">
                            <button
                              onClick={this.handleSubmit}
                              disabled={!this.isReady()}
                              className="btn btn-primary"
                            >
                              Update Reason
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    reasonData: state.setting.reasonsData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editReason: function (id: any, data: any) {
      dispatch(editReason(id, data));
    },
    getReason: function (id: number) {
      dispatch(getReason(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditRole);
