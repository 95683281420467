import Api from "../../components/Api";
import { AGGREGATOR, AGGREGATORS } from "./aggregatorsType";

export const aggregatorsList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/aggregators", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: AGGREGATORS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const getAggregator = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/aggregator/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: AGGREGATOR,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const addAggregator = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/add_aggregator", data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/aggregators";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const editAggregator = (id: number, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.put("/admin/aggregator/" + id, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/aggregators";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const blockUnblockAggregator = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.put(
      "/admin/block-unblock-aggregator/" + id,
      {},
      {
        headers: { Authorization: "Bearer " + token },
      },
    )
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/aggregators";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
