import {
  CANCEL_ORDER_REASONS,
  DELIVERY_BOYS,
  FILTERED_ORDERS,
  ORDERS_LIST,
  ORDER_ITEMS_LIST,
  ORDER_STATUS_LIST,
  OUTBOUND_CONTACTS,
  TIMER_STATUS,
  UPDATE_ORDER,
  UPDATE_LOADER,
  UPDATE_TIMER,
  MANUAL_SYNC_ORDER,
} from "../actions/orderType";
const initialState: any = {
  data: [] || "",
  filteredOrders: [] || "",
  orderItems: [] || "",
  order: {},
  orderStatus: [] || "",
  deliveryBoys: [] || "",
  outboundContacts: [] || "",
  buttonDisable: false,
  timerStatus: true,
  kitchenCount: 0,
  recievedOrderCount: 0,
  readyOrderCount: 0,
  completedOrderCount: 0,
  failedIntegOrderCount: 0,
  avg: 0,
  cancelReasons: [] || "",
  dayVal: {},
  emptyFilterOrder: false,
  isLoading: false,
  isTimerUpdate: false,
  clickmenuSync: false,
};

const orderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ORDERS_LIST:
      return {
        ...state,
        data: action.payload,
        avg: action.avg ? action.avg : 0,
        dayVal: action.dayVal,
        kitchenCount: action.kitchenCount,
        recievedOrderCount: action.recievedOrderCount,
        readyOrderCount: action.readyOrderCount,
        completedOrderCount: action.completedOrderCount,
        failedIntegOrderCount: action.failedIntegOrderCount,
        isLoading: action.isLoading,
        isTimerUpdate: action.isTimer,
      };
    case ORDER_ITEMS_LIST:
      return {
        ...state,
        orderItems: action.payload,
        order: action.orderDetail,
      };
    case ORDER_STATUS_LIST:
      return {
        ...state,
        orderStatus: action.payload,
      };
    case DELIVERY_BOYS:
      return {
        ...state,
        deliveryBoys: action.payload,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        buttonDisable: action.payload,
      };
    case OUTBOUND_CONTACTS:
      return {
        ...state,
        outboundContacts: action.payload,
      };
    case CANCEL_ORDER_REASONS:
      return {
        ...state,
        cancelReasons: action.payload,
      };
    case FILTERED_ORDERS:
      return {
        ...state,
        filteredOrders: action.payload,
        emptyFilterOrder: action.emptyFilterOrder,
      };
    case TIMER_STATUS:
      return {
        ...state,
        timerStatus: action.payload == false ? false : true,
      };
    case UPDATE_LOADER:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case UPDATE_TIMER:
      return {
        ...state,
        isTimerUpdate: action.isTimerUpdate,
      };
    case MANUAL_SYNC_ORDER:
      return {
        ...state,
        clickmenuSync: action.payload,
      };
    default:
      return state;
  }
};
export default orderReducer;
