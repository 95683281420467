import React, { Component } from "react";
import { AddUserProps, AddUserState } from "../../interfaces/settings";
import { connect } from "react-redux";
import {
  addUser,
  rolesList,
  countryList,
  storesList,
  logoutUser,
  userGroupsList,
  brandsList,
} from "../../redux";
import { Link, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import Select from "react-select";
import { userGroupsListByBrand } from "../../redux/actions/settingsAction";

class AddUser extends Component<AddUserProps, AddUserState> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstname: "",
      brand_id: "",
      lastname: "",
      email: "",
      password: "",
      city: "",
      country: "",
      address: "",
      roleId: "",
      storeId: "",
      groupId: "",
      isValidEmail: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }
  componentDidMount() {
    document.title = "SimpleXSync | Users";
    this.props.rolesList();
    this.props.countryList();
    this.props.storesList();
    this.props.brandsList();
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    if (event.target.name == "groupId") {
      if (this.state.roleId == 3 || this.state.roleId == 5) {
        let userGroup =
          this.props.userGroups &&
          this.props.userGroups.find((obj: any) => {
            return event.target.value == obj.id;
          });
        if (userGroup.group_store_id.length > 1) {
          alert(
            "Please select the group for this role that has only one store.",
          );
          this.setState({
            [event.target.name]: "",
          });
        } else {
          this.setState({
            [event.target.name]: event.target.value,
          });
        }
      } else {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }
  isUserReady = () => {
    let {
      firstname,
      email,
      password,
      country,
      address,
      roleId,
      groupId,
      isValidEmail,
      brand_id,
    } = this.state;
    return (
      firstname !== "" &&
      email !== "" &&
      password !== "" &&
      country !== "" &&
      address !== "" &&
      roleId !== "" &&
      groupId !== "" &&
      isValidEmail === true &&
      brand_id != ""
    );
    // if (roleId == 3 || roleId == 5 || roleId == 6) {
    //     return (firstname !== "" && email !== "" && password !== "" && country !== "" && address !== "" && groupId !== "" && storeId !== "" && isValidEmail === true);
    // } else {
    //     return (firstname !== "" && email !== "" && password !== "" && country !== "" && address !== "" && roleId !== "" && isValidEmail === true);
    // }
  };
  validateEmail(event: { target: { name: any; value: any } }) {
    let reg = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,4}$/;
    if (reg.test(event.target.value)) {
      this.setState({ email: event.target.value, isValidEmail: true });
    } else {
      this.setState({ email: event.target.value, isValidEmail: false });
    }
  }
  handleSubmit = (event: any) => {
    let {
      firstname,
      lastname,
      email,
      password,
      city,
      country,
      brand_id,
      address,
      roleId,
      groupId,
    } = this.state;
    let data: any = {
      first_name: firstname,
      last_name: lastname,
      user_name: lastname === "" ? firstname : firstname + " " + lastname,
      email_address: email,
      password: password,
      brand_id,
      city: city,
      country_id: country,
      address: address,
      role_id: roleId,
      user_group_id: groupId,
      status: 1,
    };
    if (this.state.roleId == 3 || this.state.roleId == 5) {
      let userGroup =
        this.props.userGroups &&
        this.props.userGroups.find((obj: any) => {
          return this.state.groupId == obj.id;
        });
      if (userGroup.group_store_id.length > 1) {
        alert("Please select the group for this role that has only one store.");
        this.setState({
          groupId: "",
        });
      } else {
        this.props.addUser(data);
      }
    } else {
      this.props.addUser(data);
    }
    // if (roleId == 3 || roleId == 5 || roleId == 6) {
    //     data.store_id = storeId;
    // }
  };
  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
    this.props.userGroupsList(e.value);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    const { roles, countries, userGroups } = this.props;
    let { isValidEmail, email } = this.state;
    let tokendata: any = jwt.decode(sessionStorage.token);
    let roleId = tokendata.role_id;
    return (
      <div className="page">
        <CheckChanges path="/add-user" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Users Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/users" className="text-primary">
                    Users
                  </Link>
                </li>
                <li className="breadcrumb-item active">Add User</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-lable">
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="firstname"
                                type="text"
                                name="firstname"
                                value={this.state.firstname}
                                required
                                data-msg="Please enter first name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-lable">
                                Last Name
                              </label>
                              <input
                                id="lastname"
                                type="text"
                                name="lastname"
                                value={this.state.lastname}
                                required
                                data-msg="Please enter last name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-lable">
                                Email <span className="text-danger">*</span>
                              </label>
                              <input
                                id="email"
                                type="email"
                                name="email"
                                required
                                data-msg="Please enter email"
                                className="input-material"
                                onChange={this.validateEmail}
                              />
                              {isValidEmail == false && email !== "" && (
                                <small className="form-text text-danger">
                                  email is not correct
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-lable">
                                Password <span className="text-danger">*</span>
                              </label>
                              <input
                                id="password"
                                type="password"
                                name="password"
                                value={this.state.password}
                                required
                                data-msg="Please enter Password"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-lable">City</label>
                              <input
                                id="city"
                                type="text"
                                name="city"
                                value={this.state.city}
                                required
                                data-msg="Please enter city"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Country <span className="text-danger">*</span>
                              </label>
                              <select
                                name="country"
                                className="form-control mt-2"
                                required
                                data-msg="Please enter Country"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Country</option>
                                {countries &&
                                  countries.map((country: any, index: any) => (
                                    <option
                                      key={index}
                                      value={country.country_id}
                                    >
                                      {country.country_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-lable">
                                Address <span className="text-danger">*</span>
                              </label>
                              <input
                                id="address"
                                type="text"
                                name="address"
                                value={this.state.address}
                                required
                                data-msg="Please enter address"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Role <span className="text-danger">*</span>
                              </label>
                              <select
                                name="roleId"
                                className="form-control mt-2"
                                value={this.state.roleId}
                                required
                                data-msg="Please Select Role"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Role</option>
                                {roles &&
                                  roles.map((role, index) =>
                                    roleId !== 7 ? (
                                      role.role_id !== 7 &&
                                      role.role_id !== 1 && (
                                        <option
                                          key={index}
                                          value={role.role_id}
                                        >
                                          {role.role_name}
                                        </option>
                                      )
                                    ) : (
                                      <option key={index} value={role.role_id}>
                                        {role.role_name}
                                      </option>
                                    ),
                                  )}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Select Brand{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                name="brands"
                                options={selectedBrands}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleBrandsInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Group <span className="text-danger">*</span>
                              </label>
                              <select
                                name="groupId"
                                className="form-control mt-2"
                                value={this.state.groupId}
                                required
                                data-msg="Please Select Group"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Group</option>
                                {userGroups &&
                                  userGroups.map((group, index) => (
                                    <option key={index} value={group.id}>
                                      {group.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          {/* {
                                                        (this.state.roleId == 3 || this.state.roleId == 5 || this.state.roleId == 6) && <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Store</label>
                                                                <select name="storeId" className="form-control mt-2" required data-msg="Please Select Role" onChange={this.handleInputChange}>
                                                                    <option value="">Select Store</option>
                                                                    {stores &&
                                                                        stores.map((store, index) => (
                                                                            <option key={index} value={store.store_id}>{store.store_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    } */}
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isUserReady()}
                            onClick={this.handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    roles: state.setting.roles,
    countries: state.store.countries,
    stores: state.store.data,
    userGroups: state.setting.userGroups,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addUser: function (data: any) {
      dispatch(addUser(data));
    },
    rolesList: function () {
      dispatch(rolesList());
    },
    countryList: function () {
      dispatch(countryList());
    },
    storesList: function () {
      dispatch(storesList());
    },
    userGroupsList: function (brand_id: any) {
      dispatch(userGroupsListByBrand(brand_id));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
