import {
  RECENT_PAYMENTS,
  DOUGNUT_CHART_CHANNEL,
  PIE_CHART_SOS,
  LINE_CHART,
  RECENT_ORDERS,
  RECENT_ITEMS,
  HOME_COUNTER,
  MONTHLY_ORDERS,
  MONTHLY_SALES,
  LEADER_BOARD,
  TOP_SELLING_ITEMS,
} from "./homeType";
import Api from "../../components/Api";

export const homeCounter = (days: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/home_counter", days, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: HOME_COUNTER,
            payload: response.data.successResponse[0],
          });
          //console.log("In action: ", response.data.successResponse[0]);
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const LineChartData = (brand_id?: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let store = {
      store_id: 0,
      brand_id,
    };
    Api.post("/admin/last_seven_days_linechart", store, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: LINE_CHART,
            payload: response.data.successResponse,
          });
          //console.log("In action: ", response.data.successResponse[0]);
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const PieChartSOS = (brand_id?: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let store = {
      store_id: 0,
      brand_id,
    };
    Api.post("/admin/last_seven_days_piechart_sos", store, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: PIE_CHART_SOS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const DougnutChartChannel = (brand_id?: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let store = {
      store_id: 0,
      brand_id,
    };
    Api.post("/admin/last_seven_days_dougnutchart_channel", store, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: DOUGNUT_CHART_CHANNEL,
            payload: response.data.successResponse[0],
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const monthlyOrdersForLine = (days: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/monthly_orders", days, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MONTHLY_ORDERS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const monthlySalesForBar = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/monthly_sales", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MONTHLY_SALES,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const recentPayments = (days: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/recent_payments", days, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: RECENT_PAYMENTS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const recentOrders = (days: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/recent_orders", days, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: RECENT_ORDERS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const recentItems = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/recent_menu_items", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: RECENT_ITEMS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const leaderBoardForGraph = (days: any) => {
  return function (dispatch: any) {
    dispatch({
      type: LEADER_BOARD,
      payload: [],
    });
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/leader_board", days, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: LEADER_BOARD,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const TopItemsList = () => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");

    Api.get("/menu/topItemsForHome", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: TOP_SELLING_ITEMS,
            payload: response.data.successResponse,
          });
          // console.log("success combo");
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
          // console.log("ok Combo");
        }
      });
  };
};
