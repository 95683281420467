import React, { Component } from "react";
import { AddModGroupProps, AddModGroupState } from "../../../interfaces/menu";
import { connect } from "react-redux";
import {
  addModGroup,
  menusList,
  logoutUser,
  groupsListForMultiSelect,
  itemsListForMultiSelect,
  brandsList,
} from "../../../redux";
import { Link, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import CheckChanges from "../../../components/confirmOnLeave";
import Select from "react-select";
import { groupsOptions } from "../../../redux/actions/menuAction";
const ShortUniqueId = require("short-unique-id");
const uid = new ShortUniqueId();
class AddModGroup extends Component<AddModGroupProps, AddModGroupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      groupJson: [],
      itemsJson: [],
      name_ar:"",
      disabled: false,
      poscode: null,
      erp_id: null,
      modgroupname: "",
      modgroupdesc: "",
      level: "group",
      type: "single",
      brand_id: "",
      priority: "",
      min_number: null,
      max_number: null,
      max_modifier_qunatity_selection: null,
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
  }
  componentDidMount() {
    this.props.brandsList();
    document.title = "SimpleXSync | Modifier Groups";
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleErpChange = (value: any) => {
    this.setState({ erp_id: value + new Date().getTime() });
  };
  handleModifiersLevel(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleGroupsInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ itemsJson: [], groupJson: e });
    }
  };
  handleItemsInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ groupJson: [], itemsJson: e });
    }
  };
  isModGroupReady = () => {
    const {
      modgroupname,
      groupJson,
      itemsJson,
      type,
      level,
      priority,
      min_number,
      max_number,
      max_modifier_qunatity_selection,
      erp_id,
      disabled,
    } = this.state;
    return (
      modgroupname !== "" &&
      (level == "group" ? groupJson.length > 0 : itemsJson.length > 0) &&
      type != "" &&
      level != "" &&
      erp_id != "" &&
      disabled != true
    );
  };
  handleState = (value: any) => {
    this.setState({ disabled: value });
  };
  handleSaveBtnClick = (event: any) => {
    this.setState({ disabled: true });
    let {
      groupJson,
      itemsJson,
      brand_id,
      modgroupname,
      max_modifier_qunatity_selection,
      min_number,
      max_number,
      erp_id,
      modgroupdesc,
      poscode,
      type,
      level,
      priority,
      name_ar
    } = this.state;
    let data: any = {
      mod_group_name: modgroupname,
      mod_group_description: modgroupdesc,
      type: level,
      modifiers_type: type,
      priority: priority,
      min_number,
      max_number,
      max_modifier_qunatity_selection,
      erp_id,
      brand_id,
      name_ar
    };
    if (level == "group") {
      data["group_json"] = JSON.stringify(groupJson);
    } else if (level == "item") {
      data["items_json"] = JSON.stringify(itemsJson);
    }
    this.props.addModGroup(data, this.handleState);
  };
  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
    this.props.groupsList(e.value);
    this.props.itemsListForMultiSelect("master", e.value);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isInserted, message, groups, items } = this.props;
    if (!isInserted) {
      msg = message;
      messcolor = "red";
    }
    let finalGroups: any = [];
    groups.map((info: any) => {
      finalGroups.push({ label: info.group_name, value: info.group_id });
    });

    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let role_id: any = decoded.role_id;
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    return (
      <div className="page">
        <CheckChanges path="/add-modgroup" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Modifier Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/mod-groups" className="text-primary">
                    Modifier Groups
                  </Link>
                </li>
                <li className="breadcrumb-item active">Add Modifier Group</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <div className="row">
                          <div className="col-lg-6 col-sm-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Mod Group name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="modgroupname"
                                type="text"
                                name="modgroupname"
                                required
                                data-msg="Please enter Modifier Group Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>

                            <div className="form-group">
                                <label className="form-control-label">
                                  Arabic Modifier Group Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="name_ar"
                                  type="text"
                                  name="name_ar"
                                  //defaultValue={modGroupData.name_ar}
                                  required
                                  data-msg="Please enter Arabic Modifier Name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                          </div>
                          <div className="col-lg-5 col-sm-5 col-5">
                            <div className="form-group">
                              <label className="form-control-label mb-3">
                                Erp Id <span className="text-danger">*</span>
                              </label>
                              <input
                                value={this.state.erp_id}
                                name="erp_id"
                                type="text"
                                data-msg="Please enter erp"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="form-control-label">
                              Select Brand{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-group">
                              <Select
                                name="brands"
                                options={selectedBrands}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                // value={{label:this.state.brand_name,value:this.state.brand_id}}
                                onChange={(e, i) =>
                                  this.handleBrandsInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Modifiers Level{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name="level"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select Type"
                                onChange={this.handleModifiersLevel}
                              >
                                <option value="group">Group</option>
                                <option value="item">Menu Item</option>
                              </select>
                            </div>
                          </div>

                          {this.state.level === "group" && (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Group <span className="text-danger">*</span>
                                </label>
                                <Select
                                  isMulti
                                  name="groups"
                                  options={groups}
                                  className="text-capitalize basic-multi-select mt-2"
                                  classNamePrefix="select"
                                  onChange={(e, i) =>
                                    this.handleGroupsInputChange(e, i)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {this.state.level === "item" && (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Select Items{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  isMulti
                                  name="items"
                                  options={items}
                                  className="text-capitalize basic-multi-select mt-2"
                                  classNamePrefix="select"
                                  onChange={(e, i) =>
                                    this.handleItemsInputChange(e, i)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Min Number
                              </label>
                              <input
                                id="min_number"
                                type="text"
                                name="min_number"
                                defaultValue={this.state.min_number}
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Max Number
                              </label>
                              <input
                                id="max_number"
                                type="text"
                                name="max_number"
                                defaultValue={this.state.max_number}
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>

                          {/* <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Max Modifier Quantity
                              </label>
                              <input
                                id="max_modifier_qunatity_selection"
                                type="text"
                                name="max_modifier_qunatity_selection"
                                defaultValue={
                                  this.state.max_modifier_qunatity_selection
                                }
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div> */}
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Description
                              </label>
                              <input
                                id="modgroupdesc"
                                type="text"
                                name="modgroupdesc"
                                required
                                data-msg="Please enter Group Description"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="groups"
                                                                options={groups}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Modifier Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name="type"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select Type"
                                onChange={this.handleInputChange}
                              >
                                <option value="single">Single Select</option>
                                <option value="multiple">Multi Select</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Priority
                              </label>
                              <input
                                id="priority"
                                type="number"
                                name="priority"
                                required
                                data-msg="Please enter Priority"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isModGroupReady()}
                            onClick={this.handleSaveBtnClick}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    menus: state.menu.menus,
    groups: state.menu.groupsptions,
    items: state.menu.allActiveItems,
    message: state.menu.message,
    isInserted: state.menu.isInserted,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addModGroup: function (data: any, handleState: any) {
      dispatch(addModGroup(data, handleState));
    },
    menusList: function () {
      dispatch(menusList());
    },
    groupsList: function (brand_id: any) {
      dispatch(groupsOptions(brand_id));
    },
    itemsListForMultiSelect: function (type: any, brand_id: any) {
      dispatch(itemsListForMultiSelect(type, brand_id));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };  
};
export default connect(mapStateToProps, mapDispatchToProps)(AddModGroup);
