import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../redux";
import { handleCityInput, addCity, countryList } from "../../redux";

import { AddBrandProps } from "../../interfaces/brands";
import { Link, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import CheckChanges from "../../components/confirmOnLeave";
import { AddCityZoneProps } from "../../interfaces/city";
class AddCity extends Component<AddCityZoneProps, { countryId: any }> {
  constructor(props: any) {
    super(props);
    this.state = {
      countryId: null,
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    document.title = "SimpleXSync | Cities";
    this.props.countryList();
  }
  isCityReady = () => {
    const { cityname } = this.props;
    console.log("this.state.countryId", this.state.countryId);

    if (this.state.countryId == "" || this.state.countryId == null) {
      return false;
    }
    return cityname !== "";
  };
  blockInvalidChar = (e: any) => {
    var regex = new RegExp("[a-zA-Z]");
    let specialkey: any = e.keyCode;
    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault();
          }
        }
      } else {
        (!regex.test(key) || specialkey == 32) && e.preventDefault();
      }
    }
  };
  handleSaveBtnClick = () => {
    let { cityname, countryid } = this.props;
    let data = {
      name: cityname,
      country_id: this.state.countryId,
      is_active: 1,
    };
    console.log("country_id", countryid);
    this.props.addCity(data);
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    console.log("[event.target.name]", event.target.value);
    this.setState({ countryId: Number(event.target.value) });
  }

  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isInserted, message, countries } = this.props;
    if (!isInserted) {
      msg = message;
      messcolor = "red";
    }
    console.log("countries", this.props.countries);

    return (
      <div className="page">
        <CheckChanges path="/add-cities" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">City Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/cities" className="text-primary">
                    City
                  </Link>
                </li>
                <li className="breadcrumb-item active">Add City</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Country <span className="text-danger">*</span>
                              </label>
                              <select
                                name="country"
                                className="form-control mt-2"
                                required
                                data-msg="Please enter Country"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Country</option>
                                {countries &&
                                  countries.map((country: any, index: any) => (
                                    <option
                                      key={index}
                                      value={country.country_id}
                                    >
                                      {country.country_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                City name <span className="text-danger">*</span>
                              </label>
                              <input
                                id="cityname"
                                type="text"
                                name="cityname"
                                onKeyDown={this.blockInvalidChar}
                                required
                                data-msg="Please enter city Name"
                                className="input-material"
                                onChange={this.props.handleCityInput}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isCityReady()}
                            onClick={this.handleSaveBtnClick}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    cityname: state.city.cityname,
    countryid: state.city.countryid,
    countries: state.store.countries,

    message: state.city.message,
    isInserted: state.city.isInserted,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    handleCityInput: (event: any) => {
      dispatch(handleCityInput(event));
    },
    logoutUser: () => {
      dispatch(logoutUser());
    },
    addCity: (data: any) => {
      dispatch(addCity(data));
    },
    countryList: () => {
      dispatch(countryList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCity);
