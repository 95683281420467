import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import BeautyStars from "beauty-stars";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import { logoutUser, feedbackList, deleteFeedback } from "../../redux";
import { CustomerProps } from "../../interfaces/customers";

function ratingsFormatter(cell: any, row: any) {
  return <BeautyStars value={cell} size="16" inactiveColor="#b7b7b7" />;
}
class ActionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  handleDelete = (id: any) => {
    this.props.delete(id);
  };
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title="Delete Feedback"
          className="btn btn-outline-danger"
          data-toggle="modal"
          data-target={`#DelFeedback${row.customer_feedback_id}`}
        >
          <i className="fa fa-trash"></i>
        </button>
        {/* <!-- Modal--> */}
        <div
          id={`DelFeedback${row.customer_feedback_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Delete Feedback
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure,you want to delete this feedback?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
                <button
                  onClick={() => this.handleDelete(row.customer_feedback_id)}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} delete={props.deleteFeedback} />;
}
class CustomerFeedback extends Component<CustomerProps, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  componentDidMount() {
    this.props.feedbackList();
    document.title = "SimpleXSync | Customer Feedbacks";
  }
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Customer Reviews Not Found",
    };
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Customer Review Management</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body text-capitalize">
                        <BootstrapTable
                          version="4"
                          data={this.props.data}
                          search={true}
                          pagination={this.props.data.length > 10 && true}
                          options={options}
                          exportCSV={true}
                          csvFileName="feedbacks.csv"
                          hover
                        >
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="customer_feedback_id"
                            csvHeader="#"
                            width="50"
                            dataSort={true}
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="menu_name"
                            csvHeader="Menu Name"
                            width="100"
                            columnTitle
                          >
                            Menu
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="item_name"
                            csvHeader="Item"
                            width="100"
                            columnTitle
                          >
                            Item
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="login_name"
                            csvHeader="Name"
                            width="100"
                            columnTitle
                          >
                            Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="email_address"
                            csvHeader="Email"
                            width="100"
                            columnTitle
                          >
                            Email
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="customer_feedback"
                            csvHeader="Review"
                            width="100"
                            columnTitle
                          >
                            Review
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="ratings"
                            csvHeader="Ratings"
                            dataFormat={ratingsFormatter}
                            width="140"
                            columnTitle
                          >
                            Ratings
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataField='action' width='140' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn> */}
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    data: state.customer.feedbackData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    feedbackList: function () {
      dispatch(feedbackList());
    },
    deleteFeedback: function (id: any) {
      dispatch(deleteFeedback(id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeedback);
