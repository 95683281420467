import React, { Component } from "react";
import { EditMenuItemProps, EditMenuItemState } from "../../../interfaces/menu";
import { connect } from "react-redux";
import moment from "moment";
import {
  getMenuItem,
  editItem,
  groupsList,
  menusList,
  statesList,
  logoutUser,
  storesListForMultiSelect,
  checkDuplicatePosCode,
  checkDuplicateErpId,
} from "../../../redux";
import Footer from "../../../components/footer/main";
import Sidebar from "../../../components/sidebar";
import Topbar from "../../../components/topbar";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import { Link, Redirect } from "react-router-dom";
import CheckChanges from "../../../components/confirmOnLeave";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  groupsEditOptions,
  groupsOptions,
  modGroupListByAggregator,
  OrderingModes,
  subGroupsByGroupByType,
} from "../../../redux/actions/menuAction";
import { instashop_categories } from "../instahop_categories";
import * as uuid from "uuid";
const ShortUniqueId = require("short-unique-id");
const uid = new ShortUniqueId();
toast.configure();
class EditMenuItem extends Component<EditMenuItemProps, EditMenuItemState> {
  constructor(props: any) {
    super(props);
    this.state = {
      itemname: "",
      name_ar: "",
      description_ar:"",
      disabled: "",
      erpid: "",
      poscode: "",
      group: "",
      type: "",
      itemdesc: "",
      priority: "",
      images: null,
      hero_image: null,
      hero_item: false,
      subgroup_id: "",
      settime: "alltime",
      is_lsm: "0",
      itemstart: "",
      itemclose: "",
      tax_percent: "",
      state: "",
      metaTitle: "",
      metaDesc: "",
      mode: [],
      storesJson: [],
      instashop_remote_code: "",
      erp_id: "",
      brand_id: "",
      brand_name: "",
      // image_url:"",
      use_as_modifier: 0,
      sizejson: [
        {
          id: "",
          aloha_cat_id: "",
          size: "",
          cost: "",
          mrp: "",
          extra_price: "",
          description: "",
          alt_text: "",
          erp_id: "",
          image_url: null,
          is_active: null,
          instashop_remote_code: "",
          description_ar: "",
        },
      ],
      instashop_category: "",
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleItemsByGroupId = this.handleItemsByGroupId.bind(this);
  }
  componentDidMount() {
    document.title = "SimpleXSync | Menu Items";
    let id = this.props.match.params.id;
    let type = this.props.match.params.menu_type;
    // this.props.menusList();
    this.props.getMenuItem(id, type);
    // this.props.groupsList(type);
    // this.props.storesList();
    // this.props.modGroupListByAggregator("master");
    this.props.OrderingModes();
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleGroupChange = (event: any) => {
    let type = this.props.match.params.menu_type;
    this.setState({
      group: event.target.value,
    });
    this.props.subGroupsByGroupByType(event.target.value, type, "item");
  };
  handleItemsByGroupId(event: { target: { name: any; value: any } }) {
    this.setState({
      group: event.target.value,
      sizejson: [
        {
          id: "",
          aloha_cat_id: "",
          size: "",
          cost: "",
          mrp: "",
          extra_price: "",
          description: "",
          alt_text: "",
          image_url: null,
        },
      ],
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    let type = this.props.match.params.menu_type;
    if (
      nextProps.duplicateItemPos == this.props.duplicateItemPos &&
      nextProps.duplicateItemErp == this.props.duplicateItemErp
    ) {
      let type = this.props.match.params.menu_type;
      this.setState({
        itemname: nextProps.itemData.item_name,
        name_ar: nextProps.itemData.name_ar,
        description_ar:nextProps.itemData.description_ar,
        use_as_modifier: nextProps.itemData.use_as_modifier,
        erp_id: nextProps.itemData.erp_id,
        brand_id:
          nextProps.itemData.item_group_id &&
          nextProps.itemData.item_group_id.menu_id &&
          nextProps.itemData.item_group_id.menu_id.brand_id &&
          nextProps.itemData.item_group_id.menu_id.brand_id.brand_id,
        brand_name:
          nextProps.itemData.item_group_id &&
          nextProps.itemData.item_group_id.menu_id &&
          nextProps.itemData.item_group_id.menu_id.brand_id &&
          nextProps.itemData.item_group_id.menu_id.brand_id.brand_name,
        subgroup_id:
          type == "master"
            ? nextProps.itemData.master_subgroup_id &&
              nextProps.itemData.master_subgroup_id.id
            : nextProps.itemData.subgroup_id &&
              nextProps.itemData.subgroup_id.id,
        group:
          nextProps.itemData.item_group_id &&
          nextProps.itemData.item_group_id.group_id,
        type: nextProps.itemData.item_type,
        priority: nextProps.itemData.priority,
        hero_item: nextProps.itemData.hero_item == 1 ? true : false,
        // sizejson: nextProps.itemData.item_size ? JSON.parse(nextProps.itemData.item_size) : [],
        storesJson: nextProps.itemData.stores_json
          ? JSON.parse(nextProps.itemData.stores_json)
          : this.state.storesJson,
        is_lsm: nextProps.itemData.is_lsm,
        metaTitle: nextProps.itemData.meta_title
          ? nextProps.itemData.meta_title
          : "",
        metaDesc: nextProps.itemData.meta_description
          ? nextProps.itemData.meta_description
          : "",
      });
      this.setState({
        instashop_category: nextProps.itemData.tag_name,
        instashop_remote_code: nextProps.itemData.instashop_remote_code,
      });
      if (type == "master") {
        console.log(
          "nextProps.itemData.master_variants",
          nextProps.itemData.master_variants,
        );
        // if (nextProps.sizejson) {
        this.setState({
          sizejson:
            nextProps.itemData.master_variants &&
            nextProps.itemData.master_variants.length > 0
              ? nextProps.itemData.master_variants
              : this.state.sizejson,
        });
        // }
      } else {
        // if (nextProps.sizejson) {
        this.setState({
          sizejson:
            nextProps.itemData.variants &&
            nextProps.itemData.variants.length > 0
              ? nextProps.itemData.variants
              : this.state.sizejson,
        });
        // }
      }

      if (nextProps.orderingModes.length > 0 && this.props.itemData) {
        let orderingModes = nextProps.orderingModes;
        let mode = nextProps.itemData.item_mode
          ? JSON.parse(nextProps.itemData.item_mode)
          : [];
        const results = orderingModes.filter(({ label: id1 }: any) =>
          mode.some(({ label: id2 }: any) => id2 === id1),
        );
        this.setState({ mode: results });
      }

      if (nextProps.itemData.item_start_time !== null) {
        this.setState({
          settime: "customtime",
          itemstart: moment(nextProps.itemData.item_start_time)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
          itemclose: moment(nextProps.itemData.item_close_time)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
        });
      } else {
        this.setState({
          settime: "alltime",
          itemstart: "",
          itemclose: "",
        });
      }
    }
  }
  handleChangeRad = (event: { target: { name: any; value: any } }) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleVariantInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = this.state.sizejson;
    list[index][name] = value;
    this.setState({ sizejson: list });
  };

  handleVariantPriceInputChange = (e: any, index: any, sizeJsonindex: any) => {
    const { name, value } = e.target;
    const list: any = this.state.sizejson;
    list[sizeJsonindex].order_modes_price[index][name] = value;
    this.setState({ sizejson: list });
  };
  handlePricingOrderModesInputChange = (e: any, indexing: any, ind: any) => {
    if (e && e.length > 0) {
      if (e.length === this.state.sizejson[ind].order_modes_price.length + 1) {
        if (this.state.sizejson[ind].order_modes_price.length == 0) {
          let item_variant_id = this.state.sizejson[ind].id;
          let mode_id =
            e[this.state.sizejson[ind].order_modes_price.length].value;
          let mode_label =
            e[this.state.sizejson[ind].order_modes_price.length].label;
          let mrp = this.state.sizejson[ind].mrp;
          let extra_price = this.state.sizejson[ind].extra_price;
          let tempsizeJson = this.state.sizejson;
          let obj = {
            item_variant_id: item_variant_id,
            order_mode_id: mode_id,
            order_mode: mode_label,
            mrp: mrp,
            extra_price: extra_price,
            label: mode_label,
            value: mode_id,
          };
          tempsizeJson[ind].order_modes_price.push(obj);
          this.setState({ sizejson: tempsizeJson });
        } else {
          if (
            this.state.sizejson[ind].order_modes_price[
              this.state.sizejson[ind].order_modes_price.length - 1
            ].mrp > 0
          ) {
            let item_variant_id = this.state.sizejson[ind].id;
            let mode_id =
              e[this.state.sizejson[ind].order_modes_price.length].value;
            let mode_label =
              e[this.state.sizejson[ind].order_modes_price.length].label;
            let mrp = this.state.sizejson[ind].mrp;
            let extra_price = this.state.sizejson[ind].extra_price;
            let tempsizeJson = this.state.sizejson;
            let obj = {
              item_variant_id: item_variant_id,
              order_mode_id: mode_id,
              order_mode: mode_label,
              mrp: mrp,
              extra_price: extra_price,
              label: mode_label,
              value: mode_id,
            };
            tempsizeJson[ind].order_modes_price.push(obj);
            this.setState({ sizejson: tempsizeJson });
          } else {
            toast.error("Max retail price should not be 0,Please update", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        }
      } else {
        let tempsizeJson = this.state.sizejson;
        let dubtempsizeJson = tempsizeJson[ind].order_modes_price.filter(
          (item: any) =>
            item.order_mode_id != indexing.removedValue.order_mode_id,
        );
        tempsizeJson[ind].order_modes_price = dubtempsizeJson;
        this.setState({ sizejson: tempsizeJson });
      }
    } else {
      let tempsizeJson = this.state.sizejson;
      tempsizeJson[ind].order_modes_price = [];
      this.setState({ sizejson: tempsizeJson });
    }
  };

  // handle click event of the Remove button
  handleRemoveClick = (index: any) => {
    const list = this.state.sizejson;
    list[index].is_active = list[index].is_active == 1 ? 0 : 1;
    console.log("list", list);
    // list.splice(index, 1);
    this.setState({ sizejson: list });
  };

  handleRemove = (index: any) => {
    const list = this.state.sizejson;
    // list[index].is_active = list[index].is_active == 1 ? 0 : 1
    list.splice(index, 1);
    this.setState({ sizejson: list });
  };
  // handle click event of the Add button
  handleAddClick = (e: any, i: any) => {
    const list: any = this.state.sizejson;
    let { duplicateItemErp, duplicateItemPos } = this.props;
    // if (list[i].order_modes_price[list[i].order_modes_price.length - 1]) {
    if (
      list[i]["size"] == "" ||
      list[i]["cost"] == "" ||
      list[i]["erp_id"] == ""
    ) {
      alert("Please fill all mandatory variant fields");
    } else if (i == "3") {
      alert("Sorry you can select only 4 variants");
    } else {
      const newlist: any = {
        id: "",
        size: "",
        cost: "",
        mrp: "",
        extra_price: "",
        description: "",
        image_url: null,
      };
      // this.state.mode && this.state.mode.forEach((elem) => {
      //     let mode_id = elem.value;
      //     let mode_label = elem.label;
      //     let obj = {
      //         item_variant_id: "",
      //         order_mode_id: mode_id,
      //         mrp: 0,
      //         extra_price: 0,
      //         label: mode_label,
      //         value: mode_id
      //     }
      //     newlist.order_modes_price.push(obj);
      // })
      const size: any = this.state.sizejson.concat(newlist);
      this.setState({ sizejson: size });
    }
    // } else {
    //     alert("Please select at least one ordering mode!")
    // }
    e.preventDefault();
  };
  fileSelectedHandler = (e: any, index: any) => {
    const list: any = this.state.sizejson;
    if (e.target.files.length > 0) {
      list[index]["image_url"] = e.target.files;
      this.setState({ sizejson: list });
    }
  };
  heroItemImageSelectedHandler = (e: any) => {
    if (e.target.files.length > 0) {
      this.setState({ hero_image: e.target.files });
    }
  };
  handleHeroItem = (event: { target: { name: any; value: any } }) => {
    const { hero_item } = this.state;
    this.setState({ hero_item: !hero_item });
  };
  checkMRPForAllModes = () => {
    let tempsizeJson = this.state.sizejson;
    let variants: any = [];
    for (let i = 0; i < tempsizeJson.length; i++) {
      let order_modes_price: any[] = tempsizeJson[i].order_modes_price;
      var filtered =
        order_modes_price &&
        order_modes_price.filter(function (el) {
          return el.mrp == 0 || el.mrp == "";
        });
      if (filtered.length > 0) {
        variants.push(tempsizeJson[i]);
      }
    }
    if (variants.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  isMenuItemReady = () => {
    const {
      itemname,
      name_ar,
      group,
      settime,
      itemstart,
      mode,
      itemclose,
      is_lsm,
      storesJson,
      erp_id,
      disabled,
    } = this.state;
    let { duplicateItemPos, duplicateItemErp } = this.props;
    if (settime == "customtime") {
      if (is_lsm == "0") {
        // && mode.length > 0
        return (
          itemname !== "" &&
          name_ar !== "" &&
          group !== "" &&
          itemstart !== "" &&
          itemclose !== "" &&
          erp_id != "" &&
          disabled != true
        );
        // && this.checkMRPForAllModes()
      } else if (is_lsm == "1") {
        // && mode.length > 0
        return (
          itemname !== "" &&
          name_ar !== "" &&
          group !== "" &&
          itemstart !== "" &&
          itemclose !== "" &&
          storesJson.length > 0 &&
          erp_id != "" &&
          disabled != true
        );
        // && this.checkMRPForAllModes()
      }
    } else {
      if (is_lsm == "0") {
        // mode.length > 0
        return (
          itemname !== "" &&
          name_ar !== "" &&
          group !== "" &&
          erp_id != "" &&
          disabled != true
        );
        // && this.checkMRPForAllModes()
      } else if (is_lsm == "1") {
        // mode.length > 0
        return (
          itemname !== "" &&
          name_ar !== "" &&
          group !== "" &&
          storesJson.length > 0 &&
          erp_id != "" &&
          disabled != true
        );
        // && this.checkMRPForAllModes()
      }
    }
  };

  handleInstashopCategor = (e: any) => {
    this.setState({ instashop_category: e.value });
  };

  handleOrderModesInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      if (e.length < this.state.mode.length) {
        let tempsizeJson = this.state.sizejson;
        for (let i = 0; i < tempsizeJson.length; i++) {
          let tempArr: any[] = tempsizeJson[i].order_modes_price;
          let selectedItems = tempArr.filter((obj) =>
            e.find((s: any) => s.value === obj.value),
          );
          tempsizeJson[i].order_modes_price = selectedItems;
        }
        this.setState({ sizejson: tempsizeJson });
      } else {
        let tempsizeJson = this.state.sizejson;
        for (let i = 0; i < tempsizeJson.length; i++) {
          let item_variant_id = this.state.sizejson[i].id;
          let mode_id =
            e[this.state.sizejson[i].order_modes_price.length].value;
          let mode_label =
            e[this.state.sizejson[i].order_modes_price.length].label;
          let mrp = this.state.sizejson[i].mrp;
          let extra_price = this.state.sizejson[i].extra_price;
          let obj = {
            item_variant_id: item_variant_id,
            order_mode_id: mode_id,
            order_mode: mode_label,
            mrp: mrp ? mrp : 0,
            extra_price: extra_price ? extra_price : 0,
            label: mode_label,
            value: mode_id,
          };
          tempsizeJson[i].order_modes_price.push(obj);
        }
        this.setState({ sizejson: tempsizeJson });
      }
      this.setState({ mode: e });
    } else {
      this.setState({ mode: [] });
      let tempsizeJson = this.state.sizejson;
      for (let i = 0; i < tempsizeJson.length; i++) {
        tempsizeJson[i].order_modes_price = [];
      }
      this.setState({ sizejson: tempsizeJson });
    }
  };
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ storesJson: e });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  isUnique = (arr: any) => {
    const len = arr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len; j++) {
        // if the elements match, this wouldn't be a unique array
        if (
          (arr[i].pos_code !== "" && arr[j].pos_code !== "") ||
          (arr[i].erp_id !== "" && arr[j].erp_id !== "")
        ) {
          if (
            i !== j &&
            (arr[i].pos_code === arr[j].pos_code ||
              arr[i].erp_id === arr[j].erp_id)
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };
  blockSpecialCharacters = () => {
    var checkString = this.state.itemname;
    if (checkString != "") {
      if (/[%]/.test(checkString)) {
        alert(
          "Your item name has '%' sign. \nIt is not allowed.\nPlease remove it and try again.",
        );
        return false;
      } else {
        return true;
      }
    }
  };

  handleInstaCode = (e: any) => {
    this.setState({ instashop_remote_code: e.target.value });
  };

  handleISubgroup = (event: any) => {
    this.setState({ subgroup_id: event.target.value });
  };
  // handleModGroups = (e: any, index: any) => {
  //     const list: any = this.state.sizejson;
  //     list[index]["modgroups"] = e.value;
  //     this.setState({ sizejson: list });
  // };
  handleState = (value: any) => {
    this.setState({ disabled: value });
  };
  handleSaveBtnClick = (event: any) => {
    this.setState({ disabled: true });
    let menutype = this.props.match.params.menu_type;
    let {
      itemname,
      subgroup_id,
      erpid,
      priority,
      instashop_category,
      erp_id,
      instashop_remote_code,
      use_as_modifier,
      itemdesc,
      hero_item,
      mode,
      hero_image,
      group,
      type,
      sizejson,
      settime,
      itemstart,
      itemclose,
      is_lsm,
      storesJson,
      metaTitle,
      metaDesc,
      name_ar,
      description_ar,
    } = this.state;
    let id = this.props.match.params.id;
    const data: any = new FormData();
    let itemNamevalid = this.blockSpecialCharacters();
    if (itemNamevalid) {
      if (this.state.sizejson) {
        for (var x = 0; x < this.state.sizejson.length; x++) {
          if (this.state.sizejson[x].image_url !== null) {
            if (typeof this.state.sizejson[x].image_url === "object") {
              var file = this.state.sizejson[x].image_url[0];
              var newFileName =
                file.name.split(".")[0] +
                `_variant_${x}.` +
                file.name.split(".")[1];
              data.append("files", file, newFileName);
            }
          }
        }
      }
      const SizeJsonString = JSON.stringify(sizejson);
      if (hero_image) {
        var file = hero_image[0];
        var newFileName =
          file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
        data.append("files", file, newFileName);
      }
      data.append("item_name", itemname);
      data.append("description_ar", description_ar);
      data.append("name_ar", name_ar);
      data.append("use_as_modifier", use_as_modifier);
      data.append("erp_id", erp_id);
      // data.append("image_url",image_url)
      if (hero_item === true) {
        data.append("hero_item", 1);
      } else {
        data.append("hero_item", 0);
      }
      data.append("item_group_id", group);
      if (menutype == "master") {
        data.append("master_subgroup_id", subgroup_id);
      } else {
        data.append("subgroup_id", subgroup_id);
      }
      data.append("tag_name", instashop_category);
      // data.append("instashop_remote_code",instashop_remote_code)
      data.append("item_type", type);
      data.append("item_mode", JSON.stringify(mode));
      data.append("priority", priority);
      data.append("meta_title", metaTitle);
      data.append("meta_description", metaDesc);
      if (settime === "customtime") {
        data.append("item_start_time", moment(itemstart).utc(false));
        data.append("item_close_time", moment(itemclose).utc(false));
      } else if (settime === "alltime") {
        data.append("item_start_time", null);
        data.append("item_close_time", null);
      }
      if (is_lsm == "0") {
        data.append("is_lsm", 0);
      } else if (is_lsm == "1") {
        data.append("is_lsm", 1);
        data.append("stores_json", JSON.stringify(storesJson));
      }
      if (
        // sizejson[sizejson.length - 1]["size"] !== "" &&
        sizejson[sizejson.length - 1]["cost"] !== ""
      ) {
        // && (sizejson[sizejson.length - 1].order_modes_price.length > 0 && sizejson[sizejson.length - 1].order_modes_price[sizejson[sizejson.length - 1].order_modes_price.length - 1].mrp > 0)
        // if (this.isUnique(sizejson)) {
        data.append("itemSizes", SizeJsonString);
        this.props.editItem(id, data, menutype, this.handleState);
        // } else {
        //     toast.error("Please fill unique POS & ERP codes", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        // }
      } else {
        this.handleState(false);
        alert("Please fill all mandatory variant fields");
      }
    }
  };
  handleErpInputChange = (e: any, index: any, value: any) => {
    const list: any = this.state.sizejson;
    list[index]["erp_id"] = value;
    this.setState({ sizejson: list });
  };
  handleErpChange = () => {
    this.setState({ erp_id: uid.stamp(32) });
  };
  handleUseAsModifier = (e: any) => {
    this.setState({ use_as_modifier: e.target.value });
  };
  render() {
    let type = this.props.match.params.menu_type;
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isUpdated, message, groups, itemData, subgroups, modgroups } =
      this.props;
    const {
      sizejson,
      settime,
      hero_item,
      priority,
      itemstart,
      mode,
      itemclose,
      is_lsm,
      storesJson,
    } = this.state;
    if (isUpdated) {
      msg = message;
      messcolor = "green";
    } else {
      msg = message;
      messcolor = "red";
    }
    return (
      <div className="page">
        <CheckChanges path="/edit-item" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Menu Item Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/menu-items" className="text-primary">
                    Menu Items
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Menu Item</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Item Name <span className="text-danger">*</span>
                              </label>
                              <input
                                id="itemname"
                                type="text"
                                name="itemname"
                                defaultValue={itemData.item_name}
                                required
                                data-msg="Please enter Item Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Arabic Item Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="name_ar"
                                type="text"
                                name="name_ar"
                                value={this.state.name_ar}
                                required
                                data-msg="Please enter Arabic Item Name"
                                className="input-material"
                                onChange={(e) => {
                                  // if (!validateAlphaValue(e.target.value))
                                  //   return;
                                  this.handleInputChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label mb-3">
                                Erp Id <span className="text-danger">*</span>
                              </label>
                              <input
                                
                                name="erp_id"
                                value={this.state.erp_id}
                                type="text"
                                data-msg="Please enter erp"
                                className="input-material"
                                onChange={e => this.handleInputChange(e)}
                              />
                            </div>
                          </div>

                          <div className="col">
                                          <div className="form-group">
                                            <label className="form-control-label">
                                              Arabic Description
                                            </label>
                                            <input
                                              id="description_ar"
                                              type="text"
                                              name="description_ar"
                                              value={this.state.description_ar}
                                              className="input-material"
                                              onChange={(e) => {
                                                // if (!validateAlphaValue(e.target.value))
                                                //   return;
                                                this.handleInputChange(e);
                                              }}
                                            />
                                          </div>
                                        </div>


                          {itemData.erp_id == "" && (
                            <div className="col-lg-1 col-sm-1 col-1 mt-5">
                              <button
                                onClick={this.handleErpChange}
                                className="btn btn-sm btn-primary"
                              >
                                <i className="fa fa-random"></i>
                              </button>
                            </div>
                          )}
                          {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP ID</label>
                                                            <input id="erpid" type="text" name="erpid" defaultValue={itemData.erp_id} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                          {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">POS Code</label>
                                                            <input id="poscode" type="text" name="poscode" defaultValue={itemData.pos_code} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                        </div>
                        <div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Brand Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                disabled
                                id="itemname"
                                type="text"
                                name="itemname"
                                defaultValue={this.state.brand_name}
                                required
                                data-msg="Please enter Item Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="itemdesc" type="text" name="itemdesc" defaultValue={itemData.item_description} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Groups <span className="text-danger">*</span>
                              </label>
                              <select
                                name="group"
                                className="form-control mt-2"
                                required
                                data-msg="Please select Group"
                                onChange={this.handleGroupChange}
                              >
                                <option disabled>Select Group</option>
                                {groups &&
                                  groups.map((group, index) => (
                                    <option
                                      key={index}
                                      value={group.group_id}
                                      {...(itemData.item_group_id &&
                                        itemData.item_group_id.group_id ===
                                          group.group_id && { selected: true })}
                                    >
                                      {group.group_name + "-" + group.menu_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          {this.state.type == "master" ? (
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  SubGroup<span className="text-danger">*</span>
                                </label>
                                <select
                                  name="master_subgroup_id"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Group"
                                  onChange={this.handleISubgroup}
                                >
                                  <option disabled>Select SubGroup</option>
                                  {subgroups &&
                                    subgroups.map(
                                      (group: any, index: any) =>
                                        group && (
                                          <option
                                            key={index}
                                            value={group.group_id}
                                            {...(itemData.master_subgroup_id &&
                                              itemData.master_subgroup_id.id ===
                                                group.group_id && {
                                                selected: true,
                                              })}
                                          >
                                            {group.sub_group_name}
                                          </option>
                                        ),
                                    )}
                                </select>
                              </div>
                            </div>
                          ) : (
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  SubGroup<span className="text-danger">*</span>
                                </label>
                                <select
                                  name="master_subgroup_id"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select Group"
                                  onChange={this.handleISubgroup}
                                >
                                  <option disabled>Select SubGroup</option>
                                  {subgroups &&
                                    subgroups.map(
                                      (group: any, index: any) =>
                                        group && (
                                          <option
                                            key={index}
                                            value={group.group_id}
                                            {...(itemData.subgroup_id &&
                                              itemData.subgroup_id.id ===
                                                group.group_id && {
                                                selected: true,
                                              })}
                                          >
                                            {group.sub_group_name}
                                          </option>
                                        ),
                                    )}
                                </select>
                              </div>
                            </div>
                          )}
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Tag Name <span className="text-danger">*</span>
                              </label>
                              <Select
                                name="instashop_category"
                                value={{
                                  label: this.state.instashop_category,
                                  value: this.state.instashop_category,
                                }}
                                options={instashop_categories}
                                placeholder="Select Instashop Category"
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleInstashopCategor(e)
                                }
                              />
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Use Item as Modifier
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name="master_subgroup_id"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select Group"
                                onChange={this.handleUseAsModifier}
                              >
                                <option
                                  disabled
                                  {...(itemData.use_as_modifier === 0 && {
                                    selected: true,
                                  })}
                                  value={0}
                                >
                                  No
                                </option>
                                <option
                                  disabled
                                  {...(itemData.use_as_modifier === 1 && {
                                    selected: true,
                                  })}
                                  value={1}
                                >
                                  Yes
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">Type</label>
                              <input
                                id="type"
                                type="text"
                                name="type"
                                defaultValue={itemData.item_type}
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>

                          {/* <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">Item Image <span className="text-danger"> {(itemData.images && itemData.images.length) > 0 && `(${itemData.images.length} Item Images)`}</span> </label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                            <small className="form-text">You can also choose multiple images.</small>
                                                        </div>
                                                    </div> */}
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Meta title{" "}
                              </label>
                              <input
                                id="metaTitle"
                                type="text"
                                value={this.state.metaTitle}
                                name="metaTitle"
                                required
                                data-msg="Please enter Item Type"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Meta description
                              </label>
                              <input
                                id="metaDesc"
                                type="text"
                                value={this.state.metaDesc}
                                name="metaDesc"
                                required
                                data-msg="Please enter Item Type"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>

                        {/* {itemData && itemData.aggregator_id && itemData.aggregator_id.id == 1 && <div className="row">

                                                    <div className="col-12" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                value={mode}
                                                                options={this.props.orderingModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>} */}
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Variants
                              </label>
                              {sizejson &&
                                sizejson.length > 0 &&
                                sizejson.map((x: any, ind: any) => {
                                  return (
                                    <div key={ind}>
                                      <div className="row">
                                        {/* <div className="col-lg-4 col-sm-4 col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label mb-3">Aloha Category Id</label>
                                                                                        <input type="number" name="aloha_cat_id" value={x.aloha_cat_id} className="input-material" onChange={e => this.handleVariantInputChange(e, ind)} />
                                                                                    </div>
                                                                                </div> */}

                                        <div className="col-lg-4 col-sm-4 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Size{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              name="size"
                                              type="text"
                                              value={x.size}
                                              data-msg="Please enter size"
                                              className="input-material"
                                              onChange={(e) =>
                                                this.handleVariantInputChange(
                                                  e,
                                                  ind,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        {/* <div className="col-lg-3 col-sm-3 col-5">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Erp Id{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              
                                              name="erp_id"
                                              type="text"
                                              value={x.erp_id}
                                              data-msg="Please enter erp"
                                              className="input-material"
                                              onChange={e => this.handleVariantInputChange(e, ind)}
                                            />
                                          </div>
                                        </div> */}

                                        {x.id == "" && (
                                          <div className="col-lg-1 col-sm-1 col-1 mt-5">
                                            <button
                                              onClick={(e) =>
                                                this.handleErpInputChange(
                                                  e,
                                                  ind,
                                                  uuid.v1(),
                                                )
                                              }
                                              className="btn btn-sm btn-primary"
                                            >
                                              <i className="fa fa-random"></i>
                                            </button>
                                          </div>
                                        )}
                                        {/* <div className="col-lg-4 col-sm-4 col-6"> */}
                                          {/* <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Cost{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              name="cost"
                                              type="number"
                                              value={x.cost}
                                              data-msg="Please enter cost"
                                              className="input-material"
                                              onChange={(e) =>
                                                this.handleVariantInputChange(
                                                  e,
                                                  ind,
                                                )
                                              }
                                            />
                                          </div> */}
                                        {/* </div> */}

                                        <div className="col-lg-4 col-sm-4 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Mrp{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              name="mrp"
                                              type="number"
                                              value={x.mrp}
                                              data-msg="Please enter cost"
                                              className="input-material"
                                              onChange={(e) =>
                                                this.handleVariantInputChange(
                                                  e,
                                                  ind,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-lg-4 col-sm-4 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Description
                                            </label>
                                            <input
                                              id="description"
                                              type="text"
                                              name="description"
                                              value={x.description}
                                              className="input-material"
                                              onChange={(e) =>
                                                this.handleVariantInputChange(
                                                  e,
                                                  ind,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        {/* <div className="col-lg-4 col-sm-4 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Arabic Description
                                            </label>
                                            <input
                                              id="description_ar"
                                              type="text"
                                              name="description_ar"
                                              value={x.description_ar}
                                              className="input-material"
                                              onChange={(e) =>
                                                this.handleVariantInputChange(
                                                  e,
                                                  ind,
                                                )
                                              }
                                            />
                                          </div>
                                        </div> */}
                                        {/* <div className="col-lg-12" >
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Pricing By Ordering Modes <span className="text-danger">*</span></label>
                                                                                        <Select
                                                                                            isMulti
                                                                                            name="mode"
                                                                                            value={x.order_modes_price}
                                                                                            options={mode}
                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                            classNamePrefix="select"
                                                                                            onChange={(e, i) => this.handlePricingOrderModesInputChange(e, i, ind)}
                                                                                        />
                                                                                    </div>
                                                                                </div> */}
                                        {/* {x.order_modes_price && x.order_modes_price.length > 0 && x.order_modes_price.map((channel: any, index: any) => (
                                                                                    <>
                                                                                        <div className="col-12 mb-2">
                                                                                            <strong>{index + 1} -</strong>
                                                                                            <strong style={{ fontSize: '16px' }} className="ml-2">
                                                                                                {channel.label}
                                                                                            </strong>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <div className="form-group">
                                                                                                <label className="form-control-label mb-3">Max Retail Price <span className="text-danger">*</span></label>
                                                                                                <input
                                                                                                    name="mrp"
                                                                                                    type="number"
                                                                                                    value={channel.mrp}
                                                                                                    data-msg="Please enter mrp"
                                                                                                    className="input-material"
                                                                                                    onChange={e => this.handleVariantPriceInputChange(e, index, ind)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <div className="form-group">
                                                                                                <label className="form-control-label mb-3">Extra Price</label>
                                                                                                <input
                                                                                                    name="extra_price"
                                                                                                    type="number"
                                                                                                    value={channel.extra_price}
                                                                                                    className="input-material"
                                                                                                    onChange={e => this.handleVariantPriceInputChange(e, index, ind)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))} */}
                                        {/* <div className="row"> */}
                                        <div className="col-lg-4 col-sm-4 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Item Image{" "}
                                              <span className="text-danger">
                                                {" "}
                                                {x.image_url !== null &&
                                                  `(1 Item Image)`}
                                              </span>
                                            </label>
                                            <input
                                              id="files"
                                              type="file"
                                              name="files"
                                              className="form-control-file"
                                              onChange={(e) =>
                                                this.fileSelectedHandler(e, ind)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Alt Tag
                                            </label>
                                            <input
                                              id="alt_text"
                                              type="text"
                                              value={x.alt_text}
                                              name="alt_text"
                                              className="input-material"
                                              onChange={(e) =>
                                                this.handleVariantInputChange(
                                                  e,
                                                  ind,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-lg-4 col-sm-4 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Image Url
                                            </label>
                                            <input
                                              id="image_url"
                                              type="text"
                                              value={x.image_url}
                                              name="image_url"
                                              className="input-material"
                                              onChange={(e) =>
                                                this.handleVariantInputChange(
                                                  e,
                                                  ind,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        {/* <div className="col-lg-3 col-sm-3 col-6" >
                                                                                    <div className="form-group">
                                                                                        <label className="form-control-label">Modifier Groups</label>
                                                                                        <Select
                                                                                            name="instashop_category"
                                                                                            options={modgroups}
                                                                                            placeholder='Select Mod Groups'
                                                                                            className="text-capitalize basic-multi-select mt-2"
                                                                                            classNamePrefix="select"
                                                                                            onChange={(e, i) => this.handleModGroups(e,ind)}
                                                                                        />
                                                                                    </div>
                                                                                </div> */}
                                        <div className="col-lg-4 col-sm-4 col-6 d-flex align-items-center">
                                          {sizejson.length !== 1 &&
                                            sizejson.length - 1 !== ind && (
                                              <button
                                                className={
                                                  x.is_active != null
                                                    ? x.is_active == 1
                                                      ? "btn btn-sm btn-outline-danger"
                                                      : "btn btn-sm btn-outline-success mr-2"
                                                    : "btn btn-sm btn-outline-danger"
                                                }
                                                onClick={() =>
                                                  x.is_active != null
                                                    ? this.handleRemoveClick(
                                                        ind,
                                                      )
                                                    : this.handleRemove(ind)
                                                }
                                              >
                                                <i
                                                  className={
                                                    x.is_active != null
                                                      ? x.is_active == 1
                                                        ? "fa fa-lock"
                                                        : "fa fa-unlock"
                                                      : "fa fa-trash"
                                                  }
                                                ></i>
                                              </button>
                                            )}
                                          {sizejson.length - 1 === ind && (
                                            <button
                                              className="btn btn-sm btn-primary ml-2"
                                              onClick={(e: any) =>
                                                this.handleAddClick(e, ind)
                                              }
                                            >
                                              <i className="fa fa-plus"></i>
                                            </button>
                                          )}
                                          {sizejson.length !== 1 &&
                                            sizejson.length - 1 === ind && (
                                              <button
                                                className={
                                                  x.id == ""
                                                    ? "btn btn-sm btn-outline-danger"
                                                    : x.is_active == 1
                                                      ? "btn btn-sm btn-outline-danger"
                                                      : "btn btn-sm btn-outline-success mr-2"
                                                }
                                                onClick={() =>
                                                  x.id == ""
                                                    ? this.handleRemove(ind)
                                                    : this.handleRemoveClick(
                                                        ind,
                                                      )
                                                }
                                              >
                                                <i
                                                  className={
                                                    x.id == ""
                                                      ? "fa fa-trash"
                                                      : x.is_active == 1
                                                        ? "fa fa-lock"
                                                        : "fa fa-unlock"
                                                  }
                                                ></i>
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom1"
                                type="radio"
                                name="settime"
                                value="alltime"
                                checked={settime === "alltime"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom1">
                                Available for all time
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom2"
                                type="radio"
                                name="settime"
                                value="customtime"
                                checked={settime === "customtime"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom2">
                                Available for specific time
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-2 col-sm-6 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="checkboxCustom1"
                                type="checkbox"
                                name="taxstatus"
                                checked={hero_item}
                                onChange={this.handleHeroItem}
                                className="checkbox-template"
                              />
                              <label htmlFor="checkboxCustom1">Hero Item</label>
                            </div>
                          </div>
                          {hero_item && (
                            <div className="col-lg-4 col-sm-6 col-6 mt-2">
                              <div className="form-group">
                                <label className="form-control-label mb-3">
                                  Hero Item Image
                                </label>
                                <input
                                  id="files"
                                  type="file"
                                  name="files"
                                  className="form-control-file"
                                  onChange={this.heroItemImageSelectedHandler}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {settime === "customtime" && (
                          <div>
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-lable">
                                    Menu Item Timing
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <input
                                    id="itemstart"
                                    type="datetime-local"
                                    name="itemstart"
                                    required
                                    value={itemstart}
                                    data-msg="Please enter starting time"
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <input
                                    id="itemclose"
                                    type="datetime-local"
                                    name="itemclose"
                                    required
                                    value={itemclose}
                                    data-msg="Please enter closing time"
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-lg-4 col-sm-4 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom3"
                                type="radio"
                                name="is_lsm"
                                value="0"
                                checked={is_lsm == "0"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom3">
                                Available for all stores
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-6 mt-5">
                            <div className="i-checks">
                              <input
                                id="radioCustom4"
                                type="radio"
                                name="is_lsm"
                                value="1"
                                checked={is_lsm == "1"}
                                onChange={this.handleChangeRad}
                                className="radio-template"
                              />
                              <label htmlFor="radioCustom4">
                                Available for specific stores
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Priority
                              </label>
                              <input
                                id="priority"
                                type="text"
                                name="priority"
                                value={priority}
                                required
                                data-msg="Please enter Priority"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {is_lsm == "1" && (
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Select Store{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  isMulti
                                  defaultValue={storesJson}
                                  name="stores"
                                  options={this.props.stores}
                                  className="text-capitalize basic-multi-select mt-2"
                                  classNamePrefix="select"
                                  onChange={(e, i) =>
                                    this.handleStoresInputChange(e, i)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            onClick={this.handleSaveBtnClick}
                            disabled={!this.isMenuItemReady()}
                            className="btn btn-primary"
                          >
                            Update Item
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    groups: state.menu.groups,
    subgroups: state.menu.subgroups,
    states: state.setting.states,
    menus: state.menu.menus,
    stores: state.menu.storesoptions,
    itemData: state.menu.itemData,
    sizejson: state.menu.sizejson,
    taxData: state.setting.taxData,
    message: state.menu.message,
    isUpdated: state.menu.isUpdated,
    duplicateItemPos: state.menu.duplicateItemPos,
    duplicateItemErp: state.menu.duplicateItemErp,
    orderingModes: state.menu.orderModes,
    modgroups: state.menu.modgroups,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editItem: function (id: any, formData: any, type: any, handleState: any) {
      dispatch(editItem(id, formData, type, handleState));
    },
    checkDuplicatePosCode: (pos_code: any) => {
      dispatch(checkDuplicatePosCode(pos_code));
    },
    checkDuplicateErpId: (erp_id: any) => {
      dispatch(checkDuplicateErpId(erp_id));
    },
    groupsList: function (type: any, brand_id: any) {
      dispatch(groupsEditOptions(type, brand_id));
    },
    getMenuItem: function (id: number, type: any) {
      dispatch(getMenuItem(id, type));
    },
    menusList: function () {
      dispatch(menusList());
    },
    storesList: () => {
      dispatch(storesListForMultiSelect());
    },
    statesList: function () {
      dispatch(statesList());
    },
    OrderingModes: () => {
      dispatch(OrderingModes());
    },
    subGroupsByGroupByType: (id: any, type: any, subgroup_type: any) => {
      dispatch(subGroupsByGroupByType(id, type, subgroup_type));
    },
    modGroupListByAggregator: (type: any) => {
      dispatch(modGroupListByAggregator(type));
    },
  };
};
function validateAlphaValue(value: string) {
  // Check if value starts with a space or contains any symbols
  return !/^[ ]/.test(value) && /^[a-zA-Z0-9\s]*$/.test(value);
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMenuItem);
