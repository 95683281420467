import moment from "moment";
import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { currency } from "../../client-config";
import "./invoice.css";
var QRCode = require("qrcode.react");
class Invoice extends Component<{ data: any; orderId: any }, {}> {
  componentRef: any;
  calcSubTotalTotal = (orderItems: any) => {
    let sum = 0;
    orderItems.forEach((item: any) => {
      sum += parseInt(item.order_item_grossprice);
    });
    return Math.round(sum);
  };
  calcTotal = (orderItems: any) => {
    let sum = 0;
    orderItems.forEach((item: any) => {
      sum += parseInt(item.order_item_grossprice);
    });
    return Math.round(sum);
  };
  render() {
    let { data, orderId } = this.props;
    // console.log("data.orderDetail.fbr_invoice_number",data.orderDetail.fbr_invoice_number);
    let DateCreated: any = "";
    let FutureDate: any = "";
    let utcDateTime: any = "";
    let FbrNumber: any = "";
    let FbrNumberComplete: any = "";
    if (data.orderDetail) {
      DateCreated = moment(data.orderDetail.date_created)
        .local()
        .format("YYYY-MM-DD HH:mm")
        .split(" ");
      DateCreated = DateCreated[1] + " , " + DateCreated[0];

      if (data.orderDetail.fbr_invoice_number != undefined) {
        FbrNumberComplete = data.orderDetail.fbr_invoice_number;
        FbrNumber = FbrNumberComplete.split(":");
        FbrNumber = FbrNumber[1];
        console.log("FbrNumber", FbrNumber);
      }
    }
    if (data.orderDetail && data.orderDetail.future_date) {
      utcDateTime = moment(data.orderDetail.future_date).utc(false);
      FutureDate = moment(utcDateTime)
        .local()
        .format("YYYY-MM-DD HH:mm")
        .split(" ");
      FutureDate = FutureDate[1] + " , " + FutureDate[0];
    }
    let aggregator_name: any;
    let src: any;
    if (data.orderDetail && data.orderDetail.order_channel == "deliveroo") {
      aggregator_name = "Deliveroo";
      src = `/assets/img/${data.orderDetail.order_channel}.png`;
    }
    return (
      <div>
        <div className="d-flex justify-content-end ">
          {data.orderDetail.order_status_code !== 1 && (
            <ReactToPrint
              trigger={() => (
                <button type="button" className="btn btn-success">
                  Print
                </button>
              )}
              content={() => this.componentRef}
            />
          )}
        </div>
        <div style={{ width: "108mm" }} ref={(el) => (this.componentRef = el)}>
          <header className="clearfix">
            <div id="logo">
              <img
                width="auto"
                height={120}
                style={{ borderRadius: 10 }}
                src={process.env.PUBLIC_URL + src}
              />
            </div>
            <h6 className="text-center mt-3">
              <b>{aggregator_name}</b>
            </h6>
            <h6 className="text-center mt-3">{data.orderDetail.city}</h6>

            <div id="project" className="invoiceh1">
              {/* <h3>Customer Information</h3> */}
              <div style={{ display: "flex" }}>
                <span>
                  <b>ORDER NO:</b>
                </span>
                <span
                  style={{
                    whiteSpace: "normal",
                    textAlign: "left",
                    flex: "auto",
                  }}
                >
                  {" "}
                  {orderId}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span>
                  <b>Order Date:</b>
                </span>
                <span
                  style={{
                    whiteSpace: "normal",
                    textAlign: "left",
                    flex: "auto",
                  }}
                >
                  {" "}
                  {data.orderDetail && DateCreated}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span>
                  <b>Customer:</b>
                </span>
                <span
                  style={{
                    whiteSpace: "normal",
                    textAlign: "left",
                    flex: "auto",
                  }}
                >
                  {" "}
                  {data.orderDetail && data.orderDetail.login_name}
                </span>
              </div>
              {data.orderDetail && data.orderDetail.phone_number && (
                <div style={{ display: "flex" }}>
                  <span>
                    <b>Phone:</b>
                  </span>
                  <span
                    style={{
                      whiteSpace: "normal",
                      textAlign: "left",
                      flex: "auto",
                    }}
                  >
                    {" "}
                    {data.orderDetail.phone_number}
                  </span>
                </div>
              )}
              <div style={{ display: "flex" }}>
                <span>
                  <b>Store:</b>
                </span>
                <span
                  style={{
                    whiteSpace: "normal",
                    textAlign: "left",
                    flex: "auto",
                  }}
                >
                  {data.orderDetail && data.orderDetail.store_name}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span>
                  <b>From:</b>
                </span>
                <span
                  style={{
                    whiteSpace: "normal",
                    textAlign: "left",
                    flex: "auto",
                  }}
                >
                  {data.orderDetail && data.orderDetail.address}
                </span>
              </div>
              {data.orderDetail && data.orderDetail.delivery_address && (
                <div style={{ display: "flex" }}>
                  <span>
                    <b>Delivery at:</b>{" "}
                  </span>
                  <span
                    style={{
                      whiteSpace: "normal",
                      textAlign: "left",
                      flex: "auto",
                    }}
                  >
                    {" "}
                    {data.orderDetail.delivery_address}
                  </span>
                </div>
              )}
              <div style={{ display: "flex" }}>
                <span>
                  <b>Special Ins:</b>
                </span>
                <span
                  style={{
                    whiteSpace: "normal",
                    textAlign: "left",
                    flex: "auto",
                  }}
                >
                  {data.orderDetail && data.orderDetail.special_inst}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span>
                  <b>Status:</b>
                </span>
                {(data.orderDetail.order_status_code === 4 ||
                  data.orderDetail.order_status_code === 5 ||
                  data.orderDetail.order_status_code === 6) && (
                  <span
                    style={{
                      whiteSpace: "normal",
                      textAlign: "left",
                      width: "80px",
                      color: "white",
                    }}
                    {...(((data.orderDetail.order_status_code === 4 ||
                      data.orderDetail.order_status_code === 5) && {
                      className: "badge badge-success text-capitalize p-1",
                    }) ||
                      (data.orderDetail.order_status_code === 6 && {
                        className: "badge badge-danger text-capitalize p-1",
                      }))}
                  >
                    {data.orderDetail.order_status_description}
                  </span>
                )}
              </div>
              {utcDateTime._isValid && (
                <div>
                  <span>
                    <b>
                      {data.orderDetail &&
                      data.orderDetail.delivery_status === "Delivery"
                        ? "Delivery"
                        : "Pickup"}{" "}
                      Time:
                    </b>
                  </span>
                  <br />
                  <span>{FutureDate}</span>
                </div>
              )}
              {FbrNumber && (
                <div style={{ display: "flex" }}>
                  <span>{FbrNumberComplete}</span>
                  <span
                    style={{
                      whiteSpace: "normal",
                      textAlign: "right",
                      flex: "auto",
                    }}
                  >
                    <QRCode size="50" value={FbrNumber} />
                  </span>
                </div>
              )}
              {/* {FbrNumber && <div style={{ display: 'flex' }}><span><b>FBR Invoice:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}><QRCode size='50' value={FbrNumber} /></span></div>} */}
              {/* {(data.orderDetail.fbr_invoice_number!=undefined) ? 
                            <div style={{ display: 'flex' }}><span style={{ textAlign: "left", fontWeight:'bold' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}> <QRCode size='50' value={FbrNumber}/></span></div>
                            :
                            <div></div>
                            } */}
            </div>
            <div id="secProject">
              <div id="project2">
                <div>
                  <h6 style={{ fontSize: 13 }}>Payment Type</h6>
                  {data.orderDetail && data.orderDetail.payment_method}
                </div>
              </div>
              <div id="project2">
                <div>
                  <h6 style={{ fontSize: 13 }}>Ordering Channel</h6>
                  {data.orderDetail && data.orderDetail.order_channel}
                </div>
              </div>
              <div id="project2">
                <div>
                  <h6 style={{ fontSize: 13 }}>Ordering Mode</h6>
                  {data.orderDetail && data.orderDetail.delivery_status}
                </div>
              </div>
            </div>
          </header>
          <main>
            <table className="invoicetable">
              <thead>
                <tr>
                  {/* <th className="service" style={{ width: "5vw" }}></th> */}
                  <th className="desc">Name</th>
                  {/* <th className="desc">DESCRIPTION</th> */}
                  {/* <th>PRICE</th> */}
                  {/* <th style={{ width: 50 }}>QTY</th> */}
                  <th style={{ width: 100 }}>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {data.Items.map((obj: any, index: any) => {
                  let AddOnsPrice: any = 0;
                  const ingredients =
                    obj.combo_choices &&
                    JSON.parse(obj.combo_choices).map((element: any) => {
                      return (
                        <li>
                          <b>{element.quantity * obj.order_item_quantity} </b>
                          {element.item_name} (
                          {element.size && JSON.parse(element.size).size})
                          {element.extra_price > 0 &&
                            `+${currency}.${element.extra_price * obj.order_item_quantity}`}
                        </li>
                      );
                    });
                  return (
                    <>
                      <tr key={index}>
                        {/* <td className="service">{index + 1}</td> */}
                        <td
                          className="desc"
                          title={
                            (obj.combo_name !== "" && obj.combo_name) ||
                            (obj.item_name !== "" &&
                              `${obj.item_name}(${JSON.parse(obj.item_size).size})`)
                          }
                        >
                          <b>
                            {(obj.combo_name !== "" && obj.combo_name) ||
                              (obj.item_name !== "" &&
                                `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}{" "}
                            x {obj.order_item_quantity}
                          </b>
                          <ul>
                            {ingredients}
                            <br />
                            <b>
                              {obj.modifiers &&
                                JSON.parse(obj.modifiers).length > 0 &&
                                "Add Ons"}
                            </b>
                          </ul>
                        </td>
                        {/* <td className="desc" title={modifiers}>{modifiers}</td> */}
                        {/* <td className="unit">{Math.round(obj.order_item_grossprice / obj.order_item_quantity)}</td> */}
                        {/* <td className="qty">{obj.order_item_quantity}</td> */}
                        <td className="total">
                          {obj.modifiers &&
                            JSON.parse(obj.modifiers).map((element: any) => {
                              AddOnsPrice +=
                                element.quantity * element.modifier_sale_price;
                            })}
                          {currency}.{obj.order_item_grossprice}
                        </td>
                      </tr>
                      {obj.modifiers &&
                        JSON.parse(obj.modifiers).map((element: any) => {
                          return (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                <b>{element.quantity} </b>
                                {element.modifier_name}
                              </td>
                              <td>
                                {currency}.
                                {element.modifier_sale_price * element.quantity}
                              </td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td>Total</td>
                        <td>
                          {currency}.{obj.order_item_grossprice + AddOnsPrice}
                        </td>
                      </tr>
                    </>
                  );
                })}
                {/* <tr>
                                    <td colSpan={1}>Sub Total</td>
                                    <td className="total">{currency}.{data.Items.length > 0 && this.calcSubTotalTotal(data.Items)}</td>
                                </tr> */}
                {data.orderDetail &&
                  data.orderDetail.delivery_status == "Delivery" && (
                    <tr>
                      <td colSpan={1}>Delivery Charges</td>
                      <td className="total">
                        {currency}.
                        {data.orderDetail && data.orderDetail.delivery_fee}
                      </td>
                    </tr>
                  )}
                {data.orderDetail.coupon && (
                  <tr>
                    <td colSpan={1} className="discount">
                      Your Discount
                    </td>
                    <td className="discount">
                      {currency}.
                      {Math.round(data.orderDetail.coupon_discount_value)}{" "}
                    </td>{" "}
                    {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                  </tr>
                )}

                <tr>
                  <td colSpan={1}>{data.orderDetail.tax_percent}% Tax</td>
                  <td>
                    {currency}.{Math.round(data.orderDetail.tax_amount)}{" "}
                  </td>{" "}
                  {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                </tr>
                {data.orderDetail.discount > 0 && (
                  <tr>
                    <td colSpan={1} className="discount">
                      Your Discount
                    </td>
                    <td className="discount">
                      {currency}.{Math.round(data.orderDetail.discount)}{" "}
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={1} className="grand total">
                    <b>GRAND TOTAL</b>
                  </td>
                  {/* <td className="grand total"><b>{currency}.{(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + Math.round(data.orderDetail.tax_amount)}</b> </td> */}
                  <td className="grand total">
                    <b>
                      {currency}.{data.orderDetail.order_grossprice}
                    </b>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="text-center mt-3">{aggregator_name}</p>
            <p className="text-center mt-3">
              NTN # {data.orderDetail.ntn_number}
            </p>
            {/* <p className="text-center mt-3">https://optp.biz/</p> */}
            <h6 className="text-center mt-3">{data.orderDetail.contact1}</h6>
            <footer className="invoicefooter">
              "Thank you for Ordering {aggregator_name}"
            </footer>
          </main>
        </div>
      </div>
    );
  }
}
export default Invoice;
