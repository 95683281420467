import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import { paymentsList, logoutUser, brandsList } from "../../redux";
import { PaymentProps } from "../../interfaces/payment";
import moment from "moment";
import { currency } from "../../client-config";
import Select from "react-select";

function priceFormatter(cell: any) {
  return `${currency}.${cell}`;
}
class Payments extends Component<PaymentProps, { brand_id: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      brand_id: "",
    };
  }
  componentDidMount() {
    this.props.brandsList();
    document.title = "SimpleXSync | Payments";
  }
  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
    this.props.paymentsList(e.value);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Payments Not Found",
    };
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Payments Management</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="form-control-label">
                        Select Brand <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="brands"
                        options={selectedBrands}
                        className="text-capitalize basic-multi-select mt-2"
                        classNamePrefix="select"
                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          data={this.props.data}
                          search={true}
                          pagination={this.props.data.length > 10 && true}
                          options={options}
                          exportCSV={true}
                          csvFileName="payments.csv"
                          hover
                        >
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="80"
                            tdStyle={{ fontSize: 10 }}
                            dataField="payment_id"
                            csvHeader="#"
                            dataSort={true}
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10 }}
                            dataField="order_id"
                            csvHeader="OrderId"
                            columnTitle
                          >
                            OrderId
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10, whiteSpace: "normal" }}
                            dataField="cardOrderId"
                            csvHeader="Transaction Id"
                            columnTitle
                          >
                            Transaction Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10, whiteSpace: "normal" }}
                            dataField="payment_method"
                            csvHeader="Payment Method"
                            columnTitle
                          >
                            Payment Method
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10, whiteSpace: "normal" }}
                            dataField="store_name"
                            csvHeader="Store Name"
                            columnTitle
                          >
                            Store Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10, whiteSpace: "normal" }}
                            dataField="branch_code"
                            csvHeader="Branch Code"
                            columnTitle
                          >
                            Branch Code
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10, whiteSpace: "normal" }}
                            dataField="first_name"
                            csvHeader="Name"
                            columnTitle
                          >
                            Customer
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10, whiteSpace: "normal" }}
                            dataField="phone_number"
                            csvHeader="Phone"
                            columnTitle
                          >
                            Phone
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataField='card_type' csvHeader='Card Type' width='100' columnTitle>Card Type</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataField='card_brand' csvHeader='Card Brand' width='100' columnTitle>Card Brand</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataField='card_last_digits' csvHeader='Card Last Digits' width='120' columnTitle>Card Last Digits</TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10, whiteSpace: "normal" }}
                            dataField="payment_amount"
                            dataFormat={priceFormatter}
                            csvHeader="Amount"
                            columnTitle
                          >
                            Amount
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataField='payment_failure_code' csvHeader='Expiry' width='100' columnTitle>Failure Code</TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10, whiteSpace: "normal" }}
                            dataField="payment_status"
                            dataFormat={statusFormatter}
                            csvHeader="Status"
                            columnTitle
                          >
                            Payment Status
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              whiteSpace: "normal",
                            }}
                            width="100"
                            tdStyle={{ fontSize: 10, whiteSpace: "normal" }}
                            dataFormat={dateFormatter}
                            dataField="date_modified"
                            csvHeader="Date"
                            columnTitle
                          >
                            DateTime
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
function dateFormatter(cell: any) {
  var DateModified: any = moment(cell)
    .local()
    .format("YYYY-MM-DD HH:mm")
    .split(" ");
  DateModified = DateModified[1] + " , " + DateModified[0];
  return (
    <div>
      <span {...(cell !== "" && cell !== null && { title: DateModified })}>
        {" "}
        {cell !== "" && cell !== null && DateModified}{" "}
      </span>
    </div>
  );
}
function statusFormatter(cell: any) {
  return (
    <div className="text-capitalize">
      <span
        {...((cell === "succeeded" && {
          className: "badge badge-success p-2",
        }) ||
          (cell === "pending" && { className: "badge badge-info p-2" }) ||
          (cell === "cancelled" && { className: "badge badge-danger p-2" }))}
      >
        {cell}
      </span>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    data: state.payment.data,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    paymentsList: function (brand_id: any) {
      dispatch(paymentsList(brand_id));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Payments);
