import {
  STORES_LIST,
  GET_STORE,
  ADD_STORE,
  BRANDS_LIST,
  CHANNEL_LIST,
  TYPES_LIST,
  AREAS_LIST,
  COUNTRY_LIST,
  EDIT_STORE,
  GET_BACKUP_STORES,
  STATUS_CHANGE_REASONS,
  UNRESOLVED_ORDER_LENGTH,
  CITIES,
  GET_TRADE_AREAS,
  FP_MENU_LOGS,
  PING_RESPONSE,
} from "./storeType";
import Api from "../../components/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt from "jsonwebtoken";
toast.configure();
export const storesList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/stores", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          response.data.successResponse.forEach((element: any) => {
            element.label = element.store_name;
            element.value = element.store_id;
          });
          dispatch({
            type: STORES_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const syncStoresWithAggregators = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post(`/admin/sync_stores_with_simplex_sync`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          toast.info(response.data.successResponse, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const storesListByBrand = (brand_id: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/stores/" + brand_id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          response.data.successResponse.forEach((element: any) => {
            element.label = element.store_name;
            element.value = element.store_id;
          });
          dispatch({
            type: STORES_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const citiesList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/cities", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CITIES,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const getStore = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/store/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_STORE,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const blockunblockstore = (id: any, isActive: any, data: any) => {
  return function (dispatch: any) {
    let is_active;
    if (isActive === 0) {
      is_active = 1;
    } else if (isActive === 1) {
      is_active = 0;
    }
    let token: any = sessionStorage.getItem("token");
    Api.put(
      `/admin/store_block_unblock/${id}`,
      {
        is_active: is_active,
      },
      {
        headers: { Authorization: "Bearer " + token },
      },
    )
      .then((response) => {
        if (response.data.success) {
          dispatch(saveAgentLogs(data));
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const brandsList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/brands", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: BRANDS_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const channelList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/sales_channel", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          response.data.successResponse.forEach((obj: any) => {
            obj.label = obj.channel_name;
            obj.value = obj.sales_channel_id;
          });
          dispatch({
            type: CHANNEL_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const storeTypeList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/store_types", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          response.data.successResponse.forEach((obj: any) => {
            obj.label = obj.store_type_name;
            obj.value = obj.store_type_id;
          });
          dispatch({
            type: TYPES_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const areasList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/areas", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: AREAS_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const countryList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/countries", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: COUNTRY_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const addStoreOld = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/add_store", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/stores";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
              error = "Please fill in all fields";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: ADD_STORE,
            isInserted: false,
            payload: error,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const addStore = (formData: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/add_store", formData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/stores";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (
              err.response.data.message.indexOf("ER_NO_REFERENCED_ROW_2") > -1
            ) {
              error = "Please fill in all mandatory fields";
            } else if (err.response.data.message.indexOf("ER_DUP_ENTRY") > -1) {
              if (err.response.data.message.indexOf("'branch_code'") > -1) {
                error = "Branch Id already exist";
              } else if (
                err.response.data.message.indexOf(
                  "IDX_53ce48affda3872cdb4bb0dcba",
                ) > -1
              ) {
                error = "Branch Code already exist";
              } else if (err.response.data.message.indexOf("store_name") > -1) {
                error = "Store Name already exist";
              } else {
                error = "Duplication error";
              }
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const editStoreOld = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.put(`/admin/edit_store/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/stores";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
              error = "Please fill in all fields";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: EDIT_STORE,
            isUpdated: false,
            payload: error,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const editStore = (id: any, formData: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.put(`/admin/edit_store/${id}`, formData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/stores";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (
              err.response.data.message.indexOf("ER_NO_REFERENCED_ROW_2") > -1
            ) {
              error = "Please fill in all mandatory fields";
            } else if (err.response.data.message.indexOf("ER_DUP_ENTRY") > -1) {
              if (err.response.data.message.indexOf("'branch_code'") > -1) {
                error = "Branch Id already exist";
              } else if (
                err.response.data.message.indexOf(
                  "IDX_53ce48affda3872cdb4bb0dcba",
                ) > -1
              ) {
                error = "Branch Code already exist";
              } else if (err.response.data.message.indexOf("store_name") > -1) {
                error = "Store Name already exist";
              } else {
                error = "Duplication error";
              }
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};

export const getBackupStores = (id: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/getBackupStores/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_BACKUP_STORES,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const getTradeAreas = (id: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/trade-zone/tradeAreas/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_TRADE_AREAS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const statusChangeReasons = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/reasons/reasonByType", {
      type: "store",
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: STATUS_CHANGE_REASONS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const saveAgentLogs = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/add_logs", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/stores";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
              error = "Please fill in all fields";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
          alert(err.message);
        }
      });
  };
};

export const saveLogs = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post("/admin/add_logs", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          console.log("log success");
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
              error = "Please fill in all fields";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
          alert(err.message);
        }
      });
  };
};

export const UnsuspendStore = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.put(`/store/store_unsuspend/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/stores";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const unresolvedordersList = (Obj: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    dispatch(storesList());
    Api.post(`store/unresolvedorders/${Obj.store_id}`, Obj, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: UNRESOLVED_ORDER_LENGTH,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: UNRESOLVED_ORDER_LENGTH,
            payload: [],
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const fpMenuLogs = (data: any) => {
  return function (dispatch: any) {
    dispatch({
      type: FP_MENU_LOGS,
      pingLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post("/menu/fpmenulogs", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        dispatch({
          type: FP_MENU_LOGS,
          payload: response.data.data,
          pingLoad: false,
        });
        console.log(response.data, "response.data");

        if (response.data.Status == "Fail") {
          toast.error("Ping Failed", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast.success("Ping Successfull", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err, "err");
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0];
          }
          dispatch({
            type: FP_MENU_LOGS,
            payload: null,
            pingLoad: false,
          });
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};

export const updateFPStoreStatus = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = localStorage.getItem("token");
    Api.post(`/menu/aggregator/update_store_status/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          toast.info(response.data.successResponse, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => {
            window.location.href = "/stores";
          }, 1000);
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setTimeout(() => {
            window.location.href = "/stores";
          }, 1000);
        } else {
          alert(err.message);
        }
      });
  };
};
