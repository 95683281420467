/* eslint-disable react/require-render-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  customersReport,
  dailySalesReport,
  logoutUser,
  pmixReport,
  salesmixReport,
} from "../../redux";
import { Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Select from "react-select";
import { secretKey } from "../../secret";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import ReactPaginate from "react-paginate";
import { currency } from "../../client-config";
import { ReportsProps, ReportsState } from "../../interfaces/reports";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// import JSZip from 'jszip';

import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import {
  activityLogsReport,
  AlohaOrderReport,
  cancellationDtetailsReport,
  cancellationReport,
  channelmixReport,
  couponredemptionReport,
  discountReport,
  orderDetailsReport,
  orderSystemIntgReport,
  profitReport,
  riderDetailsReport,
  ridersReport,
  sosReport,
  storesList,
  storesReport,
} from "../../redux/actions/reportAction";
import moment from "moment";
import { toast } from "react-toastify";
import "./reports.css";
import store from "../../redux/store";
import { AlohaOrderDetailsReport } from "./../../redux/actions/reportAction";
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
const $ = require("jquery");
$.DataTable = require("datatables.net");

class Reports extends Component<ReportsProps, ReportsState> {
  storesDropDown: any;
  constructor(props: any) {
    super(props);
    this.state = {
      reportType: "",
      storeType: "",
      allSales: false,
      showDate: false,
      validTimeFlag: false,
      startDate: "",
      validTimeFlagFrom: false,
      validTimeFlagTo: false,
      endDate: "",
      settings: {
        dom: "Bfrtip",
        paginate: false,
        bInfo: false,
        buttons: [
          "copy",
          "excel",
          "print",
          {
            extend: "pdfHtml5",
            orientation: "landscape",
            pageSize: "LEGAL",
            text: "PDF",
            footer: true,
            customize: function (doc: any) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 2,
              )
                .join("*")
                .split("");
            },
          },
        ],
        footerCallback: function (
          tfoot: any,
          data: any,
          start: any,
          end: any,
          display: any,
        ) {
          var api = this.api(),
            data;
          // Total over all pages
          $(api.column(5).footer()).html(
            `${currency}.` +
              api
                .column(5)
                .data()
                .reduce(function (a: any, b: any) {
                  return parseInt(a) + parseInt(b);
                }, 0),
          );
        },
      },
    };
    this.handleValidTime = this.handleValidTime.bind(this);
    this.reportGenerator = this.reportGenerator.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    document.title = "SimpleXSync | Reports";
    this.props.storesList();
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    let { reportType, settings } = this.state;
    if (nextProps.isLoad == false && this.props.isLoad == true) {
      if (reportType === "sales") {
        setTimeout(
          function () {
            $("#example").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "customer") {
        setTimeout(
          function () {
            $("#customerreports").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "product") {
        setTimeout(
          function () {
            $("#pmix").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "salesmix") {
        setTimeout(
          function () {
            $("#smix").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "channelmix") {
        setTimeout(
          function () {
            $("#cmix").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "couponredemption") {
        setTimeout(
          function () {
            $("#couponred").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "profit") {
        setTimeout(
          function () {
            $("#profitreports").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "discount") {
        setTimeout(
          function () {
            $("#discountreports").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "sosReport") {
        setTimeout(
          function () {
            $("#sosreports").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "riders") {
        setTimeout(
          function () {
            $("#ridersReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "store") {
        setTimeout(
          function () {
            $("#storeReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "cancel_smry") {
        setTimeout(
          function () {
            $("#cancel_smryReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "cancel_details") {
        setTimeout(
          function () {
            $("#cancel_detailsReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "order_details") {
        setTimeout(
          function () {
            $("#order_detailsReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "rider_details") {
        setTimeout(
          function () {
            $("#rider_detailsReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "activity_logs") {
        setTimeout(
          function () {
            $("#activity_logsReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "aloha_orders") {
        setTimeout(
          function () {
            $("#aloha_ordersReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "aloha_order_details") {
        setTimeout(
          function () {
            $("#aloha_order_detailsReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      } else if (reportType === "system_intrgrated_orders") {
        setTimeout(
          function () {
            $("#system_IntegratedOrdersReport").DataTable(settings, {
              responsive: true,
              deferRender: true,
            });
          }.bind(this),
          1500,
        );
      }
    }
  }
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.value > 0) {
      this.setState({ storeType: e.value });
    } else {
      this.setState({ storeType: " " });
    }
    //console.log("E ", e.value);
  };

  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (this.state.storeType === 404) {
      this.setState({ storeType: "" });
      this.storesDropDown.select.clearValue();
    }
    if (event.target.value === "sales") {
      this.setState({ showDate: true });
    } else if (event.target.value === "customer") {
      this.setState({ showDate: true });
    } else if (event.target.value === "product") {
      this.setState({ showDate: true });
    } else if (event.target.value === "salesmix") {
      this.setState({ showDate: true });
    } else if (event.target.value === "channelmix") {
      this.setState({ showDate: true });
    } else if (event.target.value === "couponredemption") {
      this.setState({ showDate: true });
    } else if (event.target.value === "profit") {
      this.setState({ showDate: true });
    } else if (event.target.value === "discount") {
      this.setState({ showDate: true, storeType: "" });
    } else if (event.target.value === "sosReport") {
      this.setState({ showDate: true });
    } else if (event.target.value === "riders") {
      this.setState({ showDate: true });
    } else if (event.target.value === "store") {
      this.setState({ showDate: true });
    } else if (event.target.value === "cancel_smry") {
      this.setState({ showDate: true });
    } else if (event.target.value === "cancel_details") {
      this.setState({ showDate: true });
    } else if (event.target.value === "order_details") {
      this.setState({ showDate: true });
    } else if (event.target.value === "rider_details") {
      this.setState({ showDate: true });
    } else if (event.target.value === "activity_logs") {
      this.setState({ showDate: true });
    } else if (event.target.value === "aloha_orders") {
      this.setState({ showDate: true });
    } else if (event.target.value === "aloha_order_details") {
      this.setState({ showDate: true });
    } else if (event.target.value === "system_intrgrated_orders") {
      this.setState({ showDate: true });
    }
  }

  handleValidTime(event: { target: { name: any; value: any } }) {
    let selectedDateTime = new Date(event.target.value);
    let validMinDateTime = new Date();
    if (selectedDateTime <= validMinDateTime) {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name == "startDate") {
        this.setState({ validTimeFlagFrom: true });
      } else {
        this.setState({ validTimeFlagTo: true });
      }
    } else {
      toast.error("Invalid date and time selected");
      if (event.target.name == "startDate") {
        this.setState({ validTimeFlagFrom: false });
      } else {
        this.setState({ validTimeFlagTo: false });
      }
    }
  }

  isReportReady = () => {
    let { reportType, startDate, endDate, validTimeFlagFrom, validTimeFlagTo } =
      this.state;
    if (
      reportType === "sales" &&
      startDate !== "" &&
      endDate !== "" &&
      validTimeFlagFrom &&
      validTimeFlagTo
    ) {
      return reportType !== "";
    } else if (reportType === "customer") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "product") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "salesmix") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "channelmix") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "couponredemption") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "profit") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "discount") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "sosReport") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "riders") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "store") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "cancel_smry") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "cancel_details") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "order_details") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "rider_details") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "activity_logs") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "aloha_orders") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "aloha_order_details") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    } else if (reportType === "system_intrgrated_orders") {
      return (
        reportType !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        validTimeFlagFrom &&
        validTimeFlagTo
      );
    }
  };
  handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    let { reportType, startDate, endDate, settings } = this.state;
    let startDateUtc = moment(startDate).utc(false).format("YYYY-MM-DD HH:mm");
    let endDateUtc = moment(endDate).utc(false).format("YYYY-MM-DD HH:mm");
    let data: any = {
      start: startDateUtc,
      end: endDateUtc,
      store_id: this.state.storeType == "" ? 0 : this.state.storeType,
    };
    let customerData: any = {
      start: startDateUtc,
      end: endDateUtc,
    };
    if (reportType === "customer") {
      this.props.customersReport(customerData, selectedPage);
      var table = $("#customerreports").DataTable();
      table.destroy();
    } else if (reportType === "product") {
      this.props.pmixReport(data, selectedPage);
      var table = $("#pmix").DataTable();
      table.destroy();
    } else if (reportType === "salesmix") {
      this.props.salesmixReport(data, selectedPage);
      var table = $("#smix").DataTable();
      table.destroy();
    } else if (reportType === "discount") {
      this.props.discountReport(data, selectedPage);
      var table = $("#discountreports").DataTable();
      table.destroy();
    } else if (reportType === "sosReport") {
      this.props.sosReport(data, selectedPage);
      var table = $("#sosreports").DataTable();
      table.destroy();
    } else if (reportType === "profit") {
      this.props.profitReport(data, selectedPage);
      var table = $("#profitreports").DataTable();
      table.destroy();
    } else if (reportType === "couponredemption") {
      this.props.couponredemptionReport(data, selectedPage);
      var table = $("#couponred").DataTable();
      table.destroy();
    } else if (reportType === "riders") {
      this.props.ridersReport(data, selectedPage);
      var table = $("#ridersReport").DataTable();
      table.destroy();
    } else if (reportType === "store") {
      this.props.storesReport(data, selectedPage);
      var table = $("#storeReport").DataTable();
      table.destroy();
    } else if (reportType === "cancel_smry") {
      this.props.cancellationReport(data, selectedPage);
      var table = $("#cancel_smryReport").DataTable();
      table.destroy();
    } else if (reportType === "cancel_details") {
      this.props.cancellationDtetailsReport(data, selectedPage);
      var table = $("#cancel_detailsReport").DataTable();
      table.destroy();
    } else if (reportType === "order_details") {
      this.props.orderDetailsReport(data, selectedPage);
      var table = $("#order_detailsReport").DataTable();
      table.destroy();
    } else if (reportType === "rider_details") {
      this.props.riderDetailsReport(data, selectedPage);
      var table = $("#rider_detailsReport").DataTable();
      table.destroy();
    } else if (reportType === "activity_logs") {
      this.props.activityLogsReport(data, selectedPage);
      var table = $("#activity_logsReport").DataTable();
      table.destroy();
    } else if (reportType === "aloha_orders") {
      this.props.AlohaOrderReport(data, selectedPage);
      var table = $("#aloha_ordersReport").DataTable();
      table.destroy();
    } else if (reportType === "aloha_order_details") {
      this.props.AlohaOrderDetailsReport(data, selectedPage);
      var table = $("#aloha_order_detailsReport").DataTable();
      table.destroy();
    } else if (reportType === "system_intrgrated_orders") {
      this.props.orderSystemIntgReport(data, selectedPage);
      var table = $("#system_IntegratedOrdersReport").DataTable();
      table.destroy();
    }
  };

  validateTimeFromTo = () => {
    let { startDate, endDate } = this.state;

    var start = moment(startDate, "YYYY-MM-DD HH:mm:ss");
    var end = moment(endDate, "YYYY-MM-DD HH:mm:ss");
    //Difference in number of days
    let days = moment.duration(end.diff(start)).asDays();

    let days_round = Math.round(days);

    if (startDate >= endDate) {
      toast.error("End date cannot be less than start date");
    } else if (days_round >= 31) {
      toast.error("Maximum 31 days can be selected");
    } else {
      this.reportGenerator();
    }
    // this.setState({ isLoad: true })
  };
  reportGenerator() {
    let { reportType, startDate, endDate, settings } = this.state;
    let {
      dailyReports,
      sosReports,
      rider_details_report,
      aloha_orders,
      aloha_order_details,
      activity_logs,
      order_details_report,
      cancel_detail_report,
      storesReports,
      ridersReports,
      cancel_smryReports,
      customerReports,
      pmixReports,
      profitReports,
      smixReports,
      channelmixReports,
      couponredemptionReports,
      discountReports,
      system_intrgrated_orders,
    } = this.props;
    dailyReports.length = 0;
    sosReports.length = 0;
    aloha_order_details.length = 0;
    aloha_orders.length = 0;
    rider_details_report.length = 0;
    activity_logs.length = 0;
    order_details_report.length = 0;
    cancel_smryReports.length = 0;
    cancel_detail_report.length = 0;
    storesReports.length = 0;
    ridersReports.length = 0;
    customerReports.length = 0;
    pmixReports.length = 0;
    smixReports.length = 0;
    channelmixReports.length = 0;
    couponredemptionReports.length = 0;
    profitReports.length = 0;
    discountReports.length = 0;
    system_intrgrated_orders.length = 0;
    let startDateUtc = moment(startDate).utc(false).format("YYYY-MM-DD HH:mm");
    let endDateUtc = moment(endDate).utc(false).format("YYYY-MM-DD HH:mm");
    let data: any = {
      start: startDateUtc,
      end: endDateUtc,
      store_id: this.state.storeType == "" ? 0 : this.state.storeType,
    };
    if (reportType === "sales") {
      this.props.dailySalesReport(data);
      var table = $("#example").DataTable();
      table.destroy();
    } else if (reportType === "customer") {
      let customerData = {
        start: startDateUtc,
        end: endDateUtc,
      };
      this.props.customersReport(customerData, 0);
      var table = $("#customerreports").DataTable();
      table.destroy();
    } else if (reportType === "product") {
      this.props.pmixReport(data, 0);
      var table = $("#pmix").DataTable();
      table.destroy();
    } else if (reportType === "salesmix") {
      this.props.salesmixReport(data, 0);
      var table = $("#smix").DataTable();
      table.destroy();
    } else if (reportType === "channelmix") {
      this.props.channelmixReport(data);
      var table = $("#cmix").DataTable();
      table.destroy();
    } else if (reportType === "couponredemption") {
      this.props.couponredemptionReport(data, 0);
      var table = $("#couponred").DataTable();
      table.destroy();
    } else if (reportType === "profit") {
      this.props.profitReport(data, 0);
      var table = $("#profitreports").DataTable();
      table.destroy();
    } else if (reportType === "discount") {
      this.props.discountReport(data, 0);
      var table = $("#discountreports").DataTable();
      table.destroy();
    } else if (reportType === "sosReport") {
      this.props.sosReport(data, 0);
      var table = $("#sosreports").DataTable();
      table.destroy();
    } else if (reportType === "riders") {
      this.props.ridersReport(data, 0);
      var table = $("#ridersReport").DataTable();
      table.destroy();
    } else if (reportType === "store") {
      this.props.storesReport(data, 0);
      var table = $("#storeReport").DataTable();
      table.destroy();
    } else if (reportType === "cancel_smry") {
      this.props.cancellationReport(data, 0);
      var table = $("#cancel_smryReport").DataTable();
      table.destroy();
    } else if (reportType === "cancel_details") {
      this.props.cancellationDtetailsReport(data, 0);
      var table = $("#cancel_detailsReport").DataTable();
      table.destroy();
    } else if (reportType === "order_details") {
      this.props.orderDetailsReport(data, 0);
      var table = $("#order_detailsReport").DataTable();
      table.destroy();
    } else if (reportType === "rider_details") {
      this.props.riderDetailsReport(data, 0);
      var table = $("#rider_detailsReport").DataTable();
      table.destroy();
    } else if (reportType === "activity_logs") {
      this.props.activityLogsReport(data, 0);
      var table = $("#activity_logsReport").DataTable();
      table.destroy();
    } else if (reportType === "aloha_orders") {
      this.props.AlohaOrderReport(data, 0);
      var table = $("#aloha_ordersReport").DataTable();
      table.destroy();
    } else if (reportType === "aloha_order_details") {
      this.props.AlohaOrderDetailsReport(data, 0);
      var table = $("#aloha_order_detailsReport").DataTable();
      table.destroy();
    } else if (reportType === "system_intrgrated_orders") {
      this.props.orderSystemIntgReport(data, 0);
      var table = $("#system_IntegratedOrdersReport").DataTable();
      table.destroy();
    }
  }

  render() {
    let {
      dailyReports,
      sosReports,
      activity_logs,
      aloha_orders,
      aloha_order_details,
      rider_details_report,
      order_details_report,
      cancel_detail_report,
      cancel_smryReports,
      storesReports,
      ridersReports,
      currentPage,
      numOfRows,
      pageCount,
      stores,
      customerReports,
      pmixReports,
      smixReports,
      channelmixReports,
      couponredemptionReports,
      profitReports,
      discountReports,
      msg,
      system_intrgrated_orders,
    } = this.props;
    let { reportType } = this.state;
    let tokendata: any = jwt.decode(sessionStorage.token);
    let roleId = tokendata.role_id;
    let options = stores;
    let maxDate = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS");
    let newObj = {
      value: 404,
      label: "All Store",
    };
    if (options.length > 0) {
      if (reportType == "sales" && options[0].value != 404) {
        options = stores;
        options.unshift(newObj);
      } else if (reportType != "sales") {
        options = options.filter((opt: any) => {
          return opt.label != "All Store";
        });
      }
    }

    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header pb-1">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>Reports Management</h4>
                </div>
              </div>
            </header>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p className="text-center font-weight-light text-danger mb-4">
                            {msg}
                          </p>
                        </strong>

                        <div className="form-horizontal">
                          <div className="row form-group">
                            <label
                              className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                              style={{ paddingTop: "1em" }}
                            >
                              Report Type
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9 col-sm-9">
                              <select
                                name="reportType"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select brand"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Report Type</option>
                                <optgroup label="Sales">
                                  <option value="sales">Total Sales</option>
                                  <option value="salesmix">Sales Mix</option>
                                  <option value="customer">Customer</option>
                                </optgroup>
                                <optgroup label="Product">
                                  <option value="product">Product Mix</option>
                                </optgroup>
                                <optgroup label="SOS">
                                  <option value="sosReport">
                                    Speed Of Services
                                  </option>
                                </optgroup>
                                <optgroup label="Channel">
                                  <option value="channelmix">
                                    Channel Mix
                                  </option>
                                </optgroup>
                                <optgroup label="Coupon">
                                  <option value="couponredemption">
                                    Coupon Redemption
                                  </option>
                                </optgroup>
                                <optgroup label="Profit">
                                  <option value="profit">Profit Margin</option>
                                </optgroup>
                                <optgroup label="Discount">
                                  <option value="discount">Discount</option>
                                </optgroup>
                                <optgroup label="Riders Efficiency">
                                  <option value="riders">
                                    Riders Efficiency Summary
                                  </option>
                                  <option value="rider_details">
                                    Riders Efficiency Details
                                  </option>
                                </optgroup>
                                <optgroup label="Stores">
                                  <option value="store">
                                    Stores Efficiency
                                  </option>
                                </optgroup>
                                <optgroup label="Order Details">
                                  <option value="order_details">
                                    Order Details
                                  </option>
                                </optgroup>
                                <optgroup label="Cancellation Report ">
                                  <option value="cancel_smry">
                                    Order Cancellation Summary
                                  </option>
                                  <option value="cancel_details">
                                    Order Cancellation Details
                                  </option>
                                </optgroup>
                                {/* <optgroup label="Aloha Data Report ">
                                  <option value="aloha_orders">
                                    Aloha Orders
                                  </option>
                                  <option value="aloha_order_details">
                                    Aloha Orders Details
                                  </option>
                                </optgroup> */}
                                {(roleId == 1 || roleId == 7) && (
                                  <optgroup label="Activity Logs">
                                    <option value="activity_logs">
                                      Activity Logs
                                    </option>
                                  </optgroup>
                                )}
                                {(roleId == 1 || roleId == 7) && (
                                  <optgroup label="Integration Orders">
                                    <option value="system_intrgrated_orders">
                                      Failed Orders
                                    </option>
                                  </optgroup>
                                )}
                              </select>
                            </div>
                          </div>
                          {this.state.reportType !== "customer" &&
                            this.state.reportType !== "discount" &&
                            this.state.reportType !== "store" &&
                            this.state.reportType !== "activity_logs" &&
                            this.state.reportType !==
                              "aggregator_failed_orders" &&
                            this.state.reportType !== "aggr_data_export" &&
                            this.state.reportType !==
                              "system_intrgrated_orders" && (
                              <div className="row form-group">
                                <label
                                  className="col-md-3 col-sm-3 form-control-label font-weight-bold"
                                  style={{ paddingTop: "1em" }}
                                >
                                  Report By Stores
                                </label>
                                <div className="col-md-9 col-sm-9">
                                  <Select
                                    //isMulti
                                    ref={(el) => (this.storesDropDown = el)}
                                    name="storeType"
                                    isClearable
                                    options={options}
                                    className="text-capitalize select mt-2"
                                    classNamePrefix="select"
                                    onChange={(e, i) =>
                                      this.handleStoresInputChange(e, i)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          {this.state.showDate == true && (
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label font-weight-bold">
                                    Start Date
                                    <small className="text-danger">*</small>
                                  </label>
                                  <input
                                    id="start"
                                    type="datetime-local"
                                    name="startDate"
                                    max={maxDate}
                                    required
                                    data-msg="Please enter Start Date"
                                    className="input-material"
                                    onChange={this.handleValidTime}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label font-weight-bold">
                                    End Date
                                    <small className="text-danger">*</small>
                                  </label>
                                  <input
                                    id="end"
                                    type="datetime-local"
                                    name="endDate"
                                    max={maxDate}
                                    required
                                    data-msg="Please enter End Date"
                                    className="input-material"
                                    onChange={this.handleValidTime}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="form-group float-right mt-3">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isReportReady()}
                            onClick={this.validateTimeFromTo}
                          >
                            Generate Report
                          </button>
                        </div>
                      </div>
                      {this.props.isLoad == true ? (
                        <div className="d-flex justify-content-center mb-4">
                          <Loader
                            type="TailSpin"
                            color="#007bff"
                            height={50}
                            width={50}
                          />
                        </div>
                      ) : (
                        <div className="row">
                          {dailyReports && dailyReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="example"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          {...(this.state.storeType == "" && {
                                            style: { display: "none" },
                                          })}
                                        >
                                          {" "}
                                          {this.state.storeType != ""
                                            ? "Store"
                                            : null}
                                        </th>
                                        <th>Payment Method</th>
                                        <th>Void</th>
                                        <th>Discount</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {dailyReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td
                                              {...(this.state.storeType ==
                                                "" && {
                                                style: { display: "none" },
                                              })}
                                            >
                                              {this.state.storeType != ""
                                                ? data.store_name
                                                : null}
                                            </td>
                                            <td>{data.payment_method}</td>
                                            <td>{Math.round(data.Void)}</td>
                                            <td>{data.Discount}</td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td>{Math.round(data.total)}</td>
                                          </tr>
                                        ),
                                      )}
                                      {/* <span>Grand Total</span><span id="val">{sumVal.toFixed(2)}</span> */}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th
                                          {...(this.state.storeType == "" && {
                                            style: { display: "none" },
                                          })}
                                        ></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : pmixReports && pmixReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="pmix"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Item Name</th>
                                        <th>Group Name</th>
                                        <th>Channel</th>
                                        <th>Quantity Sold</th>
                                        <th>Quantity Percent</th>
                                        <th>Total Sales ({currency})</th>
                                        <th>Sales Percent</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {pmixReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>
                                              {data.product_name}{" "}
                                              {data.variant_name
                                                ? `(${data.variant_name})`
                                                : ""}{" "}
                                            </td>
                                            <td>{data.group_name}</td>
                                            <td>{data.order_channel}</td>
                                            <td>{data.quantitySold}</td>
                                            <td>
                                              {data.quantitymixpercent
                                                ? Math.round(
                                                    data.quantitymixpercent,
                                                  )
                                                : 0}{" "}
                                              %
                                            </td>
                                            <td>
                                              {Math.round(data.totalSales)}
                                            </td>
                                            <td>
                                              {data.salesmixpercent
                                                ? Math.round(
                                                    data.salesmixpercent,
                                                  )
                                                : 0}{" "}
                                              %
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : customerReports && customerReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="customerreports"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                        <th>Coupon Redeemed {currency}</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Order Amount ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {customerReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.login_name}</td>
                                            <td>{data.email_address}</td>
                                            <td>{data.phone_number}</td>
                                            <td>{data.couponTotal}</td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td>
                                              {Math.round(data.OrderAmount)}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : smixReports && smixReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="smix"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Item Name</th>
                                        <th>Group Name</th>
                                        <th>Quantity Sold</th>
                                        <th>Quantity Mix Percentage</th>
                                        <th>Sales Mix Percentage</th>
                                        <th>Total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {smixReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.product_name}</td>
                                            <td>{data.group_name}</td>
                                            <td>{data.quantitySold}</td>
                                            <td>
                                              {data.quantitymixpercent
                                                ? Math.round(
                                                    data.quantitymixpercent,
                                                  )
                                                : 0}{" "}
                                              %
                                            </td>
                                            <td>
                                              {data.salesmixpercent
                                                ? Math.round(
                                                    data.salesmixpercent,
                                                  )
                                                : 0}{" "}
                                              %
                                            </td>
                                            <td>
                                              {Math.round(data.totalSales)}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : channelmixReports &&
                            channelmixReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="cmix"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Ticket Average</th>
                                        <th>Order Channel</th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {channelmixReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.avgTicket}</td>
                                            <td>{data.order_channel}</td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td>{Math.round(data.total)}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : couponredemptionReports &&
                            couponredemptionReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="couponred"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Store Name</th>
                                        <th>Order Channel</th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th>
                                          Total Coupon Redeemed ({currency})
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {couponredemptionReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.store_name}</td>
                                            <td>{data.order_channel}</td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td>
                                              {data.couponTotal
                                                ? Math.round(data.couponTotal)
                                                : 0}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : profitReports && profitReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="profitreports"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Product Name</th>
                                        <th>Quantity (Sold Unit)</th>
                                        <th>Profit Margin</th>
                                        <th>Profit %</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {profitReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>
                                              {data.combo_name
                                                ? data.combo_name
                                                : data.item_name}
                                            </td>
                                            {/* {data.item_name &&
                                              <td>{data.item_name}</td>
                                            } */}
                                            <td>{data.quantitySold}</td>
                                            <td>
                                              {data.profitMargin == "NaN"
                                                ? 0
                                                : data.profitMargin}
                                            </td>
                                            <td>
                                              {data.profitPercent == "NaN"
                                                ? 0
                                                : Math.round(
                                                    data.profitPercent,
                                                  )}{" "}
                                              %
                                            </td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td>
                                              {data.totalSales
                                                ? Math.round(data.totalSales)
                                                : 0}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : discountReports && discountReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="discountreports"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Customer Name</th>
                                        <th>Total Discount ({currency})</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Discount %</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Total Sales ({currency})</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {discountReports.map(
                                        (data: any, index: any) => (
                                          <tr>
                                            <td>{data.customer}</td>
                                            <td>{data.totalDiscount}</td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td>
                                              {data.discountPercent == "NaN"
                                                ? 0
                                                : Math.round(
                                                    data.discountPercent,
                                                  )}{" "}
                                              %
                                            </td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td>
                                              {data.totalSales
                                                ? Math.round(data.totalSales)
                                                : ""}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                        <th style={{ display: "none" }}></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : sosReports && sosReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="sosreports"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Order Id</th>
                                        <th>Order Type</th>
                                        <th>Pending Time</th>
                                        <th>Kitchen Time</th>
                                        <th>Dispatched Time</th>
                                        <th>Total Delivery Time</th>
                                        <th>Channel</th>
                                        <th>Payment Method</th>
                                        <th>Order Recieved</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {sosReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.order_id}</td>
                                            <td>{data.delivery_status}</td>
                                            <td>{data.kitchen}</td>
                                            <td>{data.dispatched}</td>
                                            <td>{data.delivered}</td>
                                            <td>{Math.round(data.total)}</td>
                                            <td>{data.order_channel}</td>
                                            <td>{data.payment_method}</td>
                                            <td>{data.date_created}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : ridersReports && ridersReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="ridersReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Riders</th>
                                        <th>Total Orders</th>
                                        <th>Avg Deilvery Time</th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Order Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ridersReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.Rider}</td>
                                            <td>{data.totalOrders}</td>
                                            <td>{data.avgDeliveryTime}</td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td>
                                              {data.totalSale
                                                ? Math.round(data.totalSale)
                                                : ""}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : rider_details_report &&
                            rider_details_report.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="rider_detailsReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Riders</th>
                                        <th>Trade Zone</th>
                                        <th>Dispatched Time</th>
                                        <th>Delivered Time</th>
                                        <th>Order Time</th>
                                        <th>Order Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rider_details_report.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.Rider}</td>
                                            <td>{data.trade_zone_name}</td>
                                            <td>{data.dispatched}</td>
                                            <td>{data.delivered}</td>
                                            <td>
                                              {moment(data.date_created)
                                                .local()
                                                .format("DD MMM YYYY HH:mm")}
                                            </td>
                                            <td>
                                              {data.order_amount
                                                ? Math.round(data.order_amount)
                                                : ""}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : storesReports && storesReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="storeReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Stores</th>
                                        <th>Avg Pending Time</th>
                                        <th>Avg Kitchen Time</th>
                                        <th>Avg Dispatch Time</th>
                                        <th>Avg Deilvery Time</th>
                                        <th>Total Sales</th>
                                        <th>Total Orders</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {storesReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.Store}</td>
                                            <td>{data.avgPendingTime}</td>
                                            <td>{data.avgKitchenTime}</td>
                                            <td>{data.avgDispatchTime}</td>
                                            <td>{data.avgDeliveryTime}</td>
                                            <td>
                                              {data.totalSale
                                                ? Math.round(data.totalSale)
                                                : 0}
                                            </td>
                                            <td>
                                              {data.totalOrders
                                                ? data.totalOrders
                                                : 0}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th>Grand Total</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : cancel_smryReports &&
                            cancel_smryReports.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="cancel_smryReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Stores</th>
                                        <th>Reason</th>
                                        <th>Cancel Count</th>
                                        <th>Cancel Count Percentage</th>
                                        <th style={{ display: "none" }}></th>
                                        <th style={{ display: "none" }}></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {cancel_smryReports.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.Store}</td>
                                            <td>{data.reason}</td>
                                            <td>{data.cancelCount}</td>
                                            <td>{data.cancelCountPer}</td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : cancel_detail_report &&
                            cancel_detail_report.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="cancel_detailsReport"
                                    style={{
                                      display: "block",
                                      overflowX: "auto",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Order Id</th>
                                        <th>Stores</th>
                                        <th>Reason</th>
                                        <th
                                          style={{ width: "300px !important" }}
                                        >
                                          Description
                                        </th>
                                        <th>Driver</th>
                                        <th>User</th>
                                        <th>Customer Name</th>
                                        <th>Contact Number</th>
                                        <th>Order Time</th>
                                        <th>Cancellation Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {cancel_detail_report.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.order_id}</td>
                                            <td>{data.Store}</td>
                                            <td>{data.reason}</td>
                                            {/* <td title={`${data.cancel_reason_description}`}>{(data.cancel_reason_description && data.cancel_reason_description.length > 20) ? `${data.cancel_reason_description.slice(0, 20)}` : data.cancel_reason_description}</td> */}
                                            <td
                                              style={{
                                                width: "300px !important",
                                                whiteSpace: "nowrap",
                                              }}
                                              title={`${data.cancel_reason_description}`}
                                            >
                                              {data.cancel_reason_description &&
                                                data.cancel_reason_description}
                                            </td>
                                            <td>{data.driver}</td>
                                            <td>{data.user}</td>
                                            <td>{data.login_name}</td>
                                            <td>{data.phone_number}</td>
                                            <td>
                                              {moment(data.order_time)
                                                .local()
                                                .format("DD MMM YYYY HH:mm")}
                                            </td>
                                            <td>
                                              {moment(data.cancel_time)
                                                .local()
                                                .format("DD MMM YYYY HH:mm")}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : order_details_report &&
                            order_details_report.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="order_detailsReport"
                                    style={{
                                      display: "block",
                                      overflowX: "auto",
                                      whiteSpace: "nowrap",
                                      width: "100%",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Order Id</th>
                                        <th>Channel</th>
                                        <th>Customer Name</th>
                                        <th>Contact Number</th>
                                        <th>City Name</th>
                                        <th>Branch Name</th>
                                        <th>Trade Zone</th>
                                        <th>Trade Area</th>
                                        <th>Delivery Address</th>
                                        <th>Branch Code</th>
                                        <th>Branch Id</th>
                                        <th>Tax</th>
                                        <th>Delivery Fee</th>
                                        <th>Discount</th>
                                        <th>Rider</th>
                                        <th>Amount</th>
                                        <th>Order Time</th>
                                        <th>Pending Time</th>
                                        <th>Kitchen Time</th>
                                        <th>Dispatch Time</th>
                                        <th>Delivered Time</th>
                                        <th>Confirme Date Time</th>
                                        <th>Pending Date Time</th>
                                        <th>Kitchen Date Time</th>
                                        <th>Ready Date Time</th>
                                        <th>Dispatched Date Time</th>
                                        <th>Delivered Date Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {order_details_report.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.order_id}</td>
                                            <td>{data.order_channel}</td>
                                            <td>{data.login_name}</td>
                                            <td>{data.phone_number}</td>
                                            <td>{data.town_city}</td>
                                            <td>{data.store_name}</td>
                                            <td>{data.delivery_zone_name}</td>
                                            <td>{data.area_name}</td>
                                            <td
                                              style={{ whiteSpace: "normal" }}
                                            >
                                              {data.delivery_address}
                                            </td>
                                            <td>{data.branch_code}</td>
                                            <td>{data.branch_id}</td>
                                            <td>{data.tax_amount}</td>
                                            <td>{data.delivery_fee}</td>
                                            <td>{data.discount}</td>
                                            <td>{data.rider}</td>
                                            <td>
                                              {Math.round(
                                                data.order_grossprice,
                                              )}
                                            </td>
                                            <td>
                                              {moment(data.order_time)
                                                .local()
                                                .format("DD MMM YYYY HH:mm")}
                                            </td>
                                            <td>{data.pending}</td>
                                            <td>{data.kitchen}</td>
                                            <td>{data.dispatched}</td>
                                            <td>{data.delivered}</td>
                                            <td>{data.confirmed_datetime}</td>
                                            <td>{data.pending_datetime}</td>
                                            <td>{data.kitchen_datetime}</td>
                                            <td>{data.ready_datetime}</td>
                                            <td>{data.dispatched_datetime}</td>
                                            <td>{data.delivered_datetime}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : activity_logs && activity_logs.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="activity_logsReport"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>User Name</th>
                                        <th>Role</th>
                                        <th>Activity Type</th>
                                        <th>Activity Name</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {activity_logs.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.id}</td>
                                            <td>{data.user_name}</td>
                                            <td>{data.role}</td>
                                            <td>{data.activity_type}</td>
                                            <td title={data.activity_name}>
                                              {data.activity_name &&
                                              data.activity_name.length > 15
                                                ? `${data.activity_name.slice(0, 15)}....`
                                                : data.activity_name}
                                            </td>
                                            <td>{data.status}</td>
                                            <td>
                                              {moment(data.date_created)
                                                .local()
                                                .format("DD MMM YYYY HH:mm")}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : aloha_orders && aloha_orders.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="aloha_ordersReport"
                                    style={{
                                      display: "block",
                                      overflowX: "auto",
                                      whiteSpace: "nowrap",
                                      width: "100%",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Unit</th>
                                        <th>Check Number</th>
                                        <th>Paybal Amount</th>
                                        <th>Discount</th>
                                        <th>Net Amount</th>
                                        <th>Quantity</th>
                                        <th>Tax Percent</th>
                                        <th>Tax Amount</th>
                                        <th>Business Date</th>
                                        <th>Payment Type</th>
                                        <th>Remarks1</th>
                                        <th>Remarks2</th>
                                        <th>AddOn</th>
                                        <th>Order Type Name</th>
                                        <th>Order Status</th>
                                        <th>OrderStatus UpdateOn</th>
                                        <th>Hour</th>
                                        <th>Minute</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {aloha_orders.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.UNIT}</td>
                                            <td>{data.CheckNUmber}</td>
                                            <td>{data.PaybalAmount}</td>
                                            <td>{data.Discount}</td>
                                            <td>{data.NetAmount}</td>
                                            <td>{data.Quantity}</td>
                                            <td>{data.TaxPercent}</td>
                                            <td>{data.TaxAmount}</td>
                                            <td>
                                              {moment(data.BusinessDate)
                                                .local()
                                                .format("DD MMM YYYY HH:mm")}
                                            </td>
                                            <td>{data.PaymentType}</td>
                                            <td>
                                              {data.remarks1
                                                ? data.remarks1
                                                : ""}
                                            </td>
                                            <td>
                                              {data.remarks2 !== null
                                                ? data.remarks2
                                                : ""}
                                            </td>
                                            <td>
                                              {moment(data.AddOn)
                                                .local()
                                                .format("DD MMM YYYY HH:mm")}
                                            </td>
                                            <td>{data.OrderTypeName}</td>
                                            <td>{data.OrderStatus}</td>
                                            <td>
                                              {data.OrderStatusUpdateOn
                                                ? moment(
                                                    data.OrderStatusUpdateOn,
                                                  )
                                                    .local()
                                                    .format("DD MMM YYYY HH:mm")
                                                : ""}
                                            </td>
                                            <td>{data.Hour}</td>
                                            <td>{data.Minute}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : aloha_order_details &&
                            aloha_order_details.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="aloha_order_detailsReport"
                                    style={{
                                      display: "block",
                                      overflowX: "auto",
                                      whiteSpace: "nowrap",
                                      width: "100%",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Unit</th>
                                        <th>Check Number</th>
                                        <th>Business Date</th>
                                        <th>Item Code</th>
                                        <th>Item Name</th>
                                        <th>Item Category Name</th>
                                        <th>Quantity</th>
                                        <th>Tax Percent</th>
                                        <th>Item Price</th>
                                        <th>Tax Amount</th>
                                        <th>Remarks1</th>
                                        <th>Remarks2</th>
                                        <th>AddOn</th>
                                        <th>Order Status</th>
                                        <th>Order Status AddOn</th>
                                        <th>Entry Id</th>
                                        <th>Hour</th>
                                        <th>Minute</th>
                                        <th>Parent</th>
                                        <th>QCID</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {aloha_order_details.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.UNIT}</td>
                                            <td>{data.check_number}</td>
                                            <td>
                                              {moment(data.BusinessDate)
                                                .local()
                                                .format("DD MMM YYYY HH:mm")}
                                            </td>
                                            <td>{data.ItemCode}</td>
                                            <td>{data.ItemName}</td>
                                            <td>{data.ItemCategoryName}</td>
                                            <td>{data.Quantity}</td>
                                            <td>{data.TaxPercent}</td>
                                            <td>{data.ItemPrice}</td>
                                            <td>{data.TaxAmount}</td>
                                            <td>
                                              {data.remarks1 !== null
                                                ? data.remarks1
                                                : ""}
                                            </td>
                                            <td>
                                              {data.remarks2 !== null
                                                ? data.remarks2
                                                : ""}
                                            </td>
                                            <td>
                                              {data.AddOn
                                                ? moment(data.AddOn)
                                                    .local()
                                                    .format("DD MMM YYYY HH:mm")
                                                : ""}
                                            </td>
                                            <td>
                                              {data.OrderStatus
                                                ? data.OrderStatus
                                                : ""}
                                            </td>
                                            <td>
                                              {data.OrderStatusAddOn
                                                ? moment(data.OrderStatusAddOn)
                                                    .local()
                                                    .format("DD MMM YYYY HH:mm")
                                                : ""}
                                            </td>
                                            <td>{data.EntryId}</td>
                                            <td>{data.Hour}</td>
                                            <td>{data.Minute}</td>
                                            <td>{data.Parent}</td>
                                            <td>{data.QCID}</td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : system_intrgrated_orders &&
                            system_intrgrated_orders.length > 0 ? (
                            <div className="col-lg-12 col-12">
                              <div className="card">
                                <div className="card-body text-capitalize">
                                  <table
                                    className="table-custom"
                                    id="system_IntegratedOrdersReport"
                                    style={{
                                      display: "block",
                                      overflowX: "scroll",
                                      whiteSpace: "nowrap",
                                      width: "100%",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>OrderId</th>
                                        <th>API Called</th>
                                        <th>Description</th>
                                        <th>Sync</th>
                                        <th>Response</th>
                                        <th style={{ display: "none" }}></th>
                                        <th>Integration Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {system_intrgrated_orders.map(
                                        (data: any, index: any) => (
                                          <tr key={index}>
                                            <td>{data.order_id}</td>
                                            <td>
                                              <span
                                                className={
                                                  data.api_called == 1
                                                    ? "reports badge-success"
                                                    : "reports badge-fail"
                                                }
                                                style={{ textAlign: "center" }}
                                              >
                                                {data.api_called == 1
                                                  ? "True"
                                                  : "False"}
                                              </span>
                                            </td>
                                            <td>{data.description}</td>
                                            <td>
                                              <span
                                                className={
                                                  data.manual_sync == 0
                                                    ? "reports badge-success"
                                                    : "reports badge-fail"
                                                }
                                                style={{ textAlign: "center" }}
                                              >
                                                {data.manual_sync == 1
                                                  ? "Manual"
                                                  : "System"}
                                              </span>
                                            </td>
                                            <td>{data.response}</td>
                                            <td
                                              style={{ display: "none" }}
                                            ></td>
                                            <td>
                                              {moment(data.date_created)
                                                .local()
                                                .format("DD MMM YYYY HH:mm")}
                                            </td>
                                          </tr>
                                        ),
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {/* Pagination */}
                      {this.props.pageCount > 1 && (
                        <div className="row">
                          <div className="col-12 d-flex flex-row-reverse">
                            <div className="mr-4">
                              <ReactPaginate
                                //initialPage={this.props.currentPage}
                                breakLinkClassName={"page-link"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"...."}
                                breakClassName={"page-item"}
                                pageCount={this.props.pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                disableInitialCallback={true} //Disable onPageChange callback with initial page. Default: false
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    dailyReports: state.report.dailyReports,
    sosReports: state.report.sosReports,
    stores: state.report.stores,
    activity_logs: state.report.activity_logs,
    rider_details_report: state.report.rider_details_report,
    cancel_detail_report: state.report.cancel_detail_report,
    order_details_report: state.report.order_details_report,
    cancel_smryReports: state.report.cancel_smryReports,
    ridersReports: state.report.ridersReports,
    storesReports: state.report.storesReports,
    allstores: state.report.all_stores,
    customerReports: state.report.customerReports,
    pmixReports: state.report.pmixReports,
    smixReports: state.report.smixReports,
    channelmixReports: state.report.channelmixReports,
    couponredemptionReports: state.report.couponredemptionReports,
    profitReports: state.report.profitReports,
    aloha_orders: state.report.aloha_orders,
    aloha_order_details: state.report.aloha_order_details,
    discountReports: state.report.discountReports,
    currentPage: state.report.currentPage,
    pageCount: state.report.pageCount,
    numOfRows: state.report.numOfRows,
    msg: state.report.msg,
    isLoad: state.report.isLoad,
    system_intrgrated_orders: state.report.system_intrgrated_orders,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    dailySalesReport: function (data: any) {
      dispatch(dailySalesReport(data));
    },
    customersReport: function (data: any, page: any) {
      dispatch(customersReport(data, page));
    },
    pmixReport: function (data: any, page: any) {
      dispatch(pmixReport(data, page));
    },
    cancellationDtetailsReport: function (data: any, page: any) {
      dispatch(cancellationDtetailsReport(data, page));
    },
    riderDetailsReport: function (data: any, page: any) {
      dispatch(riderDetailsReport(data, page));
    },
    activityLogsReport: function (data: any, page: any) {
      dispatch(activityLogsReport(data, page));
    },
    cancellationReport: function (data: any, page: any) {
      dispatch(cancellationReport(data, page));
    },
    salesmixReport: function (data: any, page: any) {
      dispatch(salesmixReport(data, page));
    },
    ridersReport: function (data: any, page: any) {
      dispatch(ridersReport(data, page));
    },
    orderDetailsReport: function (data: any, page: any) {
      dispatch(orderDetailsReport(data, page));
    },
    storesReport: function (data: any, page: any) {
      dispatch(storesReport(data, page));
    },
    channelmixReport: function (data: any) {
      dispatch(channelmixReport(data));
    },
    couponredemptionReport: function (data: any, page: any) {
      dispatch(couponredemptionReport(data, page));
    },
    profitReport: function (data: any, page: any) {
      dispatch(profitReport(data, page));
    },
    discountReport: function (data: any, page: any) {
      dispatch(discountReport(data, page));
    },
    sosReport: function (data: any, page: any) {
      dispatch(sosReport(data, page));
    },
    AlohaOrderReport: function (data: any, page: any) {
      dispatch(AlohaOrderReport(data, page));
    },
    AlohaOrderDetailsReport: function (data: any, page: any) {
      dispatch(AlohaOrderDetailsReport(data, page));
    },
    storesList: function () {
      dispatch(storesList());
    },
    orderSystemIntgReport: function (data: any, page: any) {
      dispatch(orderSystemIntgReport(data, page));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
