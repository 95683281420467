import {
  USERS_LIST,
  GET_USER,
  ROLES_LIST,
  GET_ROLE,
  GET_TAX,
  TAX_LIST,
  DISCOUNT_LIST,
  SAVE_DISCOUNT,
  STATES_LIST,
  ADD_STATE,
  GET_STATE,
  BUSINESS_TYPE_LIST,
  REASON_LIST,
  GET_REASON,
  ROLES_FEATURES,
  USERGROUP_LIST,
  GET_USERGROUP,
} from "./settingsType";
import Api from "../../components/Api";
import jwt from "jsonwebtoken";
import { countryList } from "./storeAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storesListForMultiSelectByBrand } from "./menuAction";
toast.configure();

export const usersList = (brand_id: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/users/" + brand_id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: USERS_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const addUser = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.post("/admin/add_user", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/users";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_DUP_ENTRY") {
              error = "User Already Exist";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const editUser = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.put(`/admin/edit_user/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/users";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0];
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const getUser = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/user/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_USER,
            payload: response.data.successResponse,
          });
          dispatch(
            userGroupsListByBrand(response.data.successResponse.brand_id),
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const delUser = (id: number, email_address: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let data = {
      email_address: email_address,
      user_info: jwt.decode(token),
    };
    Api.post(`/admin/del_user/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/users";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const blockunblockuser = (id: any, status: any, email_address: any) => {
  return function (dispatch: any) {
    let is_active: any;
    if (status == 0) {
      is_active = 1;
    } else if (status == 1) {
      is_active = 0;
    }
    let token: any = sessionStorage.getItem("token");
    let data = {
      status: is_active,
      email_address: email_address,
      user_info: jwt.decode(token),
    };
    Api.put(`/admin/user_block_unblock/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/users";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
//User Groups
export const userGroupsList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/user-groups", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          console.log(
            "response.data.successResponse",
            response.data.successResponse,
          );
          dispatch({
            type: USERGROUP_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const userGroupsListByBrand = (brand_id: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/user-groups/" + brand_id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          console.log(
            "response.data.successResponse",
            response.data.successResponse,
          );
          dispatch({
            type: USERGROUP_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const addUserGroup = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.post("/user-groups/add_userGroup", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/usergroups";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_DUP_ENTRY") {
              error = "Group Name Already Exist";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const editUserGroup = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.put(`/user-groups/edit_userGroup/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/usergroups";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_DUP_ENTRY") {
              error = "Group Name Already Exist";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const getUserGroup = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/user-groups/userGroup/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_USERGROUP,
            payload: response.data.successResponse,
          });
          dispatch(
            storesListForMultiSelectByBrand(
              response.data.successResponse.brand_id.brand_id,
            ),
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const delUserGroup = (id: number, group_name: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let data = {
      name: group_name,
      user_info: jwt.decode(token),
    };
    Api.post(`/user-groups/del_userGroup/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/usergroups";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const blockunblockuserGroup = (
  id: any,
  status: any,
  group_name: any,
) => {
  return function (dispatch: any) {
    let is_active: any;
    if (status == 0) {
      is_active = 1;
    } else if (status == 1) {
      is_active = 0;
    }
    let token: any = sessionStorage.getItem("token");
    let data = {
      name: group_name,
      is_active: is_active,
      user_info: jwt.decode(token),
    };
    Api.put(`/user-groups/userGroup_block_unblock/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/usergroups";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const rolesList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/roles", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ROLES_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const addRole = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.post("/admin/add_role", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/roles";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_DUP_ENTRY") {
              error = "Role Already Exist";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const editRole = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.put(`/admin/edit_role/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/roles";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0];
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const getRole = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/role/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_ROLE,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const delRole = (id: number, role_name: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let data = {
      role_name: role_name,
      user_info: jwt.decode(token),
    };
    Api.post(`/admin/del_role/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/roles";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const roleFeatures = (role_id?: any, type?: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let tokendata: any = jwt.decode(token);
    let roleId;
    let obj = {
      role_type: type,
    };
    if (role_id) {
      roleId = role_id;
    } else {
      roleId = token && tokendata.role_id;
    }
    if (roleId) {
      Api.post(`/roles/get-allowed-features/${roleId}`, obj, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: ROLES_FEATURES,
              payload: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: ROLES_FEATURES,
              payload: [],
            });
            console.log(err.response.data.message);
          } else {
            alert(err.message);
          }
        });
    }
  };
};

export const roleFeaturesByBrand = (
  brand_id: any,
  role_id?: any,
  type?: any,
) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let tokendata: any = jwt.decode(token);
    let roleId;
    let obj = {
      role_type: type,
    };
    if (role_id) {
      roleId = role_id;
    } else {
      roleId = token && tokendata.role_id;
    }
    if (roleId) {
      Api.post(`/roles/get-allowed-features/${roleId}/${brand_id}`, obj, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: ROLES_FEATURES,
              payload: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: ROLES_FEATURES,
              payload: [],
            });
            console.log(err.response.data.message);
          } else {
            alert(err.message);
          }
        });
    }
  };
};
export const updateFeaturesAccess = (data: any, type: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.post(`/roles/udpate-permissions`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          toast.info("Status changed successfully", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch(roleFeaturesByBrand(data.brand_id, data.role_id, type));
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const saveTaxByState = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.post("/admin/save_tax", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          toast.info(response.data.successResponse, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch(statesList(data.brand_id));
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const getTaxValue = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/tax_value`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_TAX,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const taxList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/tax_data`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: TAX_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const discountsList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/discounts", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: DISCOUNT_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const saveDiscountValue = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    if (!data.discount_id) {
      dispatch({
        type: SAVE_DISCOUNT,
        saveDiscount: false,
        payload: "please select discount",
      });
    } else {
      Api.post("/admin/save_discount", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.data.success) {
            window.location.reload();
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            dispatch({
              type: SAVE_DISCOUNT,
              saveDiscount: false,
              payload: error,
            });
          } else {
            alert(err.message);
          }
        });
    }
  };
};
export const saveDeliveryFee = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    // if (!data.country_code) {
    //     dispatch({
    //         type: SAVE_DELIVERY,
    //         saveDelivery: false,
    //         payload: "please select country"
    //     })
    // } else {
    Api.post("/admin/save_deliveryfee", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          toast.info(response.data.successResponse, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch(countryList());
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
  // }
};
export const statesList = (brand?: any, pageType?: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/states/${brand}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          let data = response.data.successResponse;
          if (pageType == "store") {
            data = data.filter(function (el: any) {
              return el.payment_method == "cash";
            });
            data.forEach((element: any) => {
              element.label = element.state_name;
              element.value = element.state_id;
            });
          }
          dispatch({
            type: STATES_LIST,
            payload: data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const statesListByBrand = (brand_id: any, pageType?: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/states/" + brand_id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          let data = response.data.successResponse;
          if (pageType == "store") {
            data = data.filter(function (el: any) {
              return el.payment_method == "cash";
            });
            data.forEach((element: any) => {
              element.label = element.state_name;
              element.value = element.state_id;
            });
          }
          dispatch({
            type: STATES_LIST,
            payload: data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const businessTypeList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/getAllbusinessTypes", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          response.data.successResponse.forEach((element: any) => {
            element.label = element.name;
            element.value = element.id;
          });
          dispatch({
            type: BUSINESS_TYPE_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const addStates = (data: any, type: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.post("/admin/add_state", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          if (type == "states") {
            window.location.href = "/states";
          } else {
            dispatch({
              type: ADD_STATE,
              isInserted: true,
              payload: "",
              state_id: response.data.state_id,
            });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const editStates = (stateid: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.post(`/admin/edit_state/${stateid}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/states";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const getState = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/state/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_STATE,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const delState = (id: number, state_name: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let data = {
      state_name: state_name,
      user_info: jwt.decode(token),
    };
    Api.post(`/admin/del_state/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/states";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const reasonsList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/reasons/reasonsList")
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: REASON_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const addReason = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.post("/reasons/add_reason", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/reasons";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_DUP_ENTRY") {
              // error = "Role Already Exist"
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const editReason = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    data.user_info = jwt.decode(token);
    Api.put(`/reasons/edit_reason/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/reasons";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0];
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const getReason = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/reasons/reason/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_REASON,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const delReason = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.delete(`/reasons/delete_reason/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/reasons";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const blockUnblockReason = (id: any, status: any, reason: any) => {
  return function (dispatch: any) {
    let is_active: any;
    if (status == 0) {
      is_active = 1;
    } else if (status == 1) {
      is_active = 0;
    }
    let token: any = sessionStorage.getItem("token");
    let data = {
      status: is_active,
      reason: reason,
      user_info: jwt.decode(token),
    };
    Api.put(`/reasons/block_unblock_reason/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/reasons";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
