import Api from "../../components/Api";
import {
  REPORTS_LOAD,
  DAILY_SALES_REPORTS,
  ACTIVITY_LOGS_REPORT,
  RIDER_DETAILS_REPORT,
  ORDER_DETAIL_REPORT,
  CANCEL_DETAIL_REPORT,
  CANCEL_SMRY_REPORTS,
  STORES_REPORTS,
  RIDERS_REPORTS,
  CUSTOMERS_REPORTS,
  STORES_LIST,
  SOS_REPORTS,
  PMIX_REPORTS,
  ERROR,
  SALES_MIX_REPORTS,
  CHANNEL_MIX_REPORTS,
  COUPON_REDEMPTION_REPORTS,
  PROFIT_REPORTS,
  DISCOUNT_REPORTS,
  ALOHA_ORDERS_REPORT,
  ALOHA_ORDERS_DETAILS_REPORT,
  SYSTEM_INTEGRATED_ORDERS,
} from "./reportType";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
toast.configure();

export const dailySalesReport = (data: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post("/reports/daily_sales", data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: DAILY_SALES_REPORTS,
            payload: response.data.successResponse,
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: DAILY_SALES_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const sosReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/sos_report/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          response.data.successResponse.forEach((element: any) => {
            element.date_created = moment(element.date_created)
              .local()
              .format("YYYY-MM-DD,HH:mm")
              .split(" ");
          });
          dispatch({
            type: SOS_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: SOS_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const storesList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/reports/stores", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: STORES_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const customersReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/customers/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CUSTOMERS_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          console.log(err.response.data.message);
          dispatch({
            type: CUSTOMERS_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const pmixReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/pmix_report/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: PMIX_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: PMIX_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        } else {
          alert(err.message);
        }
      });
  };
};

export const salesmixReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/salesmix_report/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: SALES_MIX_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: SALES_MIX_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        } else {
          alert(err.message);
        }
      });
  };
};

export const channelmixReport = (data: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post("/reports/channel_sales", data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CHANNEL_MIX_REPORTS,
            payload: response.data.successResponse,
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: CHANNEL_MIX_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const couponredemptionReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/coupon_redemption/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: COUPON_REDEMPTION_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: CHANNEL_MIX_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const profitReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/profit_margin/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: PROFIT_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: PROFIT_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const discountReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/discount_report/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: DISCOUNT_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: DISCOUNT_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const ridersReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/rider_efficiency_summary/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: RIDERS_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: RIDERS_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const riderDetailsReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/rider_efficiency_details/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: RIDER_DETAILS_REPORT,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: RIDER_DETAILS_REPORT,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const storesReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/store_efficiency_summary/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data, "Stores respone");
          dispatch({
            type: STORES_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: STORES_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const cancellationReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/cancellation_report/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data, "Stores respone");
          dispatch({
            type: CANCEL_SMRY_REPORTS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: CANCEL_SMRY_REPORTS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const cancellationDtetailsReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/cancellation_details_report/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CANCEL_DETAIL_REPORT,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: CANCEL_DETAIL_REPORT,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const orderDetailsReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/order_details_report/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          response.data.successResponse.forEach((element: any) => {
            if (element.confirmed_datetime !== "0000-00-00 00:00:00.000000") {
              element.confirmed_datetime = moment(element.confirmed_datetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            } else {
              element.confirmed_datetime = "";
            }
            if (element.pending_datetime !== "0000-00-00 00:00:00.000000") {
              element.pending_datetime = moment(element.pending_datetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            } else {
              element.pending_datetime = "";
            }
            if (element.kitchen_datetime !== "0000-00-00 00:00:00.000000") {
              element.kitchen_datetime = moment(element.kitchen_datetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            } else {
              element.kitchen_datetime = "";
            }
            if (element.ready_datetime !== "0000-00-00 00:00:00.000000") {
              element.ready_datetime = moment(element.ready_datetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            } else {
              element.ready_datetime = "";
            }
            if (element.dispatched_datetime !== "0000-00-00 00:00:00.000000") {
              element.dispatched_datetime = moment(element.dispatched_datetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            } else {
              element.dispatched_datetime = "";
            }
            if (element.delivered_datetime !== "0000-00-00 00:00:00.000000") {
              element.delivered_datetime = moment(element.delivered_datetime)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            } else {
              element.delivered_datetime = "";
            }
          });
          dispatch({
            type: ORDER_DETAIL_REPORT,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: ORDER_DETAIL_REPORT,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const orderSystemIntgReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/orderSystemIntgReport/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        console.log("SYSTEM_INTEGRATED_ORDERS", response.data);
        if (response.data.success) {
          console.log(response.data.successResponse, "logs respone");
          dispatch({
            type: SYSTEM_INTEGRATED_ORDERS,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: SYSTEM_INTEGRATED_ORDERS,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const activityLogsReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/activity_logs_report/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.successResponse, "logs respone");
          dispatch({
            type: ACTIVITY_LOGS_REPORT,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: ACTIVITY_LOGS_REPORT,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const AlohaOrderReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/aloha_orders/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.successResponse, "logs respone");
          dispatch({
            type: ALOHA_ORDERS_REPORT,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: ALOHA_ORDERS_REPORT,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const AlohaOrderDetailsReport = (data: any, page: any) => {
  return function (dispatch: any) {
    dispatch({
      type: REPORTS_LOAD,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    Api.post(`/reports/aloha_orders_details/${page}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.successResponse, "logs respone");
          dispatch({
            type: ALOHA_ORDERS_DETAILS_REPORT,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error("No data to report", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch({
            type: ALOHA_ORDERS_DETAILS_REPORT,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
            msg: "",
          });
          dispatch({
            type: REPORTS_LOAD,
            isLoad: false,
          });
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
