import { PAYMENTS_LIST } from "../actions/paymentType";
const initialState: any = {
  data: [] || "",
};

const paymentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PAYMENTS_LIST:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default paymentReducer;
