import React, { Component } from "react";
import jwt from "jsonwebtoken";
import { secretKey } from "../secret";
import { connect } from "react-redux";
import { EditProfileProps, EditProfileState } from "../interfaces/user";
import {
  logoutUser,
  getProfile,
  editProfile,
  changePassword,
  countryList,
} from "../redux";
import Topbar from "../components/topbar";
import Sidebar from "../components/sidebar";
import Footer from "../components/footer/main";
import { Redirect } from "react-router-dom";

class Profile extends Component<EditProfileProps, EditProfileState> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      city: "",
      country: 0,
      address: "",
      changePassMsg: "",
      newPass: "",
      confirmPass: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    this.props.getProfile();
    this.props.countryList();
    document.title = "SimpleXSync | Profile";
  }
  componentWillReceiveProps(nextProps: any) {
    console.log(nextProps.userData);
    this.setState({
      firstname: nextProps.userData.first_name,
      lastname: nextProps.userData.last_name,
      email: nextProps.userData.email_address,
      city: nextProps.userData.city,
      country: nextProps.userData.country_id,
      address: nextProps.userData.address,
    });
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleSubmit = (event: any) => {
    let { firstname, lastname, email, city, country, address } = this.state;
    this.props.editProfile(firstname, lastname, email, city, country, address);
    event.preventDefault();
  };
  isFormValid = () => {
    const { firstname, email, city, address } = this.state;
    return firstname !== "" && email !== "" && city !== "" && address !== "";
  };
  isPassFormValid = () => {
    const { newPass, confirmPass } = this.state;
    return newPass !== "" && confirmPass !== "";
  };
  changedPassword = (event: any) => {
    const { newPass, confirmPass } = this.state;
    if (newPass !== confirmPass) {
      this.setState({ changePassMsg: "Passwords do not match" });
      event.preventDefault();
    } else {
      this.props.changePassword(newPass);
    }
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    const { isUpdated, message, userData, countries } = this.props;
    const { changePassMsg } = this.state;
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Profile Management</h4>
                </div>
              </div>
            </header>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="card">
                      <div className="card-header d-flex align-items-center">
                        <h3 className="h4">Personal Information</h3>
                      </div>
                      <div className="card-body">
                        {isUpdated && (
                          <strong>
                            <p
                              className="text-center font-weight-light mb-4"
                              style={{ color: "green" }}
                            >
                              {message}
                            </p>
                          </strong>
                        )}
                        <form className="form-validate">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="firstname"
                                  defaultValue={userData.first_name}
                                  required
                                  data-msg="Please enter First Name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  name="lastname"
                                  defaultValue={userData.last_name}
                                  required
                                  data-msg="Please enter Last Name"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Email <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  defaultValue={userData.email_address}
                                  required
                                  data-msg="Please enter Email"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  City <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="city"
                                  defaultValue={userData.city}
                                  required
                                  data-msg="Please enter City"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Country <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="country"
                                  className="form-control"
                                  required
                                  data-msg="Please enter Country"
                                  onChange={this.handleInputChange}
                                >
                                  <option disabled>Select Country</option>
                                  {countries &&
                                    countries.map(
                                      (country: any, index: any) => (
                                        <option
                                          key={index}
                                          value={country.country_id}
                                          {...(userData.country_id ==
                                            country.country_id && {
                                            selected: true,
                                          })}
                                        >
                                          {country.country_name}
                                        </option>
                                      ),
                                    )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Address <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="address"
                                  defaultValue={userData.address}
                                  required
                                  data-msg="Please enter Address"
                                  className="input-material"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group float-right">
                            <button
                              onClick={this.handleSubmit}
                              disabled={!this.isFormValid()}
                              className="btn btn-primary"
                            >
                              Update Profile
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="card">
                      <div className="card-header d-flex align-items-center">
                        <h3 className="h4">Update Password</h3>
                      </div>
                      <div className="card-body">
                        {changePassMsg !== "" && (
                          <strong>
                            <p
                              className="text-center font-weight-light mb-4"
                              style={{ color: "red" }}
                            >
                              {changePassMsg}
                            </p>
                          </strong>
                        )}
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                New Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="password"
                                name="newPass"
                                required
                                data-msg="Please enter new password"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="password"
                                name="confirmPass"
                                required
                                data-msg="Please enter confirm password"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group float-right">
                          <button
                            onClick={this.changedPassword}
                            disabled={!this.isPassFormValid()}
                            className="btn btn-primary"
                          >
                            Update Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    userData: state.user.userData,
    message: state.user.message,
    isUpdated: state.user.isUpdated,
    countries: state.store.countries,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editProfile: function (
      firstname: any,
      lastname: any,
      email: any,
      city: any,
      country: any,
      address: any,
    ) {
      dispatch(editProfile(firstname, lastname, email, city, country, address));
    },
    getProfile: function () {
      dispatch(getProfile());
    },
    changePassword: function (newPass: any) {
      dispatch(changePassword(newPass));
    },
    countryList: function () {
      dispatch(countryList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
