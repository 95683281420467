export const HOME_COUNTER = "HOME_COUNTER";
export const MONTHLY_ORDERS = "MONTHLY_ORDERS";
export const MONTHLY_SALES = "MONTHLY_SALES";
export const RECENT_PAYMENTS = "RECENT_PAYMENTS";
export const RECENT_ORDERS = "RECENT_ORDERS";
export const RECENT_ITEMS = "RECENT_ITEMS";
export const LEADER_BOARD = "LEADER_BOARD";
export const TOP_SELLING_ITEMS = "TOP_SELLING_ITEMS";
export const LINE_CHART = "LINE_CHART";
export const PIE_CHART_SOS = "PIE_CHART_SOS";
export const DOUGNUT_CHART_CHANNEL = "GOUGNUT_CHART_CHANNEL";
