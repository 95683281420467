import { PAYMENTS_LIST } from "./paymentType";
import Api from "../../components/Api";

export const paymentsList = (brand_id: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/payments/" + brand_id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: PAYMENTS_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
