import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import { connect } from "react-redux";
import Footer from "../../../components/footer/main";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import {
  logoutUser,
  syncLogsList,
  delBrand,
  blockunblockbrand,
} from "../../../redux";
import { BrandProps } from "../../../interfaces/brands";
class ActionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  handleDelete = (id: any) => {
    this.props.data.delBrand(id, this.props.row.brand_name);
  };
  handleBlockUnblock = (id: any, is_active: any) => {
    this.props.data.blockunblock(id, is_active, this.props.row.brand_name);
  };
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title={row.is_active === 0 ? "UnBlock" : "Block"}
          data-toggle="modal"
          data-target={`#blockunblock${row.brand_id}`}
          className={
            row.is_active === 0
              ? "btn btn-outline-success"
              : "btn btn-outline-danger"
          }
        >
          <i
            className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}
          ></i>
        </button>
        <button
          title="Delete Brand"
          className="btn btn-outline-danger ml-2"
          data-toggle="modal"
          data-target={`#DelBrand${row.brand_id}`}
        >
          <i className="fa fa-trash"></i>
        </button>
        <Link
          title="Edit Brand"
          className="btn btn-outline-primary ml-2"
          to={`/edit-brand/${row.brand_id}`}
        >
          <i className="fa fa-edit"></i>
        </Link>
        {/* <!-- Block/Unblock Modal--> */}
        <div
          id={`blockunblock${row.brand_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  {row.is_active === 0 ? "UnBlock" : "Block"} Brand
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure,you want to{" "}
                  {row.is_active === 0 ? "UnBlock" : "Block"} this brand?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  onClick={() =>
                    this.handleBlockUnblock(row.brand_id, row.is_active)
                  }
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal--> */}
        <div
          id={`DelBrand${row.brand_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Delete Brand
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure, you want to delete this brand?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
                <button
                  onClick={() => this.handleDelete(row.brand_id)}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}

class Brand extends Component<BrandProps, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  componentDidMount() {
    this.props.brandsList();
    document.title = "SimpleXSync | Menu Sync Logs";
  }
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    const options: any = {
      // sizePerPageList: [5,10,15],
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      insertModal: () => {
        return <Redirect to="/add-brand" />;
      },
      noDataText: "Brands Not Found",
    };
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Menu Sync Logs</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          version="4"
                          data={this.props.data}
                          search={true}
                          pagination={this.props.data.length > 10 && true}
                          options={options}
                          exportCSV={true}
                          csvFileName="logs.csv"
                          hover
                        >
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="import_id"
                            csvHeader="import id"
                            width="120"
                            dataSort={true}
                            isKey
                          >
                            Menu Upload ID
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="pos_id"
                            csvHeader="pos id"
                            width="70"
                            columnTitle
                          >
                            Store Pos ID
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="status"
                            csvHeader="status"
                            width="80"
                            columnTitle
                          >
                            Status
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 12, whiteSpace: "normal" }}
                            dataField="message"
                            csvHeader="message"
                            width="200"
                            columnTitle
                          >
                            Message
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="date_created"
                            csvHeader="Uploaded At"
                            width="120"
                            columnTitle
                          >
                            Uploaded At
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    brandsList: function () {
      dispatch(syncLogsList());
    },
    delBrand: function (id: any, brand_name: any) {
      dispatch(delBrand(id, brand_name));
    },
    blockunblock: function (id: any, is_active: any, brand_name: any) {
      dispatch(blockunblockbrand(id, is_active, brand_name));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Brand);
