import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
// import DataTable from 'react-data-table-component'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import ReactPaginate from "react-paginate";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import {
  logoutUser,
  customersList,
  blockunblock,
  orderHistoryByCusId,
  favoritesList,
  brandsList,
} from "../../redux";
import { CustomerProps } from "../../interfaces/customers";
import {
  CustomerBulkUpload,
  searchCustomer,
} from "../../redux/actions/customerAction";
import Select from "react-select";

function statusFormatter(cell: any, row: any) {
  return (
    <div className="text-capitalize">
      <span
        {...((row.order_status_code === 1 && {
          className: "badge badge-primary p-1",
        }) ||
          (row.order_status_code === 2 && {
            className: "badge badge-info p-1",
          }) ||
          (row.order_status_code === 3 && {
            className: "badge badge-secondary p-1",
          }) ||
          (row.order_status_code === 4 && {
            className: "badge badge-success p-1",
          }) ||
          (row.order_status_code === 5 && {
            className: "badge badge-danger p-1",
          }))}
      >
        {row.order_status_description}
      </span>
    </div>
  );
}
class ActionFormatter extends Component<{ row: any }, {}> {
  closeModal: any;
  constructor(readonly props: any) {
    super(props);
  }
  handleBlockUnblock = (id: any, phone: any, is_active: any) => {
    this.props.data.blockunblock(id, phone, is_active);
    this.closeModal.click();
  };
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title={row.is_active === 0 ? "UnBlock" : "Block"}
          data-toggle="modal"
          data-target={`#blockunblock${row.customer_id}`}
          className={
            row.is_active === 0
              ? "btn btn-outline-success"
              : "btn btn-outline-danger"
          }
        >
          <i
            className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}
          ></i>
        </button>
        {/* <Link title="Edit Customer" className="btn btn-outline-primary ml-2" to={`/edit-customer/${row.customer_id}`}><i className="fa fa-edit"></i></Link> */}
        {/* <!-- Block/Unblock Modal--> */}
        <div
          id={`blockunblock${row.customer_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  {row.is_active === 0 ? "UnBlock" : "Block"} Customer
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure,you want to{" "}
                  {row.is_active === 0 ? "UnBlock" : "Block"} this customer?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  ref={(el) => (this.closeModal = el)}
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  onClick={() =>
                    this.handleBlockUnblock(
                      row.customer_id,
                      row.phone_number,
                      row.is_active,
                    )
                  }
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}
class OrdersFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  showitems = (id: any) => {
    this.props.data.orderHistoryByCusId(id);
  };
  render() {
    const { row, data } = this.props;
    return (
      <div>
        <button
          title="View Orders"
          className="btn btn-outline-primary mr-2"
          data-toggle="modal"
          data-target={`#vieworders${row.customer_id}`}
          onClick={() => this.showitems(row.customer_id)}
        >
          <i className="fa fa-list"></i>
        </button>
        {/* <!--Orders Modal--> */}
        <div
          id={`vieworders${row.customer_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Orders History
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      {data.orders.length > 0 ? (
                        <BootstrapTable version="4" data={data.orders} hover>
                          <TableHeaderColumn
                            dataField="order_id"
                            columnTitle
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField="store_name" columnTitle>
                            Store
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField="login_name" columnTitle>
                            Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_grossprice"
                            columnTitle
                          >
                            Price
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="action"
                            dataFormat={statusFormatter}
                          >
                            Status
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="date_created"
                            columnTitle
                          >
                            Order Received
                          </TableHeaderColumn>
                        </BootstrapTable>
                      ) : (
                        <p className="text-center">Orders Not Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function ordersHistory(cell: any, row: any, props: any) {
  return <OrdersFormatter row={row} data={props} />;
}
class FavMenuItems extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  showitems = (id: any) => {
    this.props.data.favoritesList(id);
  };
  render() {
    const { row, data } = this.props;
    return (
      <div>
        <button
          title="View Favorite Items"
          className="btn btn-outline-primary mr-2"
          data-toggle="modal"
          data-target={`#viewitems${row.customer_id}`}
          onClick={() => this.showitems(row.customer_id)}
        >
          <i className="fa fa-list"></i>
        </button>
        {/* <!--Orders Modal--> */}
        <div
          id={`viewitems${row.customer_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Favorite Menu Items
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      {data.favItems.length > 0 ? (
                        <BootstrapTable version="4" data={data.favItems} hover>
                          <TableHeaderColumn
                            dataField="wish_id"
                            columnTitle
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField="login_name" columnTitle>
                            Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="email_address"
                            columnTitle
                          >
                            Email
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField="menu_name" columnTitle>
                            Menu
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField="item_name" columnTitle>
                            Menu Item
                          </TableHeaderColumn>
                        </BootstrapTable>
                      ) : (
                        <p className="text-center">
                          Favorite Menu Items Not Found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function favMenuItem(cell: any, row: any, props: any) {
  return <FavMenuItems row={row} data={props} />;
}
function birthday(cell: any, row: any) {
  return <p>{cell.search("/") !== -1 && cell}</p>;
}
class Customers extends Component<
  CustomerProps,
  { [x: number]: any; selectedPage: number; phone: any; brand_id: any }
> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      selectedPage: 0,
      phone: "",
      brand_id: "",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
  }

  componentDidMount() {
    // this.props.customersList(0);
    this.props.brandsList();
    document.title = "SimpleXSync | Customers";
  }
  validatePhone(event: { target: { name: any; value: any } }) {
    if (event.target.value.length < 12) {
      this.setState({ phone: event.target.value });
    } else {
      if (event.target.value.length === 12) {
        this.setState({ phone: event.target.value });
      }
    }
  }
  handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    this.props.customersList(selectedPage);
  };
  handleSubmit = (event: any) => {
    event.preventDefault();
    let { phone, brand_id } = this.state;
    this.props.searchCustomer(phone, brand_id);
  };

  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
  };
  blockInvalidChar = (e: any) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    const options: any = {
      sizePerPage: 30, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Customers Not Found",
    };
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Customer Management</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Select Brand{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                name="brands"
                                options={selectedBrands}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleBrandsInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-lable">
                                Phone <span className="text-danger">*</span>
                              </label>
                              <input
                                id="phone"
                                type="number"
                                value={this.state.phone}
                                onKeyDown={this.blockInvalidChar}
                                name="phone"
                                required
                                data-msg="Please enter Phone"
                                className="input-material"
                                onChange={this.validatePhone}
                              />
                            </div>
                          </div>
                          <div className="form-group float-right mt-4">
                            <button
                              onClick={this.handleSubmit}
                              disabled={this.state.phone == ""}
                              className="btn btn-primary"
                            >
                              Search Customer
                            </button>
                          </div>
                        </div>
                        <BootstrapTable
                          version="4"
                          data={this.props.data}
                          options={options}
                          hover
                        >
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="customer_id"
                            csvHeader="#"
                            width="70"
                            dataSort={true}
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="login_name"
                            csvHeader="Name"
                            width="130"
                            columnTitle
                          >
                            Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="email_address"
                            csvHeader="Email"
                            width="130"
                            columnTitle
                          >
                            Email
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="phone_number"
                            csvHeader="Phone"
                            width="130"
                            columnTitle
                          >
                            Phone
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="gender"
                            csvHeader="Gender"
                            width="100"
                            columnTitle
                          >
                            Gender
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="customer_birthday"
                            dataFormat={birthday}
                            csvHeader="Birthday"
                            width="100"
                            columnTitle
                          >
                            Birthday
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="town_city"
                            csvHeader="City"
                            width="100"
                            columnTitle
                          >
                            City
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="country"
                            csvHeader="Country"
                            width="100"
                            columnTitle
                          >
                            Country
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="is_active"
                            csvHeader="status"
                            width="120"
                            dataSort={true}
                            dataFormat={customerStatus}
                            columnTitle
                          >
                            Status
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="action"
                            width="120"
                            dataFormat={favMenuItem}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Favorite Menu Items
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="action"
                            width="100"
                            dataFormat={ordersHistory}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Orders
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="action"
                            width="140"
                            dataFormat={actionFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Action
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.pageCount > 0 && (
                  <div className="row">
                    <div className="col d-flex d-flex justify-content-end">
                      {/* <p><b className="text-primary">Total Data</b> &nbsp; | &nbsp; <span>{this.props.numOfRows}</span></p> */}
                      {/* <ReactPaginate
                                                initialPage={parseInt(this.props.currentPage)}
                                                breakLinkClassName={'page-link'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                previousLabel={"prev"}
                                                nextLabel={"next"}
                                                breakLabel={"...."}
                                                breakClassName={"page-item"}
                                                pageCount={this.props.pageCount}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={2}
                                                disableInitialCallback={true}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"} /> */}
                    </div>
                  </div>
                )}
              </div>
            </section>
            <Footer />
          </div>
          {/* <Content data={this.props.data} blockunblock={this.props.blockunblock} /> */}
        </div>
      </div>
    );
  }
}
function customerStatus(cell: any) {
  return (
    <div className="text-capitalize">
      <span
        {...((cell == 1 && { className: "badge badge-success p-2" }) ||
          (cell == 0 && { className: "badge badge-danger p-2" }))}
      >
        {cell == 0 ? "Blocked" : "Unblocked"}
      </span>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    data: state.customer.data,
    currentPage: state.customer.currentPage,
    pageCount: state.customer.pageCount,
    numOfRows: state.customer.numOfRows,
    orders: state.customer.ordersdata,
    favItems: state.customer.favItemData,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    searchCustomer: (phone: any, brand_id: any) => {
      dispatch(searchCustomer(phone, brand_id));
    },
    customersList: function (page: any) {
      dispatch(customersList(page));
    },
    blockunblock: function (id: any, phone: any, is_active: any) {
      dispatch(blockunblock(id, phone, is_active));
    },
    orderHistoryByCusId: function (id: any) {
      dispatch(orderHistoryByCusId(id));
    },
    favoritesList: function (id: any) {
      dispatch(favoritesList(id));
    },
    CustomerBulkUpload: (data: any) => dispatch(CustomerBulkUpload(data)),
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
