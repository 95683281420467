import { createStore, applyMiddleware, combineReducers } from "redux";
import userReducer from "./reducers/userReducer";
import storeReducer from "./reducers/storeReducer";
import menuReducer from "./reducers/menuReducer";
import customerReducer from "./reducers/customerReducer";
import couponReducer from "./reducers/couponReducer";
import promoReducer from "./reducers/promoReducer";
import orderReducer from "./reducers/orderReducer";
import paymentReducer from "./reducers/paymentReducer";
import homeReducer from "./reducers/homeReducer";
import settingReducer from "./reducers/settingReducer";
import brandReducer from "./reducers/brandReducer";
import reportReducer from "./reducers/reportReducer";
import tradezoneReducer from "./reducers/tradezoneReducer";
import discountReducer from "./reducers/discountReducer";
import driverReducer from "./reducers/drivers";
import notificationReducer from "./reducers/notificationReducer";
import cityReducer from "./reducers/cityReducer";
import aggregatorReducer from "./reducers/aggregatorReducer";
const thunkMiddleware = require("redux-thunk").default;

const mainReducer = combineReducers({
  home: homeReducer,
  user: userReducer,
  store: storeReducer,
  menu: menuReducer,
  customer: customerReducer,
  coupon: couponReducer,
  discount: discountReducer,
  promo: promoReducer,
  notification: notificationReducer,
  order: orderReducer,
  payment: paymentReducer,
  setting: settingReducer,
  brand: brandReducer,
  city: cityReducer,
  report: reportReducer,
  tradezone: tradezoneReducer,
  driver: driverReducer,
  aggregator: aggregatorReducer,
});
const store = createStore(mainReducer, applyMiddleware(thunkMiddleware));

export default store;
