import { GET_PROFILE } from "./userType";
import jwt from "jsonwebtoken";
import Api from "../../components/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export const loginUser = (email: any, password: any, lat: any, lng: any) => {
  return function (dispatch: any) {
    Api.post("/admin/auth", {
      email: email,
      password: password,
      lat: lat,
      lng: lng,
    })
      .then((response) => {
        if (response.data.success) {
          let token = response.data.accessToken;
          let username =
            response.data.first_name + " " + response.data.last_name;
          sessionStorage.setItem("name", username);
          sessionStorage.setItem("token", token);
          window.location.href = "/dashboard";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0];
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const getProfile = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    let data: any = jwt.decode(token);
    Api.get(`/admin/profile/${data.user_id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_PROFILE,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const editProfile = (
  firstname: any,
  lastname: any,
  email: any,
  city: any,
  country: any,
  address: any,
) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    let data: any = jwt.decode(token);
    Api.put(
      `/admin/edit_profile/${data.user_id}`,
      {
        first_name: firstname,
        last_name: lastname,
        email_address: email,
        city: city,
        country_id: country,
        address: address,
      },
      {
        headers: { Authorization: "Bearer " + token },
      },
    )
      .then((response) => {
        if (response.data.success) {
          let username = firstname + " " + lastname;
          sessionStorage.setItem("name", username);
          // dispatch(logoutAdmin());
          toast.info(response.data.successResponse, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0];
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const changePassword = (newPass: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    let data: any = jwt.decode(token);
    Api.put(
      `/admin/change_password`,
      {
        user_id: data.user_id,
        password: newPass,
      },
      {
        headers: { Authorization: "Bearer " + token },
      },
    )
      .then((response) => {
        if (response.data.success) {
          dispatch(logoutUser());
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const forgotPassword = (email: any) => {
  return function (dispatch: any) {
    Api.post("/admin/forgot-password", {
      email: email,
    })
      .then((response) => {
        if (response.data.success) {
          toast.info(
            "Your password has been updated and We have sent the email on given email address",
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            },
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0];
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const logoutUser = () => {
  return function (dispatch: any) {
    sessionStorage.clear();
    window.location.href = "/";
  };
};
