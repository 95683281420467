import React, { Component } from "react";
import { EditSubGroupProps, EditSubGroupState } from "../../../interfaces/menu";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import { addGroup, logoutUser, storesListForMultiSelect } from "../../../redux";
import { Link, Redirect } from "react-router-dom";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import CheckChanges from "../../../components/confirmOnLeave";
import Select from "react-select";
import {
  editSubGroup,
  groupsListFilter,
  menusOptions,
  subGroup,
} from "../../../redux/actions/menuAction";
import * as uuid from "uuid";
const ShortUniqueId = require("short-unique-id");
const uid = new ShortUniqueId();
class EditSubGroup extends Component<EditSubGroupProps, EditSubGroupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sub_group_name: "",
      disabled: false,
      group_id: "",
      type: "",
      brand_id: "",
      brand_name: "",
      image: "",
      priority: "",
      erp_id: null,
      group_name: "",
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    let type = this.props.match.params.type;
    this.props.subGroup(id, type);
    // this.props.groupsList(type);
    // this.props.storesList();
    document.title = "SimpleXSync | Groups";
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      sub_group_name: nextProps.subgroup.sub_group_name,
      group_id: nextProps.subgroup.group_id,
      group_name: nextProps.subgroup.group_name,
      erp_id: nextProps.subgroup.erp_id,
      priority: nextProps.subgroup.priority,
      type: nextProps.subgroup.type,
      brand_id: nextProps.subgroup.brand_id,
      brand_name: nextProps.subgroup.brand_name,
    });
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  blockInvalidChar = (e: any) => {
    var regex = new RegExp("^[a-zA-Z0-9]");
    let specialkey: any = e.keyCode;
    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault();
          }
        }
      } else {
        (!regex.test(key) || specialkey == 32) && e.preventDefault();
      }
    }
  };
  fileSelectedHandler = (e: any) => {
    this.setState({ image: e.target.files });
  };
  isGroupReady = () => {
    const { sub_group_name, image, group_id, type, disabled } = this.state;
    return (
      sub_group_name !== "" && group_id != "" && type != "" && disabled != true
    );
  };
  handleState = (value: any) => {
    this.setState({ disabled: value });
  };
  handleSaveBtnClick = () => {
    this.setState({ disabled: true });
    let { sub_group_name, group_id, image, priority, erp_id, type } =
      this.state;
    const data: any = new FormData();
    if (image != "") {
      data.append("file", image[0]);
    }
    data.append("sub_group_name", sub_group_name);
    data.append("group_id", group_id);
    data.append("erp_id", erp_id);
    data.append("priority", priority);
    data.append("type", type);
    let id = this.props.match.params.id;
    let menutype = this.props.match.params.type;
    this.props.editSubGroup(id, menutype, data, this.handleState);
  };
  handleErpChange = () => {
    this.setState({ erp_id: uid.stamp(32) });
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    const { groups, subgroup } = this.props;
    let { sub_group_name, erp_id, group_name, group_id, priority } = this.state;
    return (
      <div className="page">
        <CheckChanges path="/add-group" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">SubGroups Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/sub-groups" className="text-primary">
                    SubGroups
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit SubGroup</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                SubGroup name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="sub_group_name"
                                value={sub_group_name}
                                type="text"
                                name="sub_group_name"
                                onKeyDown={this.blockInvalidChar}
                                required
                                data-msg="Please enter Menu Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Brand <span className="text-danger">*</span>
                              </label>
                              <input
                                disabled
                                id="sub_group_name"
                                value={this.state.brand_name}
                                type="text"
                                name="sub_group_name"
                                onKeyDown={this.blockInvalidChar}
                                required
                                data-msg="Please enter Menu Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              this.state.erp_id == ""
                                ? "col-lg-3 col-5"
                                : "col-lg-4 col-6"
                            }
                          >
                            <div className="form-group">
                              <label className="form-control-label">
                                ERP Id
                              </label>
                              <input
                                disabled
                                id="erp_id"
                                value={this.state.erp_id}
                                type="text"
                                name="erp_id"
                                onKeyDown={this.blockInvalidChar}
                                required
                                data-msg="Please enter Menu Name"
                                className="input-material"
                              />
                            </div>
                          </div>
                          {this.state.erp_id == "" && (
                            <div className="col-lg-1 col-1 mt-5">
                              <button
                                onClick={this.handleErpChange}
                                className="btn btn-sm btn-primary"
                              >
                                <i className="fa fa-random"></i>
                              </button>
                            </div>
                          )}
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Type<span className="text-danger">*</span>
                              </label>
                              <select
                                name="type"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select Menu"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Type</option>
                                <option
                                  value="item"
                                  selected={this.state.type == "item" && true}
                                >
                                  Item
                                </option>
                                <option
                                  value="combo"
                                  selected={this.state.type == "combo" && true}
                                >
                                  Combo
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Group <span className="text-danger">*</span>
                              </label>
                              <select
                                name="group_id"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select Menu"
                                onChange={this.handleInputChange}
                              >
                                <option>Select Group</option>
                                {groups &&
                                  groups.map((group, index) => (
                                    <option
                                      key={index}
                                      value={group.group_id}
                                      {...(subgroup.group_id ===
                                        group.group_id && { selected: true })}
                                    >
                                      {group.group_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label className="form-control-label mb-3">
                                SubGroup Image{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="file"
                                type="file"
                                name="file"
                                className="form-control-file"
                                onChange={this.fileSelectedHandler}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Priority
                              </label>
                              <input
                                id="priority"
                                value={priority}
                                type="number"
                                name="priority"
                                onKeyDown={this.blockInvalidChar}
                                required
                                data-msg="Please enter Priority"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                </div> */}
                        {/* <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div> */}
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isGroupReady()}
                            onClick={this.handleSaveBtnClick}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    menus: state.menu.menus,
    subgroup: state.menu.subgroup,
    stores: state.menu.storesoptions,
    groups: state.menu.groups,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    editSubGroup: function (id: any, type: any, data: any, handleState: any) {
      dispatch(editSubGroup(id, type, data, handleState));
    },
    groupsList: function (menu_type: any) {
      dispatch(groupsListFilter(menu_type));
    },
    storesList: () => {
      dispatch(storesListForMultiSelect());
    },
    subGroup: (id: any, type: any) => {
      dispatch(subGroup(id, type));
    },
    logoutUser: function () {
      dispatch(logoutUser());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSubGroup);
