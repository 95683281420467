export const USERS_LIST = "USERS_LIST";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const GET_USER = "GET_USER";
export const USERGROUP_LIST = "USERGROUPS_LIST";
export const GET_USERGROUP = "GET_USERGROUP";
export const ROLES_LIST = "ROLES_LIST";
export const ROLES_FEATURES = "ROLES_FEATURES";
export const ADD_ROLE = "ADD_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const GET_ROLE = "GET_ROLE";
export const SAVE_TAX = "SAVE_TAX";
export const GET_TAX = "GET_TAX";
export const TAX_LIST = "TAX_LIST";
export const DISCOUNT_LIST = "DISCOUNT_LIST";
export const SAVE_DISCOUNT = "SAVE_DISCOUNT";
export const SAVE_DELIVERY = "SAVE_DELIVERY";
export const STATES_LIST = "STATES_LIST";
export const ADD_STATE = "ADD_STATE";
export const EDIT_STATE = "EDIT_STATE";
export const GET_STATE = "GET_STATE";
export const BUSINESS_TYPE_LIST = "BUSINESS_TYPE_LIST";
export const REASON_LIST = "REASON_LIST";
export const GET_REASON = "GET_REASON";
