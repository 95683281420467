import {
  CUSTOMERS_LIST,
  ORDER_HISTORY,
  FRANCHISE_LIST,
  SET_PAGE_COUNT,
  DUP_DATA,
  UPDATE_LOADER,
  FAV_MENU_ITEM,
  GET_CUSTOMER,
  EDIT_CUSTOMER,
  BULK_UPLOAD,
  FEEDBACK_LIST,
  NEWSLETTER_EMAILS,
  CUSTOMER_COMPLAINTS,
} from "./customerType";
import Api from "../../components/Api";
import { toast } from "react-toastify";
import jwt from "jsonwebtoken";
import "react-toastify/dist/ReactToastify.css";
import { saveLogs } from "./storeAction";
import moment from "moment";
toast.configure();

export const customersList = (page: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/customers/${page}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CUSTOMERS_LIST,
            payload: response.data.successResponse,
            currentPage: response.data.currentPage,
            pageCount: response.data.pageCount,
            numOfRows: response.data.numOfRows,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const favoritesList = (customer_id: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/wishlist/${customer_id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: FAV_MENU_ITEM,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: FAV_MENU_ITEM,
            payload: "",
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const orderHistoryByCusId = (id: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/order_history/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ORDER_HISTORY,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: ORDER_HISTORY,
            payload: "",
          });
          // console.log(err.response.data.message)
        } else {
          alert(err.message);
        }
      });
  };
};
export const blockunblock = (id: any, phone: any, isActive: any) => {
  return function (dispatch: any) {
    let is_active: any;
    if (isActive === 0) {
      is_active = 1;
    } else if (isActive === 1) {
      is_active = 0;
    }
    let token: any = sessionStorage.getItem("token");
    let data = {
      is_active: is_active,
      phone_number: phone,
      user_info: jwt.decode(token),
    };
    Api.put(`/admin/customer_block_unblock/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(searchCustomer(phone, true));
          // window.location.href = "/customers"
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const getCustomer = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/customer/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_CUSTOMER,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const editCustomer = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.put(`/admin/edit_customer/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/customers";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace("_", " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const searchCustomer = (phone: any, brand_id: any, block?: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let data = {
      block: block,
      user_info: jwt.decode(token),
      brand_id,
    };
    Api.post(`/admin/search_customer/${phone}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CUSTOMERS_LIST,
            payload: response.data.successResponse,
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace("_", " ");
          }
          dispatch({
            type: CUSTOMERS_LIST,
            payload: [],
            currentPage: 0,
            pageCount: 0,
            numOfRows: 0,
          });
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
//Feedback Methods
export const feedbackList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/customer_feedbacks", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: FEEDBACK_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const deleteFeedback = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.delete(`/admin/del_feedback/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/feedbacks";
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
//Franchsie Method
export const franchiseList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/franchise", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: FRANCHISE_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
//News Letter Emails
export const emailList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/newsletter/emails", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: NEWSLETTER_EMAILS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};

export const CustomerBulkUpload = (data: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_LOADER,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    await Api.post("/admin/customer-upload", data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response: any) => {
        dispatch({
          type: DUP_DATA,
          payload: response.data.data,
          isLoad: false,
        });
        toast.info(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        if (response.data.dataArr) {
          setTimeout(() => {
            window.location.href = "/bulk";
          }, 3000);
        }
        console.log("Data Upload Successfully!");
      })
      .catch((err) => {
        // dispatch({ type: DUP_DATA, payload: err.response.data.duplicateData })
        // console.log("dup data in action: ", err.response.data.duplicateData)
        // console.log(err.response);
        // toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER });
      });
  };
};
export const AddressBulkUpload = (data: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_LOADER,
      isLoad: true,
    });
    let token: any = sessionStorage.getItem("token");
    await Api.post("/admin/address-upload", data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response: any) => {
        // dispatch({ type: DUP_DATA, payload: response.data.data, isLoad: false })
        toast.info(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        if (response.data.success) {
          setTimeout(() => {
            window.location.href = "/addressBulk";
          }, 3000);
        }
        console.log("Data Upload Successfully!");
      })
      .catch((err) => {
        // dispatch({ type: DUP_DATA, payload: err.response.data.duplicateData })
        // console.log("dup data in action: ", err.response.data.duplicateData)
        // console.log(err.response);
        // toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER });
      });
  };
};
export const MenueBulkUpload = (data: any) => async (dispatch: any) => {
  let token: any = sessionStorage.getItem("token");
  const response = await Api.post("/admin/menue-upload", data, {
    headers: { Authorization: "Bearer " + token },
  });
  dispatch({ type: BULK_UPLOAD, payload: response.headers });
  //toast.success("Data Uploaded Succesfully!", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
  console.log("Upload Data: ", response);
};

//get customer complaints list
export const getComplaints = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/feedbacks", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          response.data.successResponse.forEach((element: any) => {
            element.date_created = moment(element.date_created)
              .local()
              .format("YYYY-MM-DD HH:mm");
            if (element.resolved_date !== "0000-00-00 00:00:00.000000") {
              element.resolved_date = moment(element.resolved_date)
                .local()
                .format("YYYY-MM-DD HH:mm");
            } else {
              element.resolved_date = "";
            }
          });
          dispatch({
            type: CUSTOMER_COMPLAINTS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
