import React, { Component } from "react";
import {
  AddUserGroupProps,
  AddUserGroupState,
} from "../../../interfaces/settings";
import { connect } from "react-redux";
import {
  logoutUser,
  addUserGroup,
  storesListForMultiSelect,
  brandsList,
} from "../../../redux";
import { Link, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import Select from "react-select";
import { secretKey } from "../../../secret";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import CheckChanges from "../../../components/confirmOnLeave";
import { storesListForMultiSelectByBrand } from "../../../redux/actions/menuAction";
class AddUserGroup extends Component<AddUserGroupProps, AddUserGroupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      name: "",
      description: "",
      storesJson: [],
      brand_id: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    document.title = "SimpleXSync | User Groups";
    this.props.brandsList();
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  isUserReady = () => {
    let { name, storesJson } = this.state;
    return name !== "" && storesJson.length > 0;
  };
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ storesJson: e });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  handleSubmit = (event: any) => {
    let { name, description, storesJson } = this.state;
    let data: any = {
      name: name,
      description: description,
      storesJson: JSON.stringify(storesJson),
      is_active: 1,
    };
    this.props.addUserGroup(data);
  };
  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
    this.props.storesList(e.value);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    let msg;
    let messcolor;
    const { stores } = this.props;
    return (
      <div className="page">
        <CheckChanges path="/add-usergroup" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">User Groups Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/users" className="text-primary">
                    User Groups
                  </Link>
                </li>
                <li className="breadcrumb-item active">Add Group</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                id="name"
                                type="text"
                                name="name"
                                required
                                data-msg="Please enter name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Description
                              </label>
                              <input
                                id="description"
                                type="text"
                                name="description"
                                className="input-material"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Select Brand{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                name="brands"
                                options={selectedBrands}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleBrandsInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Select Store{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                isMulti
                                name="stores"
                                options={this.props.stores}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleStoresInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            className="btn btn-primary"
                            disabled={!this.isUserReady()}
                            onClick={this.handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    stores: state.menu.storesoptions,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addUserGroup: function (data: any) {
      dispatch(addUserGroup(data));
    },
    storesList: function (brand_id: any) {
      dispatch(storesListForMultiSelectByBrand(brand_id));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUserGroup);
