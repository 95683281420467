import { PROMOS_LIST, GET_PROMO } from "./promoType";
import Api from "../../components/Api";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export const promosList = () => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get("/admin/promos", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: PROMOS_LIST,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const getPromo = (id: number) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    Api.get(`/admin/promo/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_PROMO,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const addPromo = (formData: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    formData.append("user_info", JSON.stringify(jwt.decode(token)));
    Api.post("/admin/add_promo", formData, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/marketing/1";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
              error = "Please fill in all fields";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const editPromo = (id: any, formData: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    formData.append("user_info", JSON.stringify(jwt.decode(token)));
    Api.put(`/admin/edit_promo/${id}`, formData, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/marketing/1";
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace("_", " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};
export const deletePromo = (id: number, history: any, promo_name?: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let data = {
      promo_name: promo_name,
      user_info: jwt.decode(token),
    };
    Api.post(`/admin/del_promo/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          history.push("/marketing/1");
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
export const activeInactivePromo = (
  id: any,
  isArchive: any,
  history: any,
  promo_name?: any,
) => {
  return function (dispatch: any) {
    let is_archive: any;
    if (isArchive === 0) {
      is_archive = 1; //is_archive =1 it's mean that coupon is inactive
    } else if (isArchive === 1) {
      is_archive = 0; //is_archive =0 it's mean that coupon is active
    }
    let token: any = sessionStorage.getItem("token");
    let data = {
      is_archive: is_archive,
      promo_name: promo_name,
      user_info: jwt.decode(token),
    };
    Api.put(`/admin/promo_active_inactive/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          history.push("/marketing/1");
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else {
          alert(err.message);
        }
      });
  };
};
